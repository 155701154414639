"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isInWhitelist = isInWhitelist;
require("core-js/modules/es.array.concat.js");
var _request = _interopRequireDefault(require("@/utils/request.js"));
/**
 * 白名单验证
 * @param {*} param0
 * @returns
 */
function isInWhitelist(_ref) {
  var whiteId = _ref.whiteId,
    idCard = _ref.idCard;
  return (0, _request.default)({
    url: "zyg/train/white/record/verify/".concat(idCard, "/").concat(whiteId),
    method: 'get'
  });
}