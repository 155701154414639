"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancelInvoiceChange = cancelInvoiceChange;
exports.fetchApplyConfig = fetchApplyConfig;
exports.getAbleExchangeInvoiceList = getAbleExchangeInvoiceList;
exports.getApplyChangeRecordByOrderId = getApplyChangeRecordByOrderId;
exports.getApplyChangedList = getApplyChangedList;
exports.getApplyDoneList = getApplyDoneList;
exports.getApplyList = getApplyList;
exports.getApplyRecordByOrderId = getApplyRecordByOrderId;
exports.statusMapForChange = exports.statusMap = void 0;
exports.submitInvoiceApply = submitInvoiceApply;
exports.submitInvoiceChange = submitInvoiceChange;
require("core-js/modules/es.array.concat.js");
var _request = _interopRequireDefault(require("@/utils/request"));
var pre = "zyg";

/**
 * 提交开票申请
 * @param {*} params 
 */
function fetchApplyConfig() {
  // 
  return (0, _request.default)({
    url: pre + '/payService/customer/invoicing/application/config',
    method: 'get'
  });
}

/**
 * 提交开票申请
 * @param {*} params 
 */
function submitInvoiceApply(data) {
  // 
  return (0, _request.default)({
    url: pre + '/payService/customer/invoicing/application',
    method: 'post',
    data: data
  });
}

/**
 * 申请列表查询（通过state=21获取已换开数据）
 * @param {*} params 
 */
function getApplyList(params) {
  return (0, _request.default)({
    url: pre + '/payService/customer/invoicing/application/list',
    method: 'get',
    params: params
  });
}

/**
 * 【已换开列表】
 * @param {*} params 
 */
function getApplyChangedList(params) {
  return (0, _request.default)({
    url: pre + '/payService/customer/invoicing/application/listForChanged',
    method: 'get',
    params: params
  });
}

/**
 * 已开票列表
 * @param {*} params 
 * @returns 
 */
function getApplyDoneList(params) {
  return (0, _request.default)({
    url: pre + "/payService/customer/invoicing/application/listForDone",
    method: "get",
    params: params
  });
}
/**
 * 换开申请
 * @param {*} data 
 * @returns 
 */
function submitInvoiceChange(data) {
  // /payService/customer/invoicing/application/change
  return (0, _request.default)({
    url: pre + '/payService/customer/invoicing/application/change',
    method: 'post',
    data: data
  });
}
/**
 * 取消换开申请
 * @param {*} params 
 * @returns 
 */
function cancelInvoiceChange(id) {
  return (0, _request.default)({
    url: pre + '/payService/customer/invoicing/application/cancellation',
    method: 'get',
    params: {
      recordId: id
    }
  });
}

/**
 * 获取【可以换开】列表
 * @param {*} params 
 * @returns 
 */
function getAbleExchangeInvoiceList(params) {
  return (0, _request.default)({
    url: "".concat(pre, "/payService/customer/invoicing/application/listForChange"),
    method: "get",
    params: params
  });
}

/**
 * 通过订单Id换取申请开票记录的Id（用于取消该申请，未开票，已开票同时使用）
 * @param {*} id 
 * @returns 
 */
function getApplyRecordByOrderId(id) {
  return (0, _request.default)({
    url: "".concat(pre, "/payService/customer/invoicing/application/last/").concat(id),
    method: "get"
  });
}

/**
 * 根据订单ID获取对应原票所关联的最新的换票申请记录(已换开中使用，取消开票之前使用)
 * @param {*} id 
 * @returns 
 */
function getApplyChangeRecordByOrderId(id) {
  return (0, _request.default)({
    url: "".concat(pre, "/payService/customer/invoicing/application/lastChange/").concat(id),
    method: "get"
  });
}
var statusMap = exports.statusMap = {
  // 开票状态[0-待审核
  // 9-用户取消
  // 1-开票中 11-开票成功 12-开票失败 13-已申请换票
  // 14-同意换票
  // 15-拒绝换票
  // 141-冲红成功 142-冲红失败
  // 2换票中 21-换票成功 22-换票失败]

  0: '开票成功',
  // 待审核
  9: '开票成功',
  // 用户取消
  1: '开票成功',
  //开票中 （不应该出现在已开票列表中）
  11: '开票成功',
  //开票成功
  12: '开票成功',
  //开票失败  （不应该出现在已开票列表中）
  13: '换开中',
  //已申请换票 
  14: '换开中',
  //同意换票
  15: '换开失败',
  //拒绝换票
  141: '换开中',
  //冲红成功
  142: '换开失败',
  //冲红失败
  2: '换开中',
  //换票中
  21: '已换开',
  //换票成功
  22: '换开失败' //换票失败
  // 11, 15, 22, 142（已开票中换开按钮）
};
var statusMapForChange = exports.statusMapForChange = {
  // 开票状态[0-待审核
  // 9-用户取消
  // 1-开票中 11-开票成功 12-开票失败 13-已申请换票
  // 14-同意换票
  // 15-拒绝换票
  // 141-冲红成功 142-冲红失败
  // 2换票中 21-换票成功 22-换票失败]

  0: '换开中',
  // 待审核
  9: '已失效',
  // 用户取消
  1: '已失效',
  //开票中 （不应该出现在已开票列表中）
  11: '开票成功',
  //开票成功
  12: '已失效',
  //开票失败  （不应该出现在已开票列表中）
  13: '换开中',
  //已申请换票 
  14: '换开中',
  //同意换票
  15: '换开失败',
  //拒绝换票
  141: '换开中',
  //冲红成功
  142: '换开失败',
  //冲红失败
  2: '换开中',
  //换票中
  21: '已换开',
  //换票成功
  22: '换开失败' //换票失败
  // 11, 15, 142, 21（已换开中换开按钮）
};