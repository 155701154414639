"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array-buffer.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/es.typed-array.uint8-array.js");
require("core-js/modules/es.typed-array.at.js");
require("core-js/modules/es.typed-array.copy-within.js");
require("core-js/modules/es.typed-array.every.js");
require("core-js/modules/es.typed-array.fill.js");
require("core-js/modules/es.typed-array.filter.js");
require("core-js/modules/es.typed-array.find.js");
require("core-js/modules/es.typed-array.find-index.js");
require("core-js/modules/es.typed-array.find-last.js");
require("core-js/modules/es.typed-array.find-last-index.js");
require("core-js/modules/es.typed-array.for-each.js");
require("core-js/modules/es.typed-array.includes.js");
require("core-js/modules/es.typed-array.index-of.js");
require("core-js/modules/es.typed-array.iterator.js");
require("core-js/modules/es.typed-array.join.js");
require("core-js/modules/es.typed-array.last-index-of.js");
require("core-js/modules/es.typed-array.map.js");
require("core-js/modules/es.typed-array.reduce.js");
require("core-js/modules/es.typed-array.reduce-right.js");
require("core-js/modules/es.typed-array.reverse.js");
require("core-js/modules/es.typed-array.set.js");
require("core-js/modules/es.typed-array.slice.js");
require("core-js/modules/es.typed-array.some.js");
require("core-js/modules/es.typed-array.sort.js");
require("core-js/modules/es.typed-array.subarray.js");
require("core-js/modules/es.typed-array.to-locale-string.js");
require("core-js/modules/es.typed-array.to-reversed.js");
require("core-js/modules/es.typed-array.to-sorted.js");
require("core-js/modules/es.typed-array.to-string.js");
require("core-js/modules/es.typed-array.with.js");
require("core-js/modules/esnext.array-buffer.detached.js");
require("core-js/modules/esnext.array-buffer.transfer.js");
require("core-js/modules/esnext.array-buffer.transfer-to-fixed-length.js");
require("core-js/modules/web.atob.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/web.dom-exception.constructor.js");
require("core-js/modules/web.dom-exception.stack.js");
require("core-js/modules/web.dom-exception.to-string-tag.js");
require("core-js/modules/web.url.js");
require("core-js/modules/web.url-search-params.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _defineProperty2 = _interopRequireDefault(require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _swiperBundleMin = _interopRequireDefault(require("swiper/swiper-bundle.min.js"));
require("swiper/swiper-bundle.min.css");
var _mStore = _interopRequireDefault(require("@/store/modules/mStore.js"));
var _request = _interopRequireDefault(require("@/utils/request.js"));
var _table = require("../../api/table");
var _auth = require("@/utils/auth");
var _personal = require("@/api/personal");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var mPlayAuther = '';
var oids = '';
var nowPlayCourse = '';
var autoSave_ms = 300; //秒
var _default = exports.default = {
  data: function data() {
    var _ref;
    return _ref = {
      activeNames: ['1'],
      list: '',
      mUrl: _mStore.default.mUrl,
      order: '',
      tokenList: '',
      dialogVideoVisible: false,
      updateVideoWrap: true,
      coursePlayList: '',
      visible: false
    }, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "visible", false), "loading", false), "os", false), "thisVideo", null), "thisContext", null), "thisCancas", null), "videoWidth", 500), "videoHeight", 400), "postOptions", []), "CertCtl", ''), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "video_show", true), "can_show", false), "loading", true), "ids", []), "single", true), "multiple", true), "total", 0), "akworkerList", []), "workerTypeOptions", []), "title", ''), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "open", false), "queryParams", {
      pageNum: 1,
      pageSize: 10,
      imgSrc: undefined
    }), "form", {}), "rules", {}), "imgSrc", ''), "monitortimer", ''), "saveTimer", null), "uinfo", ''), "player", null), "monitorObj", null), (0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "studyLogObj", null), "endload", null);
  },
  created: function created() {
    var _this2 = this;
    this.tokenList = (0, _auth.getToken)();
    this.oid = this.$route.params && this.$route.params.id;
    oids = this.oid;

    // this.getlist();
    (0, _request.default)({
      url: '/train/config/' + this.oid,
      method: 'get'
    }).then(function (response) {
      //  isCollect
      if (response.code == 200) {
        _this2.order = response.data.order;
        if (_this2.order.status == 2) {
          _this2.getlist1();
          _this2.getMonitorCon();
        } else {
          _this2.getlist();
        }
      }
    });
    //获取个人信息
    this.getUserInfo();
    //注册浏览器刷新、关闭监听事件
    window.addEventListener('beforeunload', this.closeOrRefreshHandle);
  },
  destroyed: function destroyed() {
    //销毁事件
    window.removeEventListener('beforeunload', this.closeOrRefreshHandle);
  },
  methods: {
    testtt: function testtt() {},
    getlist: function getlist() {
      var _this3 = this;
      (0, _request.default)({
        url: '/train/config/' + this.oid,
        method: 'get'
      }).then(function (response) {
        if (response.code == 200) {
          _this3.list = response.data.requirdClass;
        }
      });
    },
    getlist1: function getlist1() {
      var _this4 = this;
      (0, _request.default)({
        url: '/train/classhours/courselits',
        method: 'get',
        params: {
          trainId: this.oid
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this4.list = response.data.required.class;
        }
      });
    },
    // getTrainMonitor(){
    //   request({
    //     url: "/getTrainMonitor" + this.oid,
    //     method: "post"
    //   }).then(response => {
    //     //  console.log(response,'1')
    //     if (response.code == 200) {
    //       this.list = response.data.requirdClass;
    //     }
    //   });
    // },
    //获取个人信息
    getUserInfo: function getUserInfo() {
      var _this5 = this;
      if (this.uinfo == '') {
        (0, _personal.getPersonalData)().then(function (response) {
          if (response.code == 200) {
            _this5.uinfo = response.data.userInfo;
          }
        });
      }
    },
    //获取监学配置
    getMonitorCon: function getMonitorCon() {
      var _this6 = this;
      //cate: //1/2/3
      (0, _request.default)({
        url: '/train/monitor/getOneMonitor',
        method: 'get',
        params: {
          itemId: oids,
          cate: '2'
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this6.monitorObj = response.data;
        }
      });
    },
    //以下为视频播放器处理方法===================
    //改变播放视频
    mChangePlay: function mChangePlay(item) {
      this.coursePlayList.chapters.forEach(function (e) {
        if (e.section) {
          e.section.forEach(function (el) {
            el.isPlay = false;
          });
        }
      });
      //切换视频，定时记录重置
      this.saveTimerHandle('stop');
      this.playVideo(item);
    },
    //视频播放
    playVideo: function playVideo(item, list) {
      var _this7 = this;
      //是否实名认证判断
      if (this.uinfo.identityStatus != 2 && this.uinfo.bottomPicture == '') {
        this.$message({
          message: '未完成实名认证或未预留留底照片',
          type: 'error'
        });
        return;
      }
      var vm = this;
      //当前课程信息
      nowPlayCourse = item;
      //当前课程学习进记录
      this.studyLogObj = item.userStudyLog;
      if (item.isTryLearn == 1 || this.order.status == 2) {
        this.dialogVideoVisible = true;
        if (list) {
          this.coursePlayList = list;
          this.mteachers = list.teachers;
        }
        this.coursePlayList.chapters.forEach(function (e) {
          if (e.section) {
            e.section.forEach(function (el) {
              el.isPlay = false;
            });
          }
        });
        item.isPlay = true;
        this.updateVideoWrap = false;
        this.$nextTick(function () {
          _this7.updateVideoWrap = true;
        });
        (0, _request.default)({
          url: '/videoUpload/play',
          method: 'post',
          params: {
            vid: item.content
          }
        }).then(function (res) {
          mPlayAuther = res.data.PlayAuth;
          _this7.player = new Aliplayer({
            id: 'player-con',
            width: '100%',
            height: '32.3rem',
            autoplay: false,
            vid: item.content,
            playauth: mPlayAuther,
            isLive: false,
            rePlay: false,
            playsinLine: true,
            preload: false,
            // 私密播放
            encryptType: 1,
            controlBarVisibility: 'hover',
            //definition: "OD,HD,SD,LD",
            skinLayout: [{
              name: 'bigPlayButton',
              align: 'blabs',
              x: 30,
              y: 80
            }, {
              name: 'H5Loading',
              align: 'cc'
            }, {
              name: 'controlBar',
              align: 'blabs',
              x: 0,
              y: 0,
              children: [{
                name: 'progress',
                align: 'tlabs',
                x: 0,
                y: 0
              }, {
                name: 'playButton',
                align: 'tl',
                x: 10,
                y: 10
              }, {
                name: 'timeDisplay',
                align: 'tl',
                x: 5,
                y: 5
              }, {
                name: 'fullScreenButton',
                align: 'tr',
                x: 10,
                y: 10
              }, {
                name: 'volume',
                align: 'tr',
                x: 10,
                y: 10
              }, {
                name: 'setting',
                align: 'tr',
                x: 10,
                y: 10
              }]
            }]
          }, function (player) {
            player.play();
            vm.saveTimerHandle('start');
            var sell = false;
            var canplay_ev = 0;
            player.on('play', function (e) {
              canplay_ev++;
              if (canplay_ev == 1) {
                if (vm.studyLogObj.time > 0) {
                  player.seek(vm.studyLogObj.time);
                }
                // await vm.sleep(2000);
                //判断首次学习是否监学
                vm.isGotoMonitor('firstlearn');
              }
            });
            player.on('ended', function (e) {
              vm.saveNewStudyProgress();
            });
            player.on('pause', function (e) {
              //vm.saveNewStudyProgress();
            });
            player.on('error', function (e) {
              vm.saveNewStudyProgress();
            });
            player.on('completeSeek', function (e) {
              if (e.paramData > vm.studyLogObj.time) {
                player.seek(vm.studyLogObj.time);
                vm.$message({
                  message: '未学习内容不可快进，请正常进度学习！',
                  type: 'error'
                });
              }
              // _this.$emit("completeSeek", _this.instance);
            });
          });
        });
      } else {
        if (this.tokenList && item.isTryLearn == 0) {
          this.$message({
            message: '请你先报名',
            type: 'info'
          });
        } else {
          this.$message({
            message: '请你先登录',
            type: 'info'
          });
        }
      }
    },
    //获取库中的学习进度
    getStudyProgress: function getStudyProgress() {
      var _this8 = this;
      (0, _request.default)({
        url: '/train/studyLog/getStudyLog',
        method: 'post',
        params: {
          sectionId: nowPlayCourse.sectionId
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this8.studyLogObj = response.data;
        } else {
          _this8.studyLogObj = null;
        }
      });
    },
    //记录最新学习进度
    saveNewStudyProgress: function saveNewStudyProgress(reload) {
      var _this9 = this;
      var timelist = this.player.getCurrentTime();
      this.timeOlist = parseInt(timelist);

      //当前进度-库中进度>10秒  则记录(为了减少嵌套，这里是反向判断)
      if (this.studyLogObj.time - this.timeOlist > -10) {
        return;
      }
      //增加学习进度记录
      (0, _request.default)({
        url: '/train/studyLog/add',
        method: 'post',
        data: {
          address: '',
          cate: 1,
          chapterId: nowPlayCourse.chapterId,
          courseId: nowPlayCourse.courseId,
          sectionId: nowPlayCourse.sectionId,
          status: 1,
          time: this.timeOlist,
          trainId: oids
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this9.studyLogObj = response.data;
          //重新刷新页面
          if (_this9.reload == 'reload') {
            (0, _request.default)({
              url: '/train/classhours/courselits',
              method: 'get',
              params: {
                trainId: oids
              }
            }).then(function (response) {
              if (response.code == 200) {
                _this9.list = response.data.required.class;
                location.reload();
              }
            });
          }
        }
      }).catch(function (err) {
        _this9.player && _this9.player.pause();
        _this9.dialogVideoVisible = false;
        _this9.timerHandle('stop');
        _this9.saveTimerHandle('stop');
        _this9.$alert('学习进度保存异常，无法继续播放', '提示', {
          confirmButtonText: '确定'
        });
      });
    },
    //关闭或刷新浏览器时，记录学习进度
    closeOrRefreshHandle: function closeOrRefreshHandle() {
      this.saveNewStudyProgress();
    },
    //关闭视频停止学习
    closeVideoWin: function closeVideoWin() {
      this.dialogVideoVisible = false;
      this.timerHandle('stop');
      this.saveTimerHandle('stop');
      this.saveNewStudyProgress('reload');
    },
    //以下是监学控制=========================================
    //监学定时器处理
    timerHandle: function timerHandle(whichHandle, min) {
      var _this10 = this;
      switch (whichHandle) {
        case 'start':
          this.monitortimer = setInterval(function () {
            _this10.openMonitor();
          }, 1000 * 60 * min);
          break;
        case 'stop':
          clearInterval(this.monitortimer); //清除TIMER
          this.monitortimer = null;
          break;
        default:
      }
    },
    //自动记录学习进度
    saveTimerHandle: function saveTimerHandle(whichHandle) {
      var _this11 = this;
      switch (whichHandle) {
        case 'start':
          this.saveTimer = setInterval(function () {
            _this11.saveNewStudyProgress();
          }, 1000 * autoSave_ms);
          break;
        case 'stop':
          clearInterval(this.saveTimer);
          this.saveTimer = null;
          break;
        default:
      }
    },
    computedIndex: function computedIndex(index) {
      if (index < 10) {
        return '0' + index + '. ';
      } else {
        return index + '. ';
      }
    },
    //监学参数判断
    isGotoMonitor: function isGotoMonitor(hd) {
      if (this.monitorObj == null) {
        return false;
      }
      //判断是否学前身份核验 1是  2否
      if (hd == 'firstlearn' && this.monitorObj.stratIdentityCheck == 1) {
        this.openMonitor();
      } else {
        this.player.play();
        //打开视频跳转至历史进度
        if (this.studyLogObj.userStudyLog.time > 0) {
          this.player.seek(item.userStudyLog.time);
        }
      }
    },
    //打开监学
    openMonitor: function openMonitor() {
      //判断开开启监学条件 1 学完不监学  2 回放不监学
      var diffvlu = this.player.getDuration() - this.studyLogObj.time; //视频总时长-日志进度，误差在5秒内算学完
      if (diffvlu < 5 && diffvlu > -5 || this.player.getCurrentTime() < this.studyLogObj.time) {
        // console.log(
        //   diffvlu < 5 && diffvlu > -5,
        //   this.player.getCurrentTime() < this.studyLogObj.time
        // );
        // console.log(diffvlu, this.player.getDuration(), this.studyLogObj.time);

        return;
      }
      this.visible = true;
      //如果播放中，则暂停kxl
      if (this.player) {
        this.player.pause();
      }
      //视频全屏情况下弹监学窗口问题，暂作退出全屏处理
      if (this.player.fullscreenService.getIsFullScreen()) {
        this.player.fullscreenService.cancelFullScreen();
      }
      this.timerHandle('stop');
      this.getUserInfo();
      this.getCompetence();
    },
    //关闭监学
    closeMonitor: function closeMonitor() {
      this.visible = false;
      this.displayCanOrVide('vid');
      this.resetCanvas();
      this.stopNavigator();

      // console.log(
      //   this.monitorObj,
      //   this.monitorObj.isPhoto,
      //   this.monitorObj.photoTime,
      //   "closeMonitor"
      // );
      //关闭时启动下一次监学倒计时 1是2否
      if (this.monitorObj.isPhoto == 1) {
        //间隔单位为分钟
        // this.timerHandle("start", this.monitorObj.photoTime);
        this.timerHandle('start', 0.5);
      }
    },
    //开启摄像头
    getCompetence: function getCompetence() {
      var _this12 = this;
      //必须在model中render后才可获取到dom节点,直接获取无法获取到model中的dom节点
      this.$nextTick(function () {
        var _this = _this12;
        _this12.os = true; //切换成关闭摄像头
        _this12.thisCancas = document.getElementById('canvasCamera');
        _this12.thisContext = _this12.thisCancas.getContext('2d');
        _this12.thisVideo = document.getElementById('videoCamera');
        // console.log(this.thisCancas, this.thisContext, this.thisVideo);
        // console.log(navigator,navigator.mediaDevices,navigator.mediaDevices.getUserMedia,"测试获取摄像头navigator");
        // console.log(navigator.mediaDevices,"测试获取摄像头navigator.mediaDevices");
        // console.log(navigator.mediaDevices.getUserMedia,"测试获取摄像头navigator.mediaDevices.getUserMedia");
        // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
        if (navigator.mediaDevices === undefined) {
          // navigator.menavigatordiaDevices = {};
          navigator.mediaDevices = {};
        }
        // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
        // 使用getUserMedia，因为它会覆盖现有的属性。
        // 这里，如果缺少getUserMedia属性，就添加它。
        if (navigator.mediaDevices.getUserMedia === undefined) {
          navigator.mediaDevices.getUserMedia = function (constraints) {
            // 首先获取现存的getUserMedia(如果存在)
            var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia;
            // 有些浏览器不支持，会返回错误信息
            // 保持接口一致
            if (!getUserMedia) {
              return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
            }
            // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
            return new Promise(function (resolve, reject) {
              getUserMedia.call(navigator, constraints, resolve, reject);
            });
          };
        }
        var constraints = {
          audio: false,
          video: {
            width: _this.videoWidth,
            height: _this.videoHeight,
            transform: 'scaleX(-1)'
          }
        };
        navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
          // 旧的浏览器可能没有srcObject
          if ('srcObject' in _this.thisVideo) {
            _this.thisVideo.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            _this.thisVideo.src = window.URL.createObjectURL(stream);
          }
          _this.thisVideo.onloadedmetadata = function (e) {
            _this.thisVideo.play();
          };
        }).catch(function (err) {
          _this12.closeMonitor();
          _this12.closeVideoWin();
          _this12.$message({
            message: '没有开启摄像头权限或浏览器版本不兼容.无法学习',
            type: 'warning'
          });
        });
      });
    },
    //绘制图片
    drawImage: function drawImage() {
      var _this13 = this;
      // 点击，canvas画图
      this.thisContext.drawImage(this.thisVideo, 0, 0, this.videoWidth, this.videoHeight);
      this.displayCanOrVide('can');
      var filename = '';
      // 获取图片base64链接
      this.imgSrc = this.thisCancas.toDataURL('image/png');
      var formData = new FormData();
      //formData.append("imgUrl", this.thisCancas.toDataURL("image/jpeg"));
      formData.append('file', this.dataURLtoFile(this.thisCancas.toDataURL('image/jpeg'), '1231qwsedq.jpg'));
      this.aliUploadBase64(this.imgSrc, 'trainFaceVerification').then(function (res) {
        if (res.res.status === 200) {
          filename = "https://oss.hangxintong.cn/".concat(res.name);
          // window.open(filename+'?t='+Math.floor(Math.random()*62), "_blank");
          // 人像比对
          (0, _request.default)({
            url: '/train/sup/course',
            method: 'post',
            data: {
              address: '',
              bussinessId: nowPlayCourse.courseId,
              chapterId: nowPlayCourse.chapterId,
              headFace: filename,
              isAgree: '',
              sectionId: nowPlayCourse.sectionId,
              trainId: oids,
              type: 1
            }
          }).then(function (response) {
            // console.log(response.code, response.data.abc);

            if (response.code == 200) {
              _this13.loading2 = false;
              if (response.data.abc > 70) {
                _this13.$message({
                  message: '人像比对成功',
                  type: 'success'
                });
                // 继续学习
                if (_this13.player) {
                  _this13.player.play();
                }
                _this13.closeMonitor();
              } else {
                _this13.displayCanOrVide('vid');
                _this13.$message({
                  message: '人像比对失败，请重新比对',
                  type: 'error'
                });
              }
            }
          });
        }
      });
    },
    displayCanOrVide: function displayCanOrVide(cOv) {
      if (cOv == 'can') {
        this.video_show = false;
        this.can_show = true;
      } else {
        this.video_show = true;
        this.can_show = false;
      }
    },
    //清空画布
    clearCanvas: function clearCanvas(id) {
      var c = document.getElementById(id);
      var cxt = c.getContext('2d');
      cxt.clearRect(0, 0, c.width, c.height);
    },
    //重置画布
    resetCanvas: function resetCanvas() {
      this.imgSrc = '';
      this.clearCanvas('canvasCamera');
    },
    //关闭摄像头
    stopNavigator: function stopNavigator() {
      if (this.thisVideo && this.thisVideo !== null && this.thisVideo.srcObject != null) {
        this.thisVideo.srcObject.getTracks()[0].stop();
        this.os = true; //切换成打开摄像头
      }
    },
    //BASE64文件转图片文件
    dataURLtoFile: function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime
      });
    },
    sleep: function sleep(ms) {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve();
        }, ms);
      });
    }
  }
};