"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("./index.vue"));
var _vue = _interopRequireDefault(require("vue"));
var ToastCmp = _vue.default.extend(_index.default);
function toast(options) {
  var div = document.createElement('div');
  document.querySelector('#app').insertBefore(div, document.querySelector('.customMade'));
  var _ref = options || {},
    type = _ref.type,
    title = _ref.title,
    content = _ref.content,
    btn = _ref.btn,
    _hideDialog = _ref.hideDialog,
    _callback = _ref.callback;
  new ToastCmp({
    // 实力化会把data注入到目标组件
    data: function data() {
      var _this = this;
      return {
        type: type || 1,
        title: title || 'Tips',
        content: content || 'contents here',
        btn: btn || 'confirm',
        hideDialog: function hideDialog() {
          _hideDialog && _hideDialog instanceof Function && _hideDialog();
          _this.isShow = false;
        },
        callback: function callback(data) {
          _callback && _callback instanceof Function && _callback(data);
          _this.isShow = false;
        }
      };
    }
  }).$mount(div);
}
var _default = exports.default = {
  install: function install(Vue) {
    Vue.prototype.$guideFrame = toast;
  }
};