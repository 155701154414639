var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "containerWrap" }, [
    _c(
      "div",
      { staticClass: "navbar" },
      [
        _c("div", { staticClass: "navbar-header" }, [
          _c("div", { staticClass: "oimg", on: { click: _vm.fhhomepage } }, [
            _c("img", {
              attrs: {
                src: this.$store.getters.assocLogo,
                title: "返回首页",
                alt: "",
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "oss" },
            [
              _c("el-input", {
                staticStyle: { position: "fixed", bottom: "-9999px" },
                attrs: { type: "text" },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.input3,
                    expression: "input3",
                  },
                ],
                key: "search-input",
                staticClass: "input-with-select oss1",
                attrs: { placeholder: "请输入内容" },
                domProps: { value: _vm.input3 },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchs($event)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.input3 = $event.target.value
                  },
                },
              }),
              _c("div", {
                staticClass: "el-icon-search oss2",
                on: { click: _vm.searchs },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "nav-bar-items" }, [
            _c(
              "div",
              {
                staticClass: "hovertwe",
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                },
              },
              [
                _vm._l(_vm.navList, function (item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "p",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.peixuncaidanfu(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.title))]
                    ),
                  ])
                }),
                _c("div", [
                  [688].includes(_vm.assocId)
                    ? _c(
                        "p",
                        { staticClass: "hovertwe", on: { click: _vm.buy } },
                        [_vm._v("批量采购")]
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "obexlik" }, [
                  _c("p", { staticClass: "oBexClick" }, [_vm._v("手机App")]),
                  _vm.codeUrl !== ""
                    ? _c("div", { staticClass: "tooltiptext" }, [
                        _c("h2", [_vm._v("扫码下载手机APP")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "108px",
                              height: "108px",
                              margin: "0 auto",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { src: _vm.codeUrl, alt: "" },
                            }),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              width: "100%",
                              "text-align": "center",
                              "font-size": "12px",
                              color: "#666666",
                            },
                          },
                          [_vm._v(" APP下载 ")]
                        ),
                      ])
                    : _vm._e(),
                ]),
                [246].includes(_vm.assocId)
                  ? _c("div", [_c("p", [_vm._v("技术支持：0471-3134033")])])
                  : _vm._e(),
                [246].includes(_vm.assocId)
                  ? _c("div", { staticClass: "obexlik" }, [
                      _c("p", { staticClass: "oBexClick" }, [
                        _vm._v("客服二维码"),
                      ]),
                      _vm._m(0),
                    ])
                  : _vm._e(),
              ],
              2
            ),
            !_vm.token || !_vm.name
              ? _c("div", { staticClass: "hoverddd" }, [
                  !_vm.token || !_vm.name
                    ? _c(
                        "div",
                        { staticClass: "nav-item", on: { click: _vm.signin } },
                        [_vm._v(" 登录 ")]
                      )
                    : _vm._e(),
                  !_vm.token || !_vm.name
                    ? _c(
                        "div",
                        {
                          staticClass: "nav-item",
                          on: { click: _vm.signinfun },
                        },
                        [_vm._v(" 注册 ")]
                      )
                    : _vm._e(),
                ])
              : _c(
                  "div",
                  {
                    staticClass: "right-menu",
                    staticStyle: { "margin-top": "10px" },
                  },
                  [
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "avatar-container",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "avatar-wrapper",
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-right": "5px",
                                  cursor: "pointer",
                                },
                              },
                              [
                                _c("el-avatar", {
                                  staticClass: "avaterImg",
                                  attrs: { size: 35, src: _vm.avatar },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "user-name",
                                staticStyle: {
                                  "margin-bottom": "22px",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.name) + " ")]
                            ),
                            _c("p", [
                              _c("i", { staticClass: "el-icon-caret-bottom" }),
                            ]),
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          {
                            staticClass: "user-dropdown",
                            staticStyle: {
                              "margin-top": "-32px",
                              "margin-left": "20px",
                              width: "140px",
                            },
                            attrs: { slot: "dropdown" },
                            slot: "dropdown",
                          },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "center",
                                  "line-height": "50px",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "toubu",
                                    on: { click: _vm.personalcenter },
                                  },
                                  [_vm._v("个人中心")]
                                ),
                              ]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "center",
                                  "line-height": "40px",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "toubu",
                                    on: { click: _vm.Logouts },
                                  },
                                  [_vm._v("退出登录")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ]),
          _vm.assocId == 17978
            ? _c(
                "div",
                { staticClass: "btn" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "fens",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/dashboard")
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "24px",
                          height: "24px",
                          "margin-bottom": "4px",
                        },
                        attrs: { src: require("@/assets/img/icon-home.png") },
                      }),
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v("首页"),
                      ]),
                    ]
                  ),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "left",
                        width: 150,
                        trigger: "click",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "reference",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "fens" }, [
                                  _c("img", {
                                    staticStyle: {
                                      width: "24px",
                                      height: "24px",
                                      "margin-bottom": "4px",
                                    },
                                    attrs: {
                                      src: require("@/assets/img/icon-qrCode.png"),
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "14px" } },
                                    [_vm._v("App")]
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4259625073
                      ),
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "fens" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: { src: _vm.codeUrl },
                          }),
                          _c("div", { staticStyle: { "font-size": "14px" } }, [
                            _vm._v("APP下载"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "left",
                        width: 150,
                        trigger: "click",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "reference",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "fens" }, [
                                  _c("img", {
                                    staticStyle: {
                                      width: "24px",
                                      height: "24px",
                                      "margin-bottom": "4px",
                                    },
                                    attrs: {
                                      src: require("@/assets/img/icon-kf.png"),
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "14px" } },
                                    [_vm._v("客服")]
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3343573084
                      ),
                    },
                    [
                      _c("div", { staticClass: "fens" }, [
                        _c("div", { staticStyle: { height: "120px" } }, [
                          _c("img", {
                            staticStyle: { width: "120px", height: "120px" },
                            attrs: { src: require("@/assets/img/kefuha.png") },
                          }),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "nihas",
                            staticStyle: { "margin-top": "3px" },
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                width: "24px",
                                height: "24px",
                                "margin-bottom": "8px",
                                display: "block",
                              },
                              attrs: {
                                src: require("@/assets/img/icon-weChat.png"),
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "12px",
                                  color: "#666",
                                  "margin-left": "6px",
                                  "margin-top": "3px",
                                },
                              },
                              [_vm._v("微信扫码")]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.dialogAddVisible,
              "close-on-click-modal": false,
              "custom-class": "addDialog",
              width: "30%",
              "z-index": "666666",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogAddVisible = $event
              },
              close: _vm.clolseLoginDialog,
            },
          },
          [
            _c("div", [
              _c("div", { staticClass: "dengluneirong" }, [
                _c("p", [_vm._v("账号密码登录")]),
                _c("p", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.inputName,
                        expression: "inputName",
                      },
                    ],
                    key: "login-input",
                    staticClass: "wwwww",
                    attrs: {
                      placeholder: "手机号",
                      type: "text",
                      maxlength: "11",
                      minlength: "11",
                      oninput: "value=value.replace(/[^\\d]/g,'')",
                    },
                    domProps: { value: _vm.inputName },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.login()
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.inputName = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("p", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.inputPwd,
                        expression: "inputPwd",
                      },
                    ],
                    staticClass: "wwwww",
                    attrs: { placeholder: "密码", type: "password" },
                    domProps: { value: _vm.inputPwd },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.login()
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.inputPwd = $event.target.value
                      },
                    },
                  }),
                ]),
                _c(
                  "p",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "anniu",
                        attrs: { type: "primary" },
                        on: {
                          click: _vm.login,
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.loginEnterFun()
                          },
                        },
                      },
                      [_vm._v("登录 ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "margin-top": "40px",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.zhucexinhu },
                    },
                    [_vm._v("新用户注册")]
                  ),
                  _c(
                    "p",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.zhaohuimima },
                    },
                    [_vm._v("密码找回")]
                  ),
                ]
              ),
            ]),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.dialogAddVisiblelist,
              "close-on-click-modal": false,
              "custom-class": "addDialog",
              width: "30%",
              "z-index": "666666",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogAddVisiblelist = $event
              },
            },
          },
          [
            _c("div", [
              _c(
                "div",
                { staticClass: "dengluneirong" },
                [
                  _c("p", [_vm._v("新用户注册")]),
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: { model: _vm.ruleForm, rules: _vm.rules },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "cellphonenumber" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "手机号",
                              type: "text",
                              maxlength: "11",
                              minlength: "11",
                              oninput: "value=value.replace(/[^\\d]/g,'')",
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.registers(_vm.ruleForm)
                              },
                              blur: _vm.zhuceclick,
                            },
                            model: {
                              value: _vm.ruleForm.cellphonenumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "cellphonenumber", $$v)
                              },
                              expression: "ruleForm.cellphonenumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            color: "red",
                            height: "20px",
                            "line-height": "20px",
                            margin: "5px 0",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.listNam) + " ")]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "passwords" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入密码,字母或数字6-20位",
                              type: "password",
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.registers(_vm.ruleForm)
                              },
                              focus: _vm.focusop,
                              blur: _vm.blurop,
                            },
                            model: {
                              value: _vm.ruleForm.passwords,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "passwords", $$v)
                              },
                              expression: "ruleForm.passwords",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticStyle: { "margin-top": "12px" } },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "verification",
                                attrs: {
                                  placeholder: "请输入验证码",
                                  maxlength: "6",
                                  minlength: "6",
                                },
                                model: {
                                  value: _vm.kanbos,
                                  callback: function ($$v) {
                                    _vm.kanbos = $$v
                                  },
                                  expression: "kanbos",
                                },
                              }),
                              _c("img", {
                                staticStyle: { width: "180px", height: "40px" },
                                attrs: { src: _vm.Diagramimg },
                                on: { click: _vm.getcaptchazhaohuifun },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-top": "12px" },
                          attrs: { prop: "verificationvode" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "verification",
                                attrs: {
                                  placeholder: "请输入验证码",
                                  maxlength: "6",
                                  minlength: "6",
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.registers(_vm.ruleForm)
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.verificationvode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "verificationvode",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.verificationvode",
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.show,
                                      expression: "show",
                                    },
                                  ],
                                  attrs: {
                                    disabled: _vm.codeList,
                                    type: "text",
                                  },
                                  on: { click: _vm.getcaptchasZ },
                                },
                                [_vm._v("获取验证码 ")]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.show,
                                      expression: "!show",
                                    },
                                  ],
                                },
                                [_vm._v(_vm._s(_vm.count) + "s后重新发送")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        { staticStyle: { "margin-top": "20px" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "anniu",
                              attrs: {
                                type: "primary",
                                disabled: !_vm.checkedTwo,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.registers(_vm.ruleForm)
                                },
                              },
                            },
                            [_vm._v("注册 ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.checkedTwo,
                                callback: function ($$v) {
                                  _vm.checkedTwo = $$v
                                },
                                expression: "checkedTwo",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#333333",
                                    "font-size": "14px",
                                  },
                                },
                                [_vm._v("我已阅读并同意")]
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "padding-left": "10px",
                                cursor: "pointer",
                                color: "#0060ef",
                                "font-size": "14px",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.yueduxieyi = true
                                },
                              },
                            },
                            [_vm._v("《学员用户注册协议》")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "el-dialog",
          {
            staticClass: "ac",
            attrs: {
              visible: _vm.yueduxieyi,
              "close-on-click-modal": false,
              "custom-class": "addDialog",
              width: "50%",
              "z-index": "666666",
              title: "用户协议",
            },
            on: {
              "update:visible": function ($event) {
                _vm.yueduxieyi = $event
              },
            },
          },
          [
            _c("div", { staticClass: "yueduxieyi" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "title",
                    staticStyle: { "font-size": "1.2rem" },
                  },
                  [_vm._v("学员用户注册协议")]
                ),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      "在使用本培训平台（以下简称培训平台）Web及手机端等平台服务之前，请您认真阅读并理解本《注册协议》（以下简称《协议》）中与您在使用过程中相关的内容，包含享有的权利和应当承担的义务。在接受本协议条款前，请您务必审慎阅读、充分理解各条款内容，如您对协议有任何疑问，请联系培训平台客服咨询。当您按照注册页面提示，填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与培训平台达成协议。您承诺接受并遵守本协议的约定，届时您不应以未阅读或者未充分理解本协议的相关内容，而主张本协议无效，或要求撤销本协议。如果您不接受本协议，系统将判定您无权注册、登录或使用本协议所涉及的相关服务。"
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "title" }, [
                  _vm._v("第一部分 协议范围"),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      " 第一条 本协议约定了培训平台与用户之间就使用培训平台服务达成协议。本协议适用于注册、登录及使用本平台提供的相关服务的用户。 "
                    ),
                  ]),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      " 第二条 培训平台有权根据需要不时地制订、修改本协议，无需另行单独通知您。变更后的协议和规则一经在网站公布后，立即或在公告明确的特定时间自动生效。若您在前述变更公告后继续使用培训平台的服务，即表示您已经阅读、理解并接受经修订的协议和规则。若您不同意相关变更，应当立即停止使用培训平台的服务。 "
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "title" }, [
                  _vm._v("第二部分 关于注册"),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      " 第三条 用户须具有法定的相应权利能力和行为能力的自然人、法人或其他组织，能够独立承担法律责任。您完成注册程序时，即视为您确认自己具备独立承担法律责任的能力。若因您不具备承担能力，而导致的一切后果，由您及您的监护人自行承担。 "
                    ),
                  ]),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      " 第四条 用户应自行诚信向本站提供注册资料，用户同意提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且培训平台保留终止用户使用本平台各项服务的权利。 "
                    ),
                  ]),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      " 第五条 用户在本站进行浏览、购买、学习等活动时，涉及用户真实姓名/名称、通信地址、联系电话等隐私信息的，本站将予以严格保密，除非得到用户的授权或法律另有规定，本站不会向外界披露用户隐私信息。 "
                    ),
                  ]),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      " 第六条 您充分理解并完全接受：即便是培训平台采取各种安全技术和程序存储、保护用户信息，防止其被未经授权的访问、使用、复制和泄露，但用户信息仍然有可能发生被黑客攻击、窃取，因不可抗力或者其他非培训平台自身原因而被泄露的情形。对此，只要是培训平台采取了必要的措施防止上述情形之发生，并在上述情形发生之后及时通知用户信息泄露的情况，并采取必要的措施防止其损失进一步扩大，培训平台则无须赔偿由此给您造成的任何损失。 "
                    ),
                  ]),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      " 第七条 您注册成功后，即成为培训平台的用户，可凭借手机号码和您设置的密码登录，您可以根据本站规定修改您的密码。如用户忘记用户名（这里指登录时的手机号码）和密码时，可根据注册时的姓名和身份证联系客服，找回用户名和密码。 "
                    ),
                  ]),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      " 第八条 您应谨慎合理的保存、使用您的用户名（这里指登录时的手机号码）和密码，应对通过您的用户名和密码实施的行为负责。除非有法律规定或司法裁定，且征得培训平台的同意，否则，用户名和密码不得以任何方式转让、赠与或继承。 "
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "title" }, [
                  _vm._v("第三部分 有关订单"),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      " 第九条 在培训平台发声购买行为的用户，请您仔细确认所购内容的名称、价格、数量等信息。您在购买时，确认好商品数量、价款及支付方式、收货人、联系方式、收货地址等内容。如果您提供的资料不真实、不准确、不详尽的，导致我们无法提供相应的服务，培训平台无需承担相应的责任和后果，由此造成的延迟服务，用户需承担因此引起的相应责任及后果。 "
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "title" }, [
                  _vm._v("第四部分 侵权行为及法律手段"),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      " 第十条 培训平台尊重他人的知识产权，也请您尊重培训平台及教程提供方的知识产权。 "
                    ),
                  ]),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      " 第十一条 培训平台上的所有图像、声音文件、视频文件、其他数字资源都是教程提供方的合法知识产权，受法律保护。未经教程提供方书面授权或许可，不得以任何目的对培训平台任何部分进行复制、复印、仿造、出售、转售、访问、或以其他方式加以利用。 "
                    ),
                  ]),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      " 第十二条 禁止对培训平台内的任何试题信息和视频培训课程等核心知识产权采取下载、录播、转录、复制等方式获取；不得以任何目的进行复制、仿造、出售、转售、或以其他方式加以利用。我们将严厉打击该种非法侵权行为。如经调查取证，侵权行为属实，教程提供方保留对该侵权行为追究法律责任的权利。 "
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "title" }, [
                  _vm._v("第五部分 争议处理"),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      " 第十三条 本协议包含了您使用培训平台需遵守的一般性规范，您在使用培训平台时还需遵守适用于该平台的特殊性规范。这些特殊规范，培训平台会不定时公布，一般性规范如与特殊性规范不一致或有冲突，则特殊性规范具有法律优先效力。 "
                    ),
                  ]),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      " 第十四条 本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。 "
                    ),
                  ]),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      "第十五条 本协议履行过程中，因您使用培训平台服务产生"
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.dialogAddVisiblefun,
              "close-on-click-modal": false,
              "custom-class": "addDialog",
              width: "30%",
              "z-index": "666666",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogAddVisiblefun = $event
              },
            },
          },
          [
            _c("div", [
              _c("div", { staticClass: "dengluneirong" }, [
                _c("p", [_vm._v("密码找回")]),
                _c(
                  "p",
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "手机号",
                        type: "text",
                        maxlength: "11",
                        minlength: "11",
                        oninput: "value=value.replace(/[^\\d]/g,'')",
                      },
                      model: {
                        value: _vm.retrieveshoujihao,
                        callback: function ($$v) {
                          _vm.retrieveshoujihao =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "retrieveshoujihao",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "verification",
                      attrs: {
                        placeholder: "请输入验证码",
                        maxlength: "6",
                        minlength: "6",
                        "auto-complete": "new-password",
                      },
                      model: {
                        value: _vm.kanbos,
                        callback: function ($$v) {
                          _vm.kanbos =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "kanbos",
                      },
                    }),
                    _c("img", {
                      staticStyle: { width: "180px", height: "40px" },
                      attrs: { src: _vm.Diagramimg },
                      on: { click: _vm.getcaptchazhaohuifun },
                    }),
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "verification",
                      attrs: {
                        placeholder: "请输入验证码",
                        maxlength: "6",
                        minlength: "6",
                        "auto-complete": "new-password",
                      },
                      model: {
                        value: _vm.retrieveyanchengma,
                        callback: function ($$v) {
                          _vm.retrieveyanchengma =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "retrieveyanchengma",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.show,
                            expression: "show",
                          },
                        ],
                        staticStyle: { cursor: "pointer" },
                        on: { click: _vm.zhaohuigetcaptchasZ },
                      },
                      [_vm._v("获取验证码")]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.show,
                            expression: "!show",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.count) + "s后重新发送")]
                    ),
                  ],
                  1
                ),
                _c(
                  "p",
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入密码,字母或数字6-20位",
                        type: "password",
                        "auto-complete": "new-password",
                      },
                      on: { focus: _vm.focusop, blur: _vm.blurop },
                      model: {
                        value: _vm.retrievemima,
                        callback: function ($$v) {
                          _vm.retrievemima = $$v
                        },
                        expression: "retrievemima",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.oplo,
                            expression: "oplo",
                          },
                        ],
                        staticClass: "tishi",
                      },
                      [_vm._v("密码可以由6-10位数字、大小写字母组成")]
                    ),
                  ],
                  1
                ),
                _c(
                  "p",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "anniu",
                        attrs: { type: "primary" },
                        on: { click: _vm.registersmima },
                      },
                      [_vm._v("确认修改 ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tooltiptext" }, [
      _c("h2", [_vm._v("微信扫码咨询客服")]),
      _c(
        "div",
        { staticStyle: { width: "108px", height: "108px", margin: "0 auto" } },
        [
          _c("img", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: {
              src: require("@/assets/img/customerServiceImg.png"),
              alt: "",
            },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }