"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Topnav = _interopRequireDefault(require("./Topnav.vue"));
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ThemeSecondHeader',
  data: function data() {
    return {};
  },
  components: {
    TopNav: _Topnav.default
  },
  computed: {},
  methods: {}
};