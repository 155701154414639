"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _homeinterface = require("@/api/homeinterface");
var _request = _interopRequireDefault(require("@/utils/request"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'announcementdetails',
  data: function data() {
    return {
      sid: this.$route.params && this.$route.params.id,
      detailist: ''
    };
  },
  created: function created() {
    this.detailsgetfun();
  },
  methods: {
    previouspage: function previouspage() {
      this.$router.go(-1);
    },
    detailsgetfun: function detailsgetfun() {
      var _this = this;
      (0, _homeinterface.detailsget)(this.sid).then(function (response) {
        _this.detailist = response.data;
      });
    }
  }
};