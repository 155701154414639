"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateTaxID = exports.validateNumber = exports.validateEmail = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.test.js");
var validateEmail = exports.validateEmail = function validateEmail(rule, value, callback) {
  var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var flag = reg.test(value);
  flag ? callback() : callback(new Error('请输入正确的邮箱'));
};
var validateNumber = exports.validateNumber = function validateNumber(message) {
  return function (rule, value, callback) {
    if (!value) callback();
    var reg = /^\d+$/;
    var flag = reg.test(value);
    flag ? callback() : callback(new Error(message ? message : "必须为数字"));
  };
};
var validateTaxID = exports.validateTaxID = function validateTaxID(rule, value, callback) {
  // 企业税号
  // /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$
  var TaxID = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/;
  var cardId = /^\d{18}$|\d{17}[Xx]$/;
  var flag = [TaxID, cardId].some(function (item) {
    // console.log("every",item.test(value))
    return item.test(value);
  });
  // console.log(flag)
  flag ? callback() : callback(new Error('请输入有效社会信用统一代码'));
};