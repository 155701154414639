var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboardThemeSecondContainer" }, [
    _c("div", { staticClass: "mainBox" }, [
      _c("div", { staticClass: "main" }, [
        _vm._m(0),
        _c("div", { staticClass: "contentBar" }, [
          _c("div", { staticClass: "newsBox" }, [
            _c("div", { staticClass: "topNewsBar" }, [
              _c("div", { staticClass: "topNews" }, [
                _c(
                  "div",
                  {
                    staticClass: "news",
                    on: {
                      click: function ($event) {
                        return _vm.goDetail(_vm.topNews, "notice")
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.topNews.title) + " ")]
                ),
              ]),
              _c("div", { staticClass: "searchBar" }, [
                _c(
                  "div",
                  { staticClass: "inputBar" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入关键字" },
                      model: {
                        value: _vm.keyword,
                        callback: function ($$v) {
                          _vm.keyword = $$v
                        },
                        expression: "keyword",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "searchBtn",
                        on: { click: _vm.handleSearch },
                      },
                      [_vm._v("搜索")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "newsBar" },
              [
                _c(
                  "el-carousel",
                  [
                    _c("el-carousel-item", [
                      _c("img", {
                        staticClass: "poster",
                        attrs: {
                          src: "https://res.hangxintong.cn/12.jpg",
                          alt: "",
                        },
                      }),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "newsContent" },
                  [
                    _c("NewsList", {
                      attrs: { list: _vm.trainNews, title: "培训通知" },
                      on: {
                        goMore: function ($event) {
                          return _vm.goMore(1)
                        },
                        goDetail: function ($event) {
                          return _vm.goDetail($event, "notice")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "newsArea" },
      [
        _c("ZhongAnCourse", {
          attrs: { title: "培训项目", list: _vm.classList },
          on: {
            goMore: function ($event) {
              return _vm.goMore(2)
            },
            goDetail: function ($event) {
              return _vm.goDetail($event, "news")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleBar" }, [
      _c("i", {
        staticClass: "el-icon-s-unfold",
        staticStyle: { "font-size": "18px" },
      }),
      _c("span", { staticClass: "title" }, [_vm._v("全部")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }