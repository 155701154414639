var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "theme_second_footer_container" }, [
    _c("div", { staticClass: "inner" }, [
      _c("div", { staticClass: "infoBar" }, [
        _c("div", { staticClass: "infoBox" }, [
          _vm._m(0),
          _c("div", {
            staticClass: "boxItem",
            domProps: { innerHTML: _vm._s(_vm.$store.getters.assocFooter) },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "boxItem" }, [
      _c("span", [_vm._v("地址：")]),
      _c("span", [_vm._v("鄂尔多斯市东胜区苏勒德大街61号")]),
      _c("span", [_vm._v(" | ")]),
      _c("span", [_vm._v("联系电话：")]),
      _c("span", [
        _c("span", { staticStyle: { "margin-right": "20px" } }, [
          _vm._v("0477-8166778"),
        ]),
        _vm._v("0477-8167768"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }