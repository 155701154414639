"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.to-string.js");
var _train = require("@/api/train");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  inject: ['handleSelect'],
  data: function data() {
    return {
      total: 100,
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 100,
        status: 1
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    deletemappingfun: function deletemappingfun(item) {
      var _this = this;
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _train.deleteSignUsp)(item.enrollInfoId).then(function (res) {
          if (res.code == 200) {
            _this.getList();
          } else {
            _this.$message.error(res.message);
          }
        });
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _train.getRegistrationListByStatus)(11).then(function (res) {
        if (res.code === 200) {
          var _res$data, _res$data2;
          _this2.list = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.list) || [];
          _this2.total = ((_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.total) || 0;
        }
      }).finally(function () {
        _this2.listLoading = false;
      });
    },
    modifyForm: function modifyForm(item) {
      console.log("item", item.enrollInfoId);
      this.$router.push("/fillOutTheForm/validateForm/".concat(item.trainId, "?enrollInfoId=").concat(item.enrollInfoId));
    }
  }
};