"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.captcha = captcha;
exports.changeEmailSave = changeEmailSave;
exports.changePasswd = changePasswd;
exports.changePasswordSave = changePasswordSave;
exports.changePhoneSave = changePhoneSave;
exports.checkPhoneCode = checkPhoneCode;
exports.examList = examList;
exports.getAuthenticationData = getAuthenticationData;
exports.getPersonalData = getPersonalData;
exports.getSelectCity = getSelectCity;
exports.getSelectQu = getSelectQu;
exports.getSelectsheng = getSelectsheng;
exports.orderList = orderList;
exports.refundOrderList = refundOrderList;
exports.savepresonalInfo = savepresonalInfo;
exports.sendEmail = sendEmail;
exports.sendPhone = sendPhone;
var _request = _interopRequireDefault(require("@/utils/request"));
var _autoprefixer = require("autoprefixer");
// 获取个人信息接口
function getPersonalData() {
  return (0, _request.default)({
    url: '/self/info2',
    method: 'get'
  });
}

// 实名认证保存接口
function getAuthenticationData(data) {
  return (0, _request.default)({
    url: '/user/authentication',
    method: 'post',
    params: data
  });
}

// 发送手机验证码
function sendPhone(phone, status, forget, captcha) {
  return (0, _request.default)({
    url: '/Alisdk/SendSmsPcNew',
    method: 'post',
    params: {
      phone: phone,
      status: status,
      forget: forget,
      captcha: captcha
    }
  });
}
// 获取图形验证码
function captcha(scene) {
  return (0, _request.default)({
    url: 'zyg/web/captcha/' + scene,
    method: 'GET'
  });
}

// 发送邮箱验证码
function sendEmail(email) {
  return (0, _request.default)({
    url: '/Alisdk/SendMailCode',
    method: 'post',
    params: {
      email: email
    }
  });
}

// 更换手机保存
function changePhoneSave(data) {
  return (0, _request.default)({
    url: '/login/changeMobile',
    method: 'post',
    params: data
  });
}

// 邮箱保存接口
function changeEmailSave(emial, code) {
  return (0, _request.default)({
    url: '/login/bindEmail',
    method: 'post',
    params: {
      emial: emial,
      code: code
    }
  });
}

// 验证手机验证码
function checkPhoneCode(mobile, code) {
  return (0, _request.default)({
    url: '/zyg/login/checkCode',
    method: 'post',
    params: {
      mobile: mobile,
      code: code
    }
  });
}

// 修改密码保存
function changePasswordSave(query) {
  return (0, _request.default)({
    url: '/login/changePasswd',
    method: 'post',
    params: query
  });
}

// 个人信息完善保存接口
function savepresonalInfo(data) {
  return (0, _request.default)({
    url: '/self/updatePcUser',
    method: 'post',
    data: data
  });
}

// POST /order/list
function orderList(query) {
  return (0, _request.default)({
    url: '/order/list',
    method: 'post',
    params: query
  });
}
function refundOrderList(query) {
  return (0, _request.default)({
    url: '/order/list',
    method: 'post',
    params: query
  });
}

// 修改密码保存接口开始
function changePasswd(query) {
  return (0, _request.default)({
    url: '/login/changePasswd',
    method: 'post',
    params: query
  });
}

// 获取省市区下拉收据接口
function getSelectsheng(query) {
  return (0, _request.default)({
    url: '/city/cityList',
    method: 'post',
    params: query
  });
}
function getSelectCity(parent_id) {
  return (0, _request.default)({
    url: '/city/cityList',
    method: 'post',
    params: {
      parent_id: parent_id
    }
  });
}
function getSelectQu(parent_id) {
  return (0, _request.default)({
    url: '/city/cityList',
    method: 'post',
    params: {
      parent_id: parent_id
    }
  });
}

// 获取省市区下拉收据接口结束

// 获取全部考试列表

function examList(data) {
  return (0, _request.default)({
    url: '/zyg/examApp/planList',
    method: 'POST',
    data: data
  });
}