"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.push.js");
//
//
//
var _default = exports.default = {
  name: 'Landing',
  data: function data() {
    return {};
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      this.$store.dispatch('app/domain').then(function (res) {
        if (_this.$route.query.zlaqjgryId && _this.$route.query.zlaqjgryXid) {
          if (_this.$route.query.exam) {
            //考试
            _this.$router.push("/?zlaqjgryId=".concat(_this.$route.query.zlaqjgryId, "&zlaqjgryXid=").concat(_this.$route.query.zlaqjgryXid, "&exam=1"));
          } else {
            //培训
            _this.$router.push("/?zlaqjgryId=".concat(_this.$route.query.zlaqjgryId, "&zlaqjgryXid=").concat(_this.$route.query.zlaqjgryXid));
          }
        } else {
          _this.$router.push("/");
        }
        console.info(res);
      }).catch(function (err) {
        _this.$router.push('/');
      });
    }
  }
};