"use strict";

var _interopRequireWildcard = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aiFaceMatch = aiFaceMatch;
exports.buyExam = buyExam;
exports.canExams = canExams;
exports.course = course;
exports.examCardInfo = examCardInfo;
exports.examCardInfoToShow = examCardInfoToShow;
exports.examEnd = examEnd;
exports.examPlanInfo = examPlanInfo;
exports.examServiceTime = examServiceTime;
exports.examUserAnswer = examUserAnswer;
exports.getAllExaminationList = getAllExaminationList;
exports.getAllExaminationListForLogin = getAllExaminationListForLogin;
exports.getClsstifityList = getClsstifityList;
exports.getFormInfo = getFormInfo;
exports.getProblems = getProblems;
exports.getSimulationExamList = getSimulationExamList;
exports.getUrl = getUrl;
exports.getUserInfo = getUserInfo;
exports.insertForm = insertForm;
exports.showExamPlanInfo = showExamPlanInfo;
exports.showMyCertificate = showMyCertificate;
exports.signUp = signUp;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.json.stringify.js");
var _request = _interopRequireDefault(require("@/utils/request"));
var _jsMd = _interopRequireWildcard(require("js-md5"));
function getUrl(dataBody) {
  return 'http://apialipay.dev.hangxintong.cn/';
}

//获取全部考试列表(无需登录)
function getAllExaminationList(dataBody) {
  return (0, _request.default)({
    url: 'zyg/examApp/companyExamList',
    method: 'post',
    data: dataBody
  });
}

//获取全部考试列表(需登录)
function getAllExaminationListForLogin(dataBody) {
  return (0, _request.default)({
    url: 'zyg/examApp/companyUserExamList',
    method: 'post',
    data: dataBody
  });
}

// 考试系统-考试列表
function getSimulationExamList(dataBody) {
  console.log(dataBody);
  return (0, _request.default)({
    url: 'zyg/examSimulation/simulationExamList',
    method: 'post',
    data: dataBody
  });
}

//报名考试
function signUp(dataBody) {
  return (0, _request.default)({
    url: 'zyg/examApp/userSignUp',
    method: 'post',
    params: dataBody
  });
}

//考试详细信息
function examPlanInfo(dataBody) {
  return (0, _request.default)({
    url: 'zyg/examPlan/showExamPlanInfoCompany',
    method: 'post',
    params: dataBody
  });
}

//获取个人基本信息
function getUserInfo(dataBody) {
  return (0, _request.default)({
    url: 'zyg/user/getUserInfo',
    method: 'post',
    params: dataBody
  });
}

//获取表单信息
function showExamPlanInfo(dataBody) {
  return (0, _request.default)({
    url: 'zyg/examPlan/showExamPlanInfo',
    method: 'post',
    params: dataBody
  });
}

//人脸比对 拍摄照片与底图做比对(原阿里接口)
// export function aiFaceMatch(dataBody) {
//   return request({
//     url: 'zyg/Alisdk/aiFaceMatch',
//     method: 'get',
//     params: dataBody,
//   })
// }
// 人脸比对 拍摄照片与底图做比对（百度接口)
function aiFaceMatch(dataBody) {
  return (0, _request.default)({
    url: 'zyg/baiDuSdk/aiFaceMatchBaiDu',
    method: 'get',
    params: dataBody
  });
}

// 获取试题列表
// export function getProblems(dataBody) {
//   return request({
//     url: 'zyg/examPlan/examStart',
//     method: 'post',
//     params: dataBody
//   })
// }
// 获取试题列表(加签名)
function getProblems(_ref) {
  var examPlanId = _ref.examPlanId,
    isTest = _ref.isTest;
  var param = {
    examPlanId: examPlanId,
    isTest: isTest,
    verifyCode: Date.now(),
    sign: (0, _jsMd.default)("examPlanId=".concat(examPlanId, "&isTest=").concat(isTest, "&time=").concat(Date.now(), "planList@zhongxinkeji.com"))
  };
  return (0, _request.default)({
    url: 'zyg/examPlan/v2/examStart',
    method: 'POST',
    data: {
      param: Base64.encode(JSON.stringify(param))
    }
  });
}
// 获取试题列表
function getFormInfo(dataBody) {
  return (0, _request.default)({
    url: 'zyg/customForm/getFormInfo',
    method: 'get',
    params: dataBody
  });
}

// 提交试卷答案
function examUserAnswer(dataBody) {
  return (0, _request.default)({
    url: 'zyg/examPlan/examUserAnswer',
    method: 'post',
    data: dataBody
  });
}

// 获取用户已答题列表
// export function topicResult(id) {
//   return request({
//     url: `zyg/web/exam/examine/examRecord/answerCards/${id}`
//   })
// }
// 获取考试服务器时间
function examServiceTime(recordId) {
  return (0, _request.default)({
    url: 'zyg/web/exam/examine/examRecord/heartbeat',
    method: 'POST',
    data: {
      recordId: recordId,
      // 心跳状态[-1发生错误,0进行中,1客户端申请继续]
      status: 1
    }
  });
}

// 监考人脸比对
function course(dataBody) {
  return (0, _request.default)({
    url: 'zyg/train/sup/course',
    method: 'post',
    data: dataBody
  });
}

//交卷

function examEnd(dataBody) {
  return (0, _request.default)({
    url: 'zyg/examPlan/examEnd',
    method: 'post',
    params: dataBody
  });
}

// 查看考试结果
function examCardInfo(dataBody) {
  return (0, _request.default)({
    url: 'zyg/examPlan/examCardInfo',
    method: 'post',
    params: dataBody
  });
}

// 提交自定义表单
function insertForm(dataBody) {
  return (0, _request.default)({
    url: 'zyg/customForm/insert',
    method: 'post',
    data: dataBody
  });
}

// 购买考试生成订单
function buyExam(examPlanId) {
  return (0, _request.default)({
    url: 'zyg/order/buyExam',
    method: 'post',
    params: {
      examPlanId: examPlanId
    }
  });
}

// 购买考试生成订单
function examCardInfoToShow(dataBody) {
  return (0, _request.default)({
    url: 'zyg/examPlan/examCardInfo',
    method: 'post',
    params: dataBody
  });
}

// 购买考试生成订单
function showMyCertificate(dataBody) {
  return (0, _request.default)({
    url: 'zyg/lmTrainExamCertCertificateLog/lookUserCertPDF',
    method: 'post',
    params: dataBody
  });
}

// 考试考试前获取服务器时间
function canExams(examId) {
  return (0, _request.default)({
    url: 'zyg/examPlan/canExam',
    method: 'get',
    params: {
      examId: examId
    }
  });
}
// 获取考试分类列表
function getClsstifityList(params) {
  return (0, _request.default)({
    url: 'zyg/web/exam/examine/class/list',
    method: 'get',
    params: params
  });
}