"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.kaoshijuebiaos = kaoshijuebiaos;
exports.kaoshikais = kaoshikais;
var _request = _interopRequireDefault(require("@/utils/request"));
// 我的考试列表
function kaoshijuebiaos(assocToken) {
  return (0, _request.default)({
    url: '/nmjx/getauth',
    method: 'get',
    params: {
      assocToken: assocToken
    }
  });
}

// 开始考试
function kaoshikais(type, subjectDbId) {
  return (0, _request.default)({
    url: '/nmjx/saveuser',
    method: 'post',
    params: {
      type: type,
      subjectDbId: subjectDbId
    }
  });
}