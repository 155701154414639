"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _theme = require("./theme");
// import Layout from '@/layout'
// import SiteLayout from '@/views/course/index.vue'
//根据协会id，返回首页、二级页的router和layout布局,
var Layout = _theme.asyncRouter.Layout;
var personalRouter = {
  path: '/personalCenter',
  component: Layout,
  redirect: '/personalCenter',
  children: [{
    path: '/personalCenter',
    name: 'personalcenterTab',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenterTab'));
      });
    },
    meta: {
      title: '个人中心'
    },
    children: [{
      path: '/personalCenter/personalInfo',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/personalInfo.vue'));
        });
      },
      meta: {
        title: '个人信息'
      }
    }, {
      path: '/personalCenter/allexam',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/exam/allExam.vue'));
        });
      },
      meta: {
        title: '我的考试'
      }
    }, {
      path: '/personalCenter/myexam',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/exam/myExam.vue'));
        });
      },
      meta: {
        title: '我参与的'
      }
    },
    // {
    //   path: '/personalCenter/allOrder',
    //   component: () => import('@/views/personalCenter/personalcenter/order/allOrder.vue'),
    //   meta: {
    //     title: '我的订单-全部'
    //   }
    // },
    {
      path: '/personalCenter/registration',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/registration.vue'));
        });
      },
      meta: {
        title: '我的申请-报名申请'
      }
    }, {
      path: '/personalCenter/period',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/period.vue'));
        });
      },
      meta: {
        title: '我的申请-学时减免申请'
      }
    }, {
      path: '/personalCenter/myRegistration/underReview',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/myRegistration/underReview.vue'));
        });
      },
      meta: {
        title: '我的报名-审核中'
      }
    }, {
      path: '/personalCenter/myRegistration/passed',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/myRegistration/passed.vue'));
        });
      },
      meta: {
        title: '我的报名-已通过'
      }
    }, {
      path: '/personalCenter/myRegistration/refuse',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/myRegistration/refuse.vue'));
        });
      },
      meta: {
        title: '我的报名-拒绝'
      }
    }, {
      path: '/personalCenter/appliedOrder',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/order/appliedOrder.vue'));
        });
      },
      meta: {
        title: '我的订单-待付款'
      }
    }, {
      path: '/personalCenter/applyingOrder',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/order/applyingOrder.vue'));
        });
      },
      meta: {
        title: '我的订单-已付款'
      }
    }, {
      path: '/personalCenter/refundOrder',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/order/refundOrder.vue'));
        });
      },
      meta: {
        title: '我的订单-退款'
      }
    }, {
      path: '/personalCenter/applyingInvoice',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/invoice/applyingInvoice.vue'));
        });
      },
      meta: {
        title: '我的发票-未开票'
      }
    }, {
      path: '/personalCenter/appliedInvoice',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/invoice/appliedInvoice.vue'));
        });
      },
      meta: {
        title: '我的发票-已开票'
      }
    }, {
      path: '/personalCenter/exchangeInvoice',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/invoice/exchangeInvoice.vue'));
        });
      },
      meta: {
        title: '我的发票-已换开'
      }
    }, {
      path: '/personalCenter/personalStudy',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/personalStudy.vue'));
        });
      },
      meta: {
        title: '学习进度'
      }
    }, {
      path: '/personalCenter/personalCollection',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/personalCollection.vue'));
        });
      },
      meta: {
        title: '我的收藏'
      }
    }, {
      path: '/personalCenter/certificate',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/certificate.vue'));
        });
      },
      meta: {
        title: '证书档案'
      }
    }, {
      path: '/personalCenter/certificatery',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/certificatery.vue'));
        });
      },
      meta: {
        title: '预览证书'
      }
    }, {
      path: '/personalCenter/personalOrder',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/personalOrder.vue'));
        });
      },
      meta: {
        title: '订单详情'
      }
    }, {
      path: '/personalCenter/admissionTicket',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/admissionTicket'));
        });
      },
      meta: {
        title: '我的准考证'
      }
    }, {
      path: '/personalCenter/MyNotice',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/MyNotice'));
        });
      },
      meta: {
        title: '我的通知'
      }
    },
    // 勘察设计协会关闭批量购买
    {
      path: '/personalCenter/purchaseCourse',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/bulkPurchase/purchaseCourse'));
        });
      },
      meta: {
        title: '课程列表-采购'
      }
    }, {
      path: '/personalCenter/myPurchase',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/bulkPurchase/myPurchase'));
        });
      },
      meta: {
        title: '我的采购单'
      }
    },
    // 已付款详情
    {
      path: '/personalcenter/alreadyPay',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/alreadyPay/detail'));
        });
      },
      meta: {
        title: '付款详情'
      }
    }]
  }, {
    path: '/personalCenter/applyPeriod',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/applyPeriod'));
      });
    },
    meta: {
      title: '申请减免学时'
    }
  }, {
    path: '/personalCenter/registrationStatus',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/registrationStatus'));
      });
    },
    meta: {
      title: '学时减免申请状态'
    }
  },
  // 购物车结算页面
  {
    path: '/payment/shoppingCart',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/payment/shoppingCart/index.vue'));
      });
    },
    meta: {
      title: '购物车结算'
    }
  }]
};
var _default = exports.default = personalRouter;