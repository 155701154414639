"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _swiperBundleMin = _interopRequireDefault(require("swiper/swiper-bundle.min.js"));
require("swiper/swiper-bundle.min.css");
var _mStore = _interopRequireDefault(require("@/store/modules/mStore.js"));
var _request = _interopRequireDefault(require("@/utils/request.js"));
var _table = require("../../api/table");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var mPlayAuther = "";
var _default = exports.default = {
  data: function data() {
    return {
      activeNames: ["1"],
      list: "",
      mUrl: _mStore.default.mUrl,
      dialogVideoVisible: false,
      updateVideoWrap: true,
      coursePlayList: []
    };
  },
  created: function created() {
    this.oid = this.$route.params && this.$route.params.id;
    this.getlist();
  },
  methods: {
    getlist: function getlist() {
      var _this = this;
      (0, _request.default)({
        url: "/train/config/" + this.oid,
        method: "get"
      }).then(function (response) {
        if (response.code == 200) {
          _this.list = response.data.searchSplit;
        }
      });
    },
    mChangePlay: function mChangePlay(item) {
      this.coursePlayList.chapters.forEach(function (e) {
        e.section.forEach(function (el) {
          el.isPlay = false;
        });
      });
      this.playVideo(item);
    },
    playVideo: function playVideo(item, list) {
      var _this2 = this;
      if (item.isTryLearn == 1) {
        this.dialogVideoVisible = true;
        if (list) {
          this.coursePlayList = list;
          this.mteachers = list.teachers;
        }
        this.coursePlayList.chapters.forEach(function (e) {
          e.section.forEach(function (el) {
            el.isPlay = false;
          });
        });
        item.isPlay = true;
        this.updateVideoWrap = false;
        this.$nextTick(function () {
          _this2.updateVideoWrap = true;
        });
        (0, _request.default)({
          url: "/videoUpload/play",
          method: "post",
          params: {
            vid: item.content
          }
        }).then(function (res) {
          mPlayAuther = res.data.PlayAuth;
          var player = new Aliplayer({
            id: "player-con",
            width: "100%",
            height: "32.3rem",
            autoplay: false,
            vid: item.content,
            playauth: mPlayAuther,
            isLive: false,
            rePlay: false,
            playsinLine: true,
            // 私密播放
            encryptType: 1,
            preload: false,
            controlBarVisibility: "hover"
          }, function (player) {
            player.play();
          });
        });
      } else {
        if (this.tokenList) {
          this.$router.push({
            path: "/payment/" + this.oid
          });
        } else {
          this.$notify({
            title: "提示",
            message: "请你先登录",
            type: "info"
          });
        }
      }
    },
    computedIndex: function computedIndex(index) {
      if (index < 10) {
        return "0" + index + ". ";
      } else {
        return index + ". ";
      }
    }
  }
};