"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addEEMCStudyRecord = addEEMCStudyRecord;
exports.getEEMCStudyStatus = getEEMCStudyStatus;
exports.getEmmcToken = getEmmcToken;
require("core-js/modules/es.array.concat.js");
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 通过用户userCode实现账号登录功能
 * 
 * @param {*} userCode 
 * @returns 
 * @author kxl
 */
function getEmmcToken(userCode) {
  return (0, _request.default)({
    url: '/zyg/web/training/learner/logInByOAuth/eemc/' + userCode,
    method: 'post'
  });
}

/**
 * 判断能效中心用户，指定课程能否学习
 * @param {*} trainId 
 */
function getEEMCStudyStatus(trainId, eemcUserInfo) {
  var userCode = eemcUserInfo.userCode;
  return (0, _request.default)({
    url: "/zyg/web/training/learner/study/status/eemc/learn/".concat(trainId, "?userCode=").concat(userCode),
    method: "get"
  });
}

/**
 * 
 * @param {*} data 
 * startTime 开始播放时间(yyyy-MM-dd HH:mm:ss)
trainId 培训ID
sectionId 培训课程节ID
cate 必修/选修
courseId 培训课程ID
chapterId 培训课程章ID
 * @returns 
 */
function addEEMCStudyRecord(data) {
  return (0, _request.default)({
    url: '/zyg/web/training/learner/eemcStudyLog',
    method: 'post',
    data: data
  });
}