var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { width: "78%", margin: "35px auto", "max-width": "1360px" },
    },
    [
      _c(
        "div",
        {
          staticClass: "announcementdetails",
          staticStyle: { "min-height": "800px" },
        },
        [
          _c("div", { staticClass: "announcementdetailsxi" }, [
            _c("p", { staticClass: "op" }, [_vm._v("培训通知")]),
            _c("p", { on: { click: _vm.previouspage } }, [
              _vm._v("返回上一页"),
            ]),
          ]),
          _c("div", { staticClass: "announcementdetailsone" }, [
            _c("p", [_vm._v(_vm._s(_vm.detailist.title))]),
            _c("p", [
              _vm._v(
                " 作者：" +
                  _vm._s(_vm.detailist.createName) +
                  " 发布时间：" +
                  _vm._s(_vm.detailist.createTime) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "announcementdetailstwe" }, [
            _c("p", { domProps: { innerHTML: _vm._s(_vm.detailist.content) } }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }