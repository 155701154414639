"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _validate = require("@/utils/validate");
var _auth = require("@/utils/auth");
var _eventBus = require("@/utils/event-bus.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Login',
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!(0, _validate.validUsername)(value)) {
        callback(new Error('Please enter the correct user name'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('The password can not be less than 6 digits'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        mobile: '',
        passwd: ''
      },
      loginRules: {
        mobile: [{
          required: true,
          trigger: 'blur'
        }],
        passwd: [{
          required: true,
          trigger: 'blur'
        }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    this.userInfo = (0, _auth.getUserInfo)();
    if (this.userInfo) {
      this.$router.push({
        path: this.redirect || '/allExamination/1'
      });
    }
  },
  methods: {
    showPwd: function showPwd() {
      var _this = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          _this2.$store.dispatch('user/login', _this2.loginForm).then(function () {
            _this2.$store.dispatch('user/getInfo').then(function (res) {
              console.log('刷新页面');
              _this2.$router.push({
                path: '/allExamination/1'
              });
              _this2.loading = false;
              _this2.dialogAddVisible = false;
              _this2.dialogAddVisiblelist = false;
              _this2.hasToken = _this2.$store.getters.token;
              _this2.followxiehuifun();
              setTimeout(function () {
                // location.reload() // 强制刷新
                console.log('刷新页面');
                _this2.$router.push({
                  path: '/allExamination/1'
                });
              }, 100);
              if (res.userInfo.identityStatus != 2) {
                _this2.$router.push({
                  path: '/personalCenter/personalInfo',
                  query: {
                    index: 0,
                    type: res.userInfo.identityStatus
                  }
                });
              }
            }).catch(function () {
              _this2.loading = false;
            });
            // this.$router.push({ path:this.redirect || '//allExamination/1' })
            _this2.loading = false;
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    showRegisterMessageBox: function showRegisterMessageBox() {
      console.log('showRegisterMessageBox');
      _eventBus.EventBus.$emit('call-nav-register');
    },
    showFindPasswordMessageBox: function showFindPasswordMessageBox() {
      _eventBus.EventBus.$emit('call-nav-findpassword');
    }
  }
};