"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.function.name.js");
var _objectSpread2 = _interopRequireDefault(require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _table = require("@/api/table");
var _request = _interopRequireDefault(require("@/utils/request.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger"
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      total: 0,
      page: 1,
      size: 10,
      selId: ""
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name'])),
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      this.listLoading = true;
      var dataBody = {
        page: this.page,
        size: this.size,
        cate: 5,
        search: ""
      };
      (0, _table.getCollection)(dataBody).then(function (response) {
        if (response.code == 200) {
          _this.list = response.data.content;
          _this.total = response.data.totalElements;
        } else {
          _this.$message.error("数据请求失败");
        }
        _this.listLoading = false;
      });
    },
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    CancelCollection: function CancelCollection(item) {
      var _this2 = this;
      //取消收藏
      this.$alert("<strong><div><div style=\"color: #666666;font-size: 0.9rem;\">".concat(this.name, "\u540C\u5B66\uFF1A</div>") + '<div style="color: #303030;font-size: 0.9rem;margin-top:0.5rem;">是否取消收藏课程《' + item.itemName + "》？</div>" + " </div></strong>", "取消收藏", {
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        cancelButtonText: "取消",
        showConfirmButton: true,
        confirmButtonText: "确定"
      }).then(function () {
        var dataBody = {
          //取消收藏参数
          serverId: item.id,
          cate: 5
        };
        /*取消收藏接口*/
        (0, _table.CancelCollection)(dataBody).then(function (response) {
          if (response.code == 200) {
            _this2.$message({
              type: "success",
              message: "取消收藏成功!"
            });
            /*取消成功之后  刷新数据*/
            _this2.page = 1;
            _this2.fetchData();
          } else {
            _this2.$message.error("数据请求失败");
          }
        });
      }).catch(function () {});
    },
    /*立即支付*/ImmediatePayment: function ImmediatePayment(item) {
      this.$router.push('/teachinfo/' + item.id);
      // request({
      //   url: "/order/buyTrain",
      //   method: "post",
      //   params: {
      //     courseId: item.id
      //   }
      // }).then(response => {
      //   if (response.code == 200) {
      //     let oidlist = response.data.id;
      //     this.$router.push({
      //       path: "/payment/" + response.data.id
      //     });
      //   }
      // });
    },
    /*分页点击之后数据处理*/handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.fetchData();
    }
  }
};