"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var setFavicon = function setFavicon(host) {
  var icon = '';
  var title = '';
  console.log('host', host);
  if (host === 'cfpx.hangxintong.cn') {
    icon = 'https://res.hangxintong.cn/cfpx.ico';
    title = '赤峰建筑业培训中心';
  } else if (host === 'www.zsjakj.com') {
    icon = 'https://res.hangxintong.cn/251favicon.png';
    title = '水利三类人员教育系统';
  }
  if (!icon) return;
  var link = document.querySelector('link[real*=icon]') || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = icon;
  document.getElementsByTagName('head')[0].appendChild(link);
  document.title = title;
};
var _default = exports.default = setFavicon;