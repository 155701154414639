"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _table = require("@/api/table");
var _allInvoice = _interopRequireDefault(require("./invoice/allInvoice.vue"));
var _applyingInvoice = _interopRequireDefault(require("./invoice/applyingInvoice.vue"));
var _appliedInvoice = _interopRequireDefault(require("./invoice/appliedInvoice.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    allInvoice: _allInvoice.default,
    applyingInvoice: _applyingInvoice.default,
    appliedInvoice: _appliedInvoice.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger"
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      currentRole: _allInvoice.default,
      selIndex: 0,
      showType: 1,
      detailsContent: ""
    };
  },
  created: function created() {
    var mySelf = this;
    /*点击查看详情带过来id*/
    eventBus.$on("showDetails", function (data) {
      mySelf.showType = 2;
      mySelf.detailsContent = data;
    });
  },
  watch: {
    detailsContent: function detailsContent(val) {}
  },
  methods: {
    fetchData: function fetchData() {
      this.listLoading = true;
      // getList().then(response => {
      //   this.list = response.data.items

      // })
      this.listLoading = false;
    },
    itemClick: function itemClick(key) {
      this.selIndex = key;
      if (key == 0) {
        this.currentRole = _allInvoice.default;
      } else if (key == 1) {
        this.currentRole = _applyingInvoice.default;
      } else if (key == 2) {
        this.currentRole = _appliedInvoice.default;
      }
    },
    back: function back() {
      this.showType = 1;
    }
  }
};