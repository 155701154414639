"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.includes.js");
var _train = require("@/api/train");
// 点击播放按钮时，首先校验是否为一级建造师课程，如果是，请求接口获取跳转链接地址，完成页面跳转，如果不是不做处理

var goToStudyMixins = {
  data: function data() {
    return {
      // 课程id
      courseId: '',
      // 一级建造师课程标识数组
      typeListMixin: ['hyxxjzgczhuxiangzdyz', 'hyxxjzgczengxiangzdyz', 'hyxxglgczhuxiangzdyz', 'hyxxglgczengxiangzdyz', 'hyxxtlgczhuxiangzdyz', 'hyxxtlgczengxiangzdyz', 'hyxxslsdgczhuxiangzdyz', 'hyxxslsdgczengxiangzdyz', 'hyxxszgygczhuxiangzdyz', 'hyxxszgygczengxiangzdyz', 'hyxxkygczhuxiangzdyz', 'hyxxkygczengxiangzdyz', 'hyxxjdgczhuxiangzdyz', 'hyxxjdgczengxiangzdyz']
    };
  },
  methods: {
    goToStudyMix: function goToStudyMix(courseId) {
      var _this = this;
      this.courseId = courseId;
      return new Promise(function (resolve, reject) {
        _this.checkCourseMix().then(function () {
          _this.getLinkMix();
        }).catch(function () {
          resolve();
        });
      });
    },
    /**
     * 校验课程是否为一级建造师
     */
    checkCourseMix: function checkCourseMix() {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        (0, _train.getValidateType)(_this2.courseId).then(function (res) {
          var data = res.data;
          if (!data.validateMethod) {
            return reject();
          }
          if (_this2.typeListMixin.includes(data.validateMethod)) {
            // 是一级建造师课程
            return resolve();
          }
          // 不是一级建造师课程
          reject();
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    /**
     * 获取跳转链接
     */
    getLinkMix: function getLinkMix() {
      var _this3 = this;
      (0, _train.getStudyUrl)(this.courseId).then(function (res) {
        var data = res.data;
        if (data) {
          return window.open(data);
        }
        _this3.$message.error("跳转链接获取失败");
      });
    }
  }
};
var _default = exports.default = goToStudyMixins;