"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.search.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _swiperBundleMin = _interopRequireDefault(require("swiper/swiper-bundle.min.js"));
require("swiper/swiper-bundle.min.css");
var _mStore = _interopRequireDefault(require("@/store/modules/mStore.js"));
var _request = _interopRequireDefault(require("@/utils/request.js"));
var _table = require("../../api/table");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var mPlayAuther = '';
var oids = '';
var _default = exports.default = {
  data: function data() {
    return {
      activeNames: ['1'],
      list: '',
      mUrl: _mStore.default.mUrl,
      dialogVideoVisible: false,
      updateVideoWrap: true,
      coursePlayList: [],
      tokenList: '',
      order: ''
    };
  },
  created: function created() {
    var _this = this;
    this.tokenList = (0, _auth.getToken)();
    this.oid = this.$route.params && this.$route.params.id;
    oids = this.oid;
    // this.getlist();
    (0, _request.default)({
      url: '/train/config/' + this.oid,
      method: 'get'
    }).then(function (response) {
      //  isCollect
      if (response.code == 200) {
        _this.order = response.data.order;
        if (_this.order.status == 2) {
          _this.getlist1();
        } else {
          _this.getlist();
        }
      }
    });
  },
  methods: {
    getlist: function getlist() {
      var _this2 = this;
      (0, _request.default)({
        url: '/train/config/' + this.oid,
        method: 'get'
      }).then(function (response) {
        if (response.code == 200) {
          _this2.list = response.data.searchSplit;
        }
      });
    },
    getlist1: function getlist1() {
      var _this3 = this;
      (0, _request.default)({
        url: '/train/classhours/courselits',
        method: 'get',
        params: {
          trainId: this.oid
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this3.list = response.data.search.class;
        }
      });
    },
    mChangePlay: function mChangePlay(item) {
      this.coursePlayList.chapters.forEach(function (e) {
        if (e.section) {
          e.section.forEach(function (el) {
            el.isPlay = false;
          });
        }
      });
      this.playVideo(item);
    },
    playVideo: function playVideo(item, list) {
      var _this4 = this;
      if (item.isTryLearn == 1 || this.order.status == 2) {
        this.dialogVideoVisible = true;
        if (list) {
          this.coursePlayList = list;
          this.mteachers = list.teachers;
        }
        this.coursePlayList.chapters.forEach(function (e) {
          if (e.section) {
            e.section.forEach(function (el) {
              el.isPlay = false;
            });
          }
        });
        item.isPlay = true;
        this.updateVideoWrap = false;
        this.$nextTick(function () {
          _this4.updateVideoWrap = true;
        });
        (0, _request.default)({
          url: '/videoUpload/play',
          method: 'post',
          params: {
            vid: item.content
          }
        }).then(function (res) {
          mPlayAuther = res.data.PlayAuth;
          var player = new Aliplayer({
            id: 'player-con',
            width: '100%',
            height: '32.3rem',
            autoplay: false,
            vid: item.content,
            playauth: mPlayAuther,
            isLive: false,
            rePlay: false,
            playsinLine: true,
            preload: false,
            // 私密播放
            encryptType: 1,
            controlBarVisibility: 'hover',
            skinLayout: [{
              name: 'bigPlayButton',
              align: 'blabs',
              x: 30,
              y: 80
            }, {
              name: 'H5Loading',
              align: 'cc'
            }, {
              name: 'controlBar',
              align: 'blabs',
              x: 0,
              y: 0,
              children: [
              //{name: "progress", align: "tlabs", x: 0, y: 0},
              {
                name: 'playButton',
                align: 'tl',
                x: 10,
                y: 10
              }, {
                name: 'timeDisplay',
                align: 'tl',
                x: 5,
                y: 5
              }, {
                name: 'fullScreenButton',
                align: 'tr',
                x: 10,
                y: 10
              }, {
                name: 'volume',
                align: 'tr',
                x: 10,
                y: 10
              }, {
                name: 'setting',
                align: 'tr',
                x: 10,
                y: 10
              }]
            }]
          }, function (player) {
            var _this5 = this;
            player.play();
            player.on('ended', function (e) {
              var timelist = player.getCurrentTime();
              _this5.timeOlist = parseInt(timelist);
              (0, _request.default)({
                url: '/train/studyLog/add',
                method: 'post',
                data: {
                  address: '',
                  cate: 2,
                  chapterId: item.chapterId,
                  courseId: item.courseId,
                  sectionId: item.sectionId,
                  status: 2,
                  time: _this5.timeOlist,
                  trainId: oids
                }
              }).then(function (response) {
                if (response.code == 200) {
                  (0, _request.default)({
                    url: '/train/classhours/courselits',
                    method: 'get',
                    params: {
                      trainId: oids
                    }
                  }).then(function (response) {
                    if (response.code == 200) {
                      _this5.list = response.data.search.class;
                      location.reload();
                    }
                  });
                }
              }).catch(function (err) {
                _this5.player && _this5.player.pause();
                _this5.dialogVideoVisible = false;
                _this5.timerHandle('stop');
                _this5.saveTimerHandle('stop');
                _this5.$alert('学习进度保存异常，无法继续播放', '提示', {
                  confirmButtonText: '确定'
                });
              });
            });
            player.on('pause', function (e) {
              var timelist = player.getCurrentTime();
              _this5.timeOlist = parseInt(timelist);
              (0, _request.default)({
                url: '/train/studyLog/add',
                method: 'post',
                data: {
                  address: '',
                  cate: 2,
                  chapterId: item.chapterId,
                  courseId: item.courseId,
                  sectionId: item.sectionId,
                  status: 1,
                  time: _this5.timeOlist,
                  trainId: oids
                }
              }).then(function (response) {
                if (response.code == 200) {
                  (0, _request.default)({
                    url: '/train/classhours/courselits',
                    method: 'get',
                    params: {
                      trainId: oids
                    }
                  }).then(function (response) {
                    if (response.code == 200) {
                      _this5.list = response.data.search.class;
                      location.reload();
                    }
                  });
                }
              }).catch(function (err) {
                _this5.player && _this5.player.pause();
                _this5.dialogVideoVisible = false;
                _this5.timerHandle('stop');
                _this5.saveTimerHandle('stop');
                _this5.$alert('学习进度保存异常，无法继续播放', '提示', {
                  confirmButtonText: '确定'
                });
              });
            });
            player.on('error', function (e) {
              var timelist = player.getCurrentTime();
              _this5.timeOlist = parseInt(timelist);
              (0, _request.default)({
                url: '/train/studyLog/add',
                method: 'post',
                data: {
                  address: '',
                  cate: 2,
                  chapterId: item.chapterId,
                  courseId: item.courseId,
                  sectionId: item.sectionId,
                  status: 1,
                  time: _this5.timeOlist,
                  trainId: oids
                }
              }).then(function (response) {
                if (response.code == 200) {
                  (0, _request.default)({
                    url: '/train/classhours/courselits',
                    method: 'get',
                    params: {
                      trainId: oids
                    }
                  }).then(function (response) {
                    if (response.code == 200) {
                      _this5.list = response.data.search.class;
                      location.reload();
                    }
                  });
                }
              }).catch(function (err) {
                _this5.player && _this5.player.pause();
                _this5.dialogVideoVisible = false;
                _this5.timerHandle('stop');
                _this5.saveTimerHandle('stop');
                _this5.$alert('学习进度保存异常，无法继续播放', '提示', {
                  confirmButtonText: '确定'
                });
              });
            });
          });
        });
      } else {
        if (this.tokenList && item.isTryLearn == 0) {
          this.$message({
            title: '提示',
            message: '请你先报名',
            type: 'info'
          });
        } else {
          this.$message({
            title: '提示',
            message: '请你先登录',
            type: 'info'
          });
        }
      }
    },
    computedIndex: function computedIndex(index) {
      if (index < 10) {
        return '0' + index + '. ';
      } else {
        return index + '. ';
      }
    }
  }
};