"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VAILDATOR_COMMON = void 0;
exports.formatDate = formatDate;
exports.getFullTime = getFullTime;
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.dot-all.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.sticky.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.replace.js");
var VAILDATOR_COMMON = exports.VAILDATOR_COMMON = {
  // 编辑器
  EDIT_MAX_LEG_TIP: '最大输入10000字符',
  // 邮箱
  EMAIL_TIP: '格式不正确',
  EMAIL_TIPS: '最大输入90字符',
  EMAIL_REGEXP: /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i,
  EMAI_LEG: {
    MAX: 90
  },
  // 固定电话号码
  TEL_NOEXIT_TIP: '您输入的手机号码不存在，请核对后重新输入',
  TEL_TIP: '格式不正确（区号-电话号码）',
  TEL_REGEXP: /(^0(\d{2})[2-9](\d{7})$)|(^0(\d{3})[2-9](\d{6})$)/,
  TEL_LANDLINE: /0\d{2,3}-\d{7,8}$/,
  // 手机号
  PHONE_TIP: '请输入正确的手机号码格式',
  PHONE_REGEXP: /^((1[3,5,8,4][0-9])|(14[5,7])|(17[0,3,6,7,8])|(19[7,8,9])|(16[6]))\d{8}$/,
  // 银行卡号码
  BACK_CARD_REGEXP: /^[1-9]\d{9,19}$/,
  BACK_CARD_TIP: '请输入正确的银行卡号码',
  // QQ号码
  QQ_REGEXP: /^[1-9]\d{4,11}$/,
  QQ_TIP: '请输入正确的QQ号码',
  // 手机验证码:4位数字
  CODE_TIP: '请输入4位数字',
  CODE_REGEXP: /^\d{4}$/,
  // 用户账号：2至12位大小写字母和数字
  ACCOUNT_TIP: '请输入2至12位汉字、大小写字母或数字',
  LONG_NAME_TIP: '请输入1至50位汉字、符号、大小写字母或数字',
  ACCOUNT_REGEXP: /^[\u4e00-\u9fa5a-zA-Z0-9]{2,30}$/,
  ACCOUNT_LEG: {
    MIN: 2,
    MAX: 16
  },
  // 用户密码：6至20位大小写字母和数字
  PSW_TIP: '请输入6至20位字母或数字(不允许输入纯空格)',
  PSW_REGEXP: /^[a-zA-Z0-9]{6,20}$/,
  PSW_LEG: {
    MIN: 6,
    MAX: 20
  },
  // Code代码
  DIC_CODE_DiGIT_TIP: '只允许1位数字',
  // author：P.J，remark：一位数字
  DIC_CODE_DiGIT: /^\d{1}$/,
  // author：P.J，remark：一位数字
  BANK_CODETIP: '只允许2位数字',
  // author：P.J，remark：2位数字
  BANK_CODE_REGEXP: /^(\d{2})$/,
  // author：P.J，remark：2位数字
  // 2至6位纯数字
  BANK_CODE_TIP: '只允许2至8位数字',
  // author：P.J，remark：2到6位数字
  BANK_CODENUM_REGEXP: /^(\d{2,8})$/,
  // 2到8位数字
  // 2至3位纯数字
  BANK_CODE_MSG: '只允许2至3位数字',
  // author：P.J，remark：2到3位数字
  BANK_CODE_TWO_THREE: /^(\d{2,3})$/,
  // author：P.J，remark：2到3位数字
  DIC_CODE_TIP: '只允许字母、数字或短横线（-）',
  DIC_CODE_REGEXP: /^[a-zA-Z\d-]+$/,
  CODEMAX_TIP: '最大输入12个字符',
  DIC_CODE_LEG: {
    MIN: 1,
    MAX: 12
  },
  // 真实姓名:2至16位汉字
  REALNAME_TIP: '最多可输入20个有效字符',
  REALNAME_TIP_MES: '请输入2-20位有效汉字',
  REALNAME_TIPTEST: /^[\u4E00-\u9FA5\uF900-\uFA2D|\w]{2,20}$/,
  REALNAME_REGEXP: /[\u4E00-\u9FA5\uf900-\ufa2d]|[A-Za-z]{2,20}$/,
  REALNAME_REGEXPS: /^([\u4e00-\u9fa5]){2,20}$/,
  // 身份证号
  ID_CARD_TIP: '身份证格式不正确',
  ID_CARD_REGEXP: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|30|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/,
  // ID_CARD_REGEXP: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
  // 单位名称：1至50位汉字、大小写字母和数字
  COM_NAME_TIP: '最多可输入50个有效字符',
  COM_NAME_TIPS: '请输入有效字符',
  COM_CHARACTER_MESS: '最多可输入50个字符',
  //COM_NAME_REGEXP: /^[\u4E00-\u9FA5\uF900-\uFA2D|\w]{1,16}$/,
  COM_NAME_REGEXP: /^[\u4e00-\u9fa5A-Za-z0-9]{1,50}$/,
  COM_CHARACTER: /^[\u4e00-\u9fa5]{1,20}$/,
  COM_NAME_REGEXPS: /^[\s\u4e00-\u9fa5a-z0-9_-]{0,}$/,
  COM_NAME_LEG: {
    MAX: 50
  },
  // 单位营业执照号
  COM_LIC_TIP: '请输入15至18位数字或大写字母',
  COM_LIC_REGEXP: /^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){7,10}$/,
  // 银行账户：9至22位纯数字
  BANK_NUM_TIP: '请输入9至22位数字',
  BANK_NUM_REGEXP: /^(\d{9,22})$/,
  // 地址：1至100位字符
  ADDRESS_TIP: '最大可输入255个字符',
  ADDRESS_REGEXP: /^[\u4e00-\u9fa5A-Za-z0-9]{1,254}$/,
  ADDRESS_LEG: {
    MAX: 100
  },
  //特长字符
  POINT_TIP: '最大可输入50个字符',
  POINT_REGEXP: /^[\s\S]{0,50}$/,
  POINT_LEG: {
    MAX: 50
  },
  // 标题：1至50位汉字、大小写字母和数字
  TITLE_TIPS: '最大可输入50个字符',
  TITLE_TIPSs: '最大可输入30个字符',
  TITLE_TIP: '请输入汉字、大小写字母或数字',
  TITLE_REGEXPs: /^[\u4e00-\u9fa5A-Za-z0-9]{1,30}$/,
  TITLE_REGEXP: /^[\u4e00-\u9fa5A-Za-z0-9]{1,50}$/,
  TITLE_LEG: {
    MAX: 50
  },
  CON_NAME_REGEXP: /^[\u4e00-\u9fa5A-Za-z0-9]{1,36}$/,
  CON_NAME_LEG: {
    MAX: 36
  },
  // textarea文本框：1至2000位字符
  TEXTAREA_TIP: '最大可输入1000个字符',
  TEXTAREA_TEXTAREAREQ: '必填项',
  TEXTAREA_REGEXP: /^[\s\S]{1,1000}$/,
  TEXTAREA_LEG: {
    MAX: 1000
  },
  //机构名称
  ORGAN_TIP: '最多可输入30个字符',
  POINT_REGEXPS: /^[\s\S]{0,30}$/,
  // TEXTAREA_TEXTAREAREQ: '必填项',
  ORGAN_REGEXP: /^[\u4e00-\u9fa5|\w]{1,20}$/,
  // TEXTAREA_LEG: {
  //   MAX: 2000
  // },
  // //详细地址文本框
  // ADDRESS_TIP: '请输入有效字符,最大可输入255字符',
  // TEXTAREA_TEXTAREAREQ: '必填项',
  // ADDRESS_REGEXP:/^[\u4E00-\u9FA5\uF900-\uFA2D|\w]{1,255}$/,
  // TEXTAREA_LEG: {
  //   MAX: 2000
  // },

  SUGGESTION_TIP: '最大可输入1000个字符',
  SUGGESTION_REAREQ: '必填项',
  SUGGESTION_REGEXP: /^[\s\S]{1,999}$/,
  SUGGESTION_LEG: {
    MAX: 90
  },
  // 述职述责
  DEBR_TIP: '最大可输入5000个字符',
  DEBR_REGEXP: /^[\s\S]{1,5000}$/,
  DEBR_LEG: {
    MAX: 5000
  },
  // 规格参数
  SPEC_TIP: '最大可输入10000个字符',
  SPEC_REGEXP: /^[\s\S]{1,10000}$/,
  SPEC_LEG: {
    MAX: 10000
  },
  // 校验存在
  EXIT_TIP: '已经存在，请重新输入',
  // 校验存在
  EXIT_TIP_SELECT: '已经存在，请重新选择',
  // 无效字符
  NOVOLID: '请输入汉字、符号、大小写字母或数字',
  // 日期不能为空
  DATEREQUIRE: '日期不能为空',
  // 时间不能为空
  TIMEREQUIRE: '时间不能为空',
  // 验证中...
  VALIDATE_TIP: '校验中...',
  // 服务器异常
  SERVER_TIP: '服务器校验异常',
  // input 为必填项
  INPUT_REQUIRE: '必填项',
  // select 为必填项
  SELECT_REQUIRE: '必选项',
  // 数字: 正整数
  NUMBER_REGEXP: /^([0-9]*[1-9][0-9]*)$/,
  // 数字
  NUMBER_ALL_REGEXP: /^[0-9]+.?[0-9]*$/,
  // 数字: 正数，小数点不超过两位
  PRICE_TIP: '请输入正数，小数点不超过两位',
  PRICE_CHECK: /^(([1-9]\d*)|([0-9]\d*\.\d?[1-9]{3}))$/,
  // 小数：整数不超过15位，小数点不超过两位
  INTEGER_TIP: '整数部分不超过15位，小数点不超过两位',
  INTEGER_CHECK: /(^[1-9]([0-9]{0,13})?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
  // 万元单位正则小数点后4为
  PRICE_TIPE: '请输入正数，小数点不超过四位',
  PRICE_CHECKE: /^(([1-9]\d*)|([0-9]\d*\.\d?[0-9]{1,3}))$/,
  PRICE_REGEXP: /^((([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2}))))$/,
  // 单位注册资金（万）：1至8位正整数（首数字不能为0）
  COM_CAPITAL_TIP: '请输入1至8位数字（首数字不能为0）',
  COM_CAPITAL_REGEXP: /^([1-9](\d{0,7}))$/,
  // 超短名称：1至10位字符
  Small_NAME_REGEXP: /^([\s\S]){1,10}$/,
  Small_NAME_LEG: {
    MIN: 1,
    MAX: 10
  },
  // 短名称：2至12位字符
  SHORT_NAME_REGEXP: /^([\s\S]){2,12}$/,
  SHORT_NAME_LEG: {
    MIN: 2,
    MAX: 12
  },
  // 一般中长度名称：2至30位字符
  COMMON_NAME_REGEXP: /^([\s\S]){2,30}$/,
  COMMON_NAME_LEG: {
    MIN: 2,
    MAX: 30
  },
  // 长名称：2至50位
  LONG_NAME_REGEXP: /^([\s\S]){1,50}$/,
  LONG_NAME_LEG: {
    MIN: 1,
    MAX: 50
  },
  // 只能輸入數字
  CONAMOUNT_TIP: '只能输入数字',
  CONAMOUNT: /^[0-9]+([.]{1}[0-9]+)?$/,
  // 支付比例 1到100
  PAY_PERCENT_TIP: '请输入1到100之间的整数',
  PAY_PERCENT: /^(([1-9]\d?)|100)$/,
  // 项目名称
  PRO_NUM_TIP: '请输入1至20位大小写字母或数字',
  PRO_NUM_TIPS: '请输1到50之间的字母或数字',
  PRO_NUM_REGEXP: /^[a-zA-Z0-9]*$/,
  PERSON_TIP: '请输入6位以内整数',
  MONEY_TIP: '整数10位以内，小数两位以内',
  PERSON_REGEXP: /^([1-9](\d{0,5}))$/,
  GIFT_TIP: '请输入1至10位数字（首数字不能为0）',
  GIFT_REGEXP: /^([1-9](\d{0,9}))$/,
  MONEY_REGEXP: /(^[1-9]([0-9]{0,9})?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
  MONEY_REGEXPONE: /(^[1-9]([0-9]{0,9})?(\.[0-9]{1,2})?$)|(^(1){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
  PERSON_LEG: {
    MIN: 1,
    MAX: 50
  },
  PRO_NUM_LEG: {
    MIN: 1,
    MAX: 20
  }
};
function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      var str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}
function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}

// 返回 年月日时分秒
function getFullTime() {
  var now = new Date();
  var year = now.getFullYear(); //获取完整的年份(4位,1970-????)
  var month = now.getMonth() + 1; //获取当前月份(0-11,0代表1月)
  var today = now.getDate(); //获取当前日(1-31)
  var hour = now.getHours(); //获取当前小时数(0-23)
  var minute = now.getMinutes(); //获取当前分钟数(0-59)
  var second = now.getSeconds(); //获取当前秒数(0-59)
  var nowTime = '';
  nowTime = year + '-' + fillZero(month) + '-' + fillZero(today) + ' ' + fillZero(hour) + ':' + fillZero(minute) + ':' + fillZero(second);
  return nowTime;
}
function fillZero(str) {
  var realNum;
  if (str < 10) {
    realNum = '0' + str;
  } else {
    realNum = str;
  }
  return realNum;
}