"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkSignUpInfo = checkSignUpInfo;
exports.deleteSignUp = deleteSignUp;
exports.deleteSignUsp = deleteSignUsp;
exports.getApplyClazzDetailById = getApplyClazzDetailById;
exports.getRegistrationListByStatus = getRegistrationListByStatus;
exports.getStudyUrl = getStudyUrl;
exports.getValidateType = getValidateType;
exports.modifyForm = modifyForm;
exports.supplySignUpInfo = supplySignUpInfo;
require("core-js/modules/es.array.concat.js");
var _request = _interopRequireDefault(require("@/utils/request.js"));
var _auth = require("@/utils/auth");
// 获取培训课程的验证方式
function getValidateType(trainId) {
  return (0, _request.default)({
    url: 'zyg/train/validate/getValidateType',
    method: 'get',
    params: {
      assocId: (0, _auth.getAssocId)(),
      trainId: trainId
    }
  });
}
function getStudyUrl(trainItemId) {
  return (0, _request.default)({
    url: "zyg/train/specialuser/skipLoginHongYu/".concat(trainItemId),
    method: 'get'
  });
}

// 删除报名信息
function deleteSignUsp(enrollInfoId) {
  return (0, _request.default)({
    url: 'zyg//train/validate/deleteSignUp',
    method: 'DELETE',
    params: {
      enrollInfoId: enrollInfoId
    }
  });
}

// 查询已报名的学员是否需要补充报名信息
function supplySignUpInfo(trainId) {
  return (0, _request.default)({
    url: "zyg/train/validate/supplySignUpInfo",
    method: 'get',
    params: {
      assocId: (0, _auth.getAssocId)(),
      trainId: trainId
    }
  });
}

// 报名提交
function checkSignUpInfo(trainId, form) {
  return (0, _request.default)({
    url: "zyg/train/validate/checkSignUpInfo/".concat((0, _auth.getAssocId)(), "/").concat(trainId),
    method: 'post',
    data: form
  });
}

// 根据状态查询报名列表
function getRegistrationListByStatus(status) {
  return (0, _request.default)({
    url: "zyg/train/validate/findVerifyResultByPage",
    method: 'get',
    params: {
      status: status,
      assocId: (0, _auth.getAssocId)()
    }
  });
}

// 删除报名信息
function deleteSignUp(enrollInfoId) {
  return (0, _request.default)({
    url: "zyg/train/validate/deleteSignUp",
    method: 'delete',
    params: {
      enrollInfoId: enrollInfoId
    }
  });
}

/**
 * 修改报名表单
 * @param {*} data
 * @returns
 */
function modifyForm(data) {
  return (0, _request.default)({
    url: "zyg/train/validate/editForm",
    method: "post",
    data: data
  });
}

/**
 * 查询当前报名信息详情（修改表单反显用）
 * @param {*} id
 * @returns
 */
function getApplyClazzDetailById(id) {
  return (0, _request.default)({
    url: "zyg/train/validate/details/".concat(id),
    method: "get"
  });
}