"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AppMain", {
  enumerable: true,
  get: function get() {
    return _AppMain.default;
  }
});
Object.defineProperty(exports, "Footbar", {
  enumerable: true,
  get: function get() {
    return _Footbar.default;
  }
});
Object.defineProperty(exports, "Navbar", {
  enumerable: true,
  get: function get() {
    return _Navbar.default;
  }
});
Object.defineProperty(exports, "Sidebar", {
  enumerable: true,
  get: function get() {
    return _Sidebar.default;
  }
});
var _Navbar = _interopRequireDefault(require("./Navbar"));
var _Sidebar = _interopRequireDefault(require("./Sidebar"));
var _AppMain = _interopRequireDefault(require("./AppMain"));
var _Footbar = _interopRequireDefault(require("./Footbar"));