"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/web.url.js");
require("core-js/modules/web.url-search-params.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _defineProperty2 = _interopRequireDefault(require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _swiperBundleMin = _interopRequireDefault(require("swiper/swiper-bundle.min.js"));
require("swiper/swiper-bundle.min.css");
var _mStore = _interopRequireDefault(require("@/store/modules/mStore.js"));
var _request = _interopRequireDefault(require("@/utils/request.js"));
var _table = require("../../api/table");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var mPlayAuther = "";
var _default = exports.default = {
  data: function data() {
    var _ref;
    return _ref = {
      activeNames: ["1"],
      list: "",
      mUrl: _mStore.default.mUrl,
      tokenList: "",
      dialogVideoVisible: false,
      updateVideoWrap: true,
      coursePlayList: [],
      visible: false,
      //弹窗
      loading: false,
      //上传按钮加载
      os: false,
      //控制摄像头开关
      thisVideo: null,
      thisContext: null,
      thisCancas: null,
      videoWidth: 500,
      videoHeight: 400,
      postOptions: [],
      CertCtl: ""
    }, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "loading", true), "ids", []), "single", true), "multiple", true), "total", 0), "akworkerList", []), "workerTypeOptions", []), "title", ""), "open", false), "queryParams", {
      pageNum: 1,
      pageSize: 10,
      imgSrc: undefined
    }), (0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "form", {}), "rules", {});
  },
  created: function created() {
    this.tokenList = (0, _auth.getToken)();
    this.oid = this.$route.params && this.$route.params.id;
    this.getlist();
  },
  methods: {
    getlist: function getlist() {
      var _this2 = this;
      (0, _request.default)({
        url: "/train/config/" + this.oid,
        method: "get"
      }).then(function (response) {
        if (response.code == 200) {
          _this2.list = response.data.requirdClass;
        }
      });
    },
    mChangePlay: function mChangePlay(item) {
      this.coursePlayList.chapters.forEach(function (e) {
        // e.section.forEach(el => {
        //   el.isPlay = false;
        // });
      });
      this.playVideo(item);
    },
    playVideo: function playVideo(item, list) {
      var _this3 = this;
      if (item.isTryLearn == 1) {
        this.dialogVideoVisible = true;
        if (list) {
          this.coursePlayList = list;
          this.mteachers = list.teachers;
        }
        this.coursePlayList.chapters.forEach(function (e) {
          // e.section.forEach(el => {
          //   el.isPlay = false;
          // });
        });
        item.isPlay = true;
        this.updateVideoWrap = false;
        this.$nextTick(function () {
          _this3.updateVideoWrap = true;
        });
        (0, _request.default)({
          url: "/videoUpload/play",
          method: "post",
          params: {
            vid: item.content
          }
        }).then(function (res) {
          mPlayAuther = res.data.PlayAuth;
          _this3.player = new Aliplayer({
            id: "player-con",
            width: "100%",
            height: "32.3rem",
            autoplay: false,
            vid: item.content,
            playauth: mPlayAuther,
            isLive: false,
            rePlay: false,
            playsinLine: true,
            preload: false,
            // 私密播放
            encryptType: 1,
            controlBarVisibility: "hover",
            //definition: "OD,HD,SD,LD",
            skinLayout: [{
              name: "bigPlayButton",
              align: "blabs",
              x: 30,
              y: 80
            }, {
              name: "H5Loading",
              align: "cc"
            }, {
              name: "controlBar",
              align: "blabs",
              x: 0,
              y: 0,
              children: [{
                name: "progress",
                align: "tlabs",
                x: 0,
                y: 0
              }, {
                name: "playButton",
                align: "tl",
                x: 10,
                y: 10
              }, {
                name: "timeDisplay",
                align: "tl",
                x: 5,
                y: 5
              }, {
                name: "fullScreenButton",
                align: "tr",
                x: 10,
                y: 10
              }, {
                name: "volume",
                align: "tr",
                x: 10,
                y: 10
              }, {
                name: "setting",
                align: "tr",
                x: 10,
                y: 10
              }]
            }]
          }, function (player) {
            player.play();
          });
        });
        // setTimeout(() => {
        //   this.onTake();
        // }, 3000);
      } else {
        if (this.tokenList) {
          this.$router.push({
            path: "/payment/" + this.oid
          });
        } else {
          this.$message({
            title: "提示",
            message: "请你先登录",
            type: "info"
          });
        }
      }
    },
    computedIndex: function computedIndex(index) {
      if (index < 10) {
        return "0" + index + ". ";
      } else {
        return index + ". ";
      }
    },
    onTake: function onTake() {
      this.visible = true;
      this.getCompetence();
    },
    onCancel: function onCancel() {
      this.visible = false;
      /* this.resetCanvas();*/
      this.stopNavigator();
    },
    // 调用摄像头权限
    getCompetence: function getCompetence() {
      var _this4 = this;
      //必须在model中render后才可获取到dom节点,直接获取无法获取到model中的dom节点
      this.$nextTick(function () {
        var _this = _this4;
        _this4.os = false; //切换成关闭摄像头
        _this4.thisCancas = document.getElementById("canvasCamera");
        _this4.thisContext = _this4.thisCancas.getContext("2d");
        _this4.thisVideo = document.getElementById("videoCamera");
        // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
        if (navigator.mediaDevices === undefined) {
          navigator.menavigatordiaDevices = {};
        }
        // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
        // 使用getUserMedia，因为它会覆盖现有的属性。
        // 这里，如果缺少getUserMedia属性，就添加它。
        if (navigator.mediaDevices.getUserMedia === undefined) {
          navigator.mediaDevices.getUserMedia = function (constraints) {
            // 首先获取现存的getUserMedia(如果存在)
            var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia;
            // 有些浏览器不支持，会返回错误信息
            // 保持接口一致
            if (!getUserMedia) {
              return Promise.reject(new Error("getUserMedia is not implemented in this browser"));
            }
            // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
            return new Promise(function (resolve, reject) {
              getUserMedia.call(navigator, constraints, resolve, reject);
            });
          };
        }
        var constraints = {
          audio: false,
          video: {
            width: _this.videoWidth,
            height: _this.videoHeight,
            transform: "scaleX(-1)"
          }
        };
        navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
          // 旧的浏览器可能没有srcObject
          if ("srcObject" in _this.thisVideo) {
            _this.thisVideo.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            _this.thisVideo.src = window.URL.createObjectURL(stream);
          }
          _this.thisVideo.onloadedmetadata = function (e) {
            _this.thisVideo.play();
          };
        }).catch(function (err) {
          _this4.$message({
            title: "警告",
            message: "没有开启摄像头权限或浏览器版本不兼容.",
            type: "warning"
          });
        });
      });
    },
    //绘制图片
    drawImage: function drawImage() {
      // 点击，canvas画图
      this.thisContext.drawImage(this.thisVideo, 0, 0, this.videoWidth, this.videoHeight);
      // 获取图片base64链接
      this.imgSrc = this.thisCancas.toDataURL("image/png");
      /*const imgSrc=this.imgSrc;*/
    },
    //清空画布
    clearCanvas: function clearCanvas(id) {
      var c = document.getElementById(id);
      var cxt = c.getContext("2d");
      cxt.clearRect(0, 0, c.width, c.height);
    },
    //重置画布
    resetCanvas: function resetCanvas() {
      this.imgSrc = "";
      this.clearCanvas("canvasCamera");
    },
    //关闭摄像头
    stopNavigator: function stopNavigator() {
      if (this.thisVideo && this.thisVideo !== null) {
        this.thisVideo.srcObject.getTracks()[0].stop();
        this.os = true; //切换成打开摄像头
      }
    } /*调用摄像头拍照结束*/
  }
};