"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array-buffer.constructor.js");
require("core-js/modules/es.array-buffer.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/es.typed-array.uint8-array.js");
require("core-js/modules/es.typed-array.at.js");
require("core-js/modules/es.typed-array.copy-within.js");
require("core-js/modules/es.typed-array.every.js");
require("core-js/modules/es.typed-array.fill.js");
require("core-js/modules/es.typed-array.filter.js");
require("core-js/modules/es.typed-array.find.js");
require("core-js/modules/es.typed-array.find-index.js");
require("core-js/modules/es.typed-array.find-last.js");
require("core-js/modules/es.typed-array.find-last-index.js");
require("core-js/modules/es.typed-array.for-each.js");
require("core-js/modules/es.typed-array.includes.js");
require("core-js/modules/es.typed-array.index-of.js");
require("core-js/modules/es.typed-array.iterator.js");
require("core-js/modules/es.typed-array.join.js");
require("core-js/modules/es.typed-array.last-index-of.js");
require("core-js/modules/es.typed-array.map.js");
require("core-js/modules/es.typed-array.reduce.js");
require("core-js/modules/es.typed-array.reduce-right.js");
require("core-js/modules/es.typed-array.reverse.js");
require("core-js/modules/es.typed-array.set.js");
require("core-js/modules/es.typed-array.slice.js");
require("core-js/modules/es.typed-array.some.js");
require("core-js/modules/es.typed-array.sort.js");
require("core-js/modules/es.typed-array.subarray.js");
require("core-js/modules/es.typed-array.to-locale-string.js");
require("core-js/modules/es.typed-array.to-reversed.js");
require("core-js/modules/es.typed-array.to-sorted.js");
require("core-js/modules/es.typed-array.to-string.js");
require("core-js/modules/es.typed-array.with.js");
require("core-js/modules/esnext.array-buffer.detached.js");
require("core-js/modules/esnext.array-buffer.transfer.js");
require("core-js/modules/esnext.array-buffer.transfer-to-fixed-length.js");
require("core-js/modules/web.atob.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/web.dom-exception.constructor.js");
require("core-js/modules/web.dom-exception.stack.js");
require("core-js/modules/web.dom-exception.to-string-tag.js");
require("core-js/modules/web.url.js");
require("core-js/modules/web.url-search-params.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _auth = require("@/utils/auth");
var _elementUi = require("element-ui");
var _examination = require("../../api/examination.js");
var _icon_sel_xz = _interopRequireDefault(require("@/assets/img/icon_sel_xz.png"));
var _icon_wxz = _interopRequireDefault(require("@/assets/img/icon_wxz.png"));
var _icon_ybj = _interopRequireDefault(require("@/assets/img/icon_ybj.png"));
var _icon_wbj = _interopRequireDefault(require("@/assets/img/icon_wbj.png"));
var _request = _interopRequireDefault(require("../../utils/request.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      answerList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'],
      id: '',
      logo: '',
      page_loading: true,
      page_loading_text: '',
      dialogStartExamination: true,
      videoWidth: '170',
      videoHeight: '170',
      imgSrc: '',
      thisCancas: null,
      thisContext: null,
      thisVideo: null,
      openVideo: false,
      type: 1,
      allList: [],
      assocLogo: '',
      userFace: '',
      //
      userName: '',
      //
      problemItem: '',
      //当前展示题的所有信息
      problemItemIndex: 0,
      //当前第几题  下标
      icon_xz: _icon_sel_xz.default,
      icon_wxz: _icon_wxz.default,
      icon_bj_xz: _icon_ybj.default,
      icon_bj_wxz: _icon_wbj.default,
      answerNum: 0,
      //已答题数
      unAnswerNum: 0,
      //未答题数，
      faceTime: 0,
      ///监考拍照间隔时长
      longTime: 0,
      //总考试时长
      starTime: 0,
      //考试开始时间
      examHandTime: 0,
      //最少交卷时间,
      useTime: 0,
      //考试已用时间
      timeText: '',
      interval: null,
      utrId: '',
      errorDialogVisible: false,
      tips: '',
      //提示信息
      errorBtnText: '',
      //错误弹窗  底部按钮显示文字
      correctBtnText: '',
      IsInTakePic: false,
      from: '',
      IsItVerified: false,
      supervisionintervalId: null,
      intervalFlag: false,
      isMarkCount: 0,
      // 标记数量
      examEndTime: null,
      // 考试结束时间
      timeInt: '',
      time: true
    };
  },
  watch: {
    //图片地址变化后  上传图片  进行考前核验人脸
    // imgSrc: function(val) {
    //   if (val) {
    //     this.uploadPic(val)
    //   }
    // }
    imgSrc: {
      handler: function handler(val) {
        if (val) {
          this.uploadPic(val);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    var _this2 = this;
    window.addEventListener('beforeunload', function (e) {
      return _this2.beforeunloadHandler(e);
    });
    this.IsItVerified = localStorage.getItem('IsItVerified');
    this.assocLogo = (0, _auth.getAssocLogo)();
    var from = this.$route.params.id;
    var split = from.split(',');
    console.log(split);
    this.id = split[0];
    this.from = split[1];
    // 获取用户信息
    this.getUserDetails();
    // 获取考题
    this.initQuestionList();
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      // 禁用右键
      document.oncontextmenu = new Function('event.returnValue=false');
      // 禁用选择
      document.onselectstart = new Function('event.returnValue=false');
    });
  },
  beforeDestroy: function beforeDestroy() {
    // 开启选择
    document.onselectstart = new Function('event.returnValue=true');
    this.stopInter();
  },
  destroyed: function destroyed() {
    var _this3 = this;
    window.removeEventListener('beforeunload', function (e) {
      return _this3.beforeunloadHandler(e);
    });
  },
  methods: {
    beforeunloadHandler: function beforeunloadHandler(e) {
      e = e || window.event;
      if (e) {
        e.returnValue = '关闭提示';
      }
      return '关闭提示';
    },
    backHtml: function backHtml() {
      clearInterval(this.interval);
      // this.$router.push('/examinationSystem/examList/1')
      this.$router.go(-1);
    },
    //底部功能按钮点击事件
    buttomBntClick: function buttomBntClick(val) {
      if (val == 1) {
        //标记
        if (this.problemItem.isMark == 1) {
          this.problemItem.isMark = 0;
          this.isMarkCount--;
        } else {
          this.problemItem.isMark = 1;
          this.isMarkCount++;
        }
        var id = this.problemItem.id;
        var isMark = this.problemItem.isMark;
        this.allList = this.allList.map(function (listItem) {
          if (listItem.id == id) {
            listItem.isMark = isMark;
          }
          return listItem;
        });
        //去刷新答题卡
        this.refreshata();
      } else if (val == 2) {
        //上一题
        if (this.problemItemIndex == 0) {
          this.showMsg(2, '已经是第一题');
        } else {
          this.problemItemIndex -= 1;
          this.showQuestion();
          //去刷新答题卡
          this.refreshata();
        }
      } else if (val == 3) {
        //下一题
        if (this.problemItemIndex == this.allList.length - 1) {
          this.showMsg(2, '已经是最后一题');
        } else {
          this.problemItemIndex += 1;
          this.showQuestion();
          //去刷新答题卡
          this.refreshata();
        }
      } else if (val == 4) {
        //交卷
        this.HandInPapers(1);
      }
    },
    //单选题  判断题  多选题 选项点击事件
    choiceItemClick: function choiceItemClick(from, item, id, tqType) {
      console.log(from);
      console.log(item);
      console.log(id);
      console.log(tqType);
      var that = this;
      if (from == 1) {
        //普通题
        if (tqType == 1 || tqType == 3) {
          var selValue = [];
          //将所有选项全部 未选中
          this.problemItem.tqContentOb.options = this.problemItem.tqContentOb.options.map(function (pItem) {
            pItem.sel = 0;
            return pItem;
          });
          //设置对应选中项
          this.problemItem.tqContentOb.options = this.problemItem.tqContentOb.options.map(function (pItem) {
            if (pItem.tags == item.tags) {
              if (pItem.sel == 1) {
                pItem.sel = 0;
              } else {
                pItem.sel = 1;
                selValue.push(item.tags);
              }
            }
            return pItem;
          });
          // 将原数据值改变
          this.allList.map(function (listItem) {
            if (listItem.id == id) {
              listItem = that.problemItem;
              listItem.userAnswer = selValue;
            }
            return listItem;
          });
          //去刷新答题卡
          this.refreshata();
        } else if (tqType == 2) {
          var selValue = [];
          this.problemItem.tqContentOb.options = this.problemItem.tqContentOb.options.map(function (pItem) {
            if (pItem.tags == item.tags) {
              if (pItem.sel == 1) {
                pItem.sel = 0;
              } else {
                pItem.sel = 1;
              }
            }
            if (pItem.sel == 1) {
              selValue.push(pItem.tags);
            }
            return pItem;
          });
          this.allList.map(function (listItem) {
            if (listItem.id == id) {
              listItem = that.problemItem;
              listItem.userAnswer = selValue;
            }
            return listItem;
          });
          this.refreshata();
        }
      } else if (from == 2) {
        //组合题 需反查主题id
        if (tqType == 1 || tqType == 3) {
          //单选或者判断
          var selValue = [];
          //将所有选项全部 未选中
          console.log(JSON.stringify(this.problemItem));
          this.problemItem.children = this.problemItem.children.map(function (pItem) {
            if (pItem.id == id) {
              pItem.tqContentOb.options.map(function (pItemChildrem) {
                pItemChildrem.sel = 0;
                return pItemChildrem;
              });
            }
            return pItem;
          });
          console.log(JSON.stringify(this.problemItem));
          //设置对应选中项
          this.problemItem.children = this.problemItem.children.map(function (pItem) {
            if (pItem.id == id) {
              pItem.tqContentOb.options = pItem.tqContentOb.options.map(function (pItemChildrem) {
                if (pItemChildrem.tags == item.tags) {
                  if (pItemChildrem.sel == 1) {
                    pItemChildrem.sel = 0;
                  } else {
                    pItemChildrem.sel = 1;
                    selValue.push(pItemChildrem.tags);
                  }
                }
                return pItemChildrem;
              });
            }
            return pItem;
          });
          // 将原数据值改变
          this.allList.map(function (listItem) {
            if (listItem.id == that.problemItem.id) {
              listItem = that.problemItem;
              listItem.children = listItem.children.map(function (listChildrenItem) {
                if (listChildrenItem.id == id) {
                  listChildrenItem.userAnswer = selValue;
                }
                return listChildrenItem;
              });
              listItem.userAnswer = selValue;
            }
            return listItem;
          });
          console.log(JSON.stringify(this.allList));
          //去刷新答题卡
          this.refreshata();
        } else if (tqType == 2) {
          //多选
          var selValue = [];
          this.problemItem.children.map(function (pItem) {
            if (pItem.id == id) {
              pItem.tqContentOb.options = pItem.tqContentOb.options.map(function (pItemChildren) {
                if (pItemChildren.tags == item.tags) {
                  if (pItemChildren.sel == 1) {
                    pItemChildren.sel = 0;
                  } else {
                    pItemChildren.sel = 1;
                  }
                }
                if (pItemChildren.sel == 1) {
                  selValue.push(pItemChildren.tags);
                }
                return pItemChildren;
              });
            }
            return pItem;
          });
          console.log(JSON.stringify(this.problemItem));
          this.allList.map(function (listItem) {
            if (listItem.id == that.problemItem.id) {
              listItem = that.problemItem;
              listItem.children.map(function (itemC) {
                if (itemC.id == id) {
                  itemC.userAnswer = selValue;
                  return itemC;
                }
              });
            }
            return listItem;
          });
          this.refreshata();
        }
      }
    },
    //填空题值改变
    completionChange: function completionChange(from, item, id, tqType) {
      var that = this;
      if (from == 1) {
        //普通
        if (tqType == 4) {
          //填空题
          var selValue = [];
          this.problemItem.tqContentOb.options.map(function (pItem) {
            selValue.push(pItem.value);
            return pItem;
          });
          this.allList.map(function (listItem) {
            if (listItem.id == id) {
              listItem = that.problemItem;
              listItem.userAnswer = selValue;
            }
            return listItem;
          });
          this.refreshata();
        } else if (tqType == 5) {
          //问答题
          var selValue = [];
          selValue.push(this.problemItem.value);
          this.allList.map(function (listItem) {
            if (listItem.id == id) {
              listItem = that.problemItem;
              listItem.userAnswer = selValue;
            }
            return listItem;
          });
          this.refreshata();
        }
      } else if (from == 2) {
        //组合
        if (tqType == 4) {
          //填空题
          var selValue = [];
          this.problemItem.children.map(function (pItem) {
            if (pItem.id == id) {
              pItem.tqContentOb.options.map(function (pItemChildren) {
                selValue.push(pItemChildren.value);
                return pItemChildren;
              });
            }
            return pItem;
          });
          this.allList.map(function (listItem) {
            if (listItem.id == that.problemItem.id) {
              listItem = that.problemItem;
              listItem.children.map(function (listChildrenItem) {
                if (listChildrenItem.id == id) {
                  listChildrenItem.userAnswer = selValue;
                }
                return listChildrenItem;
              });
            }
            return listItem;
          });
          this.refreshata();
        } else if (tqType == 5) {
          //问答题
          var selValue = [];
          this.problemItem.children.map(function (item) {
            if (item.id == id) {
              selValue.push(item.value);
            }
            return item;
          });
          this.allList.map(function (listItem) {
            if (listItem.id == that.problemItem.id) {
              listItem = that.problemItem;
              listItem.children.map(function (listChildrenItem) {
                if (listChildrenItem.id == id) {
                  listChildrenItem.userAnswer = selValue;
                }
                return listChildrenItem;
              });
            }
            return listItem;
          });
          this.refreshata();
        }
      }
    },
    //答题卡上题数点击事件
    cardItemClick: function cardItemClick(val) {
      this.problemItemIndex = val;
      this.showQuestion();
    },
    // 调用权限（打开摄像头功能）
    getCompetence: function getCompetence() {
      console.log(55555555555);
      var _this = this;
      _this.thisCancas = document.getElementById('canvasCamera');
      _this.thisContext = _this.thisCancas.getContext('2d');
      _this.thisVideo = document.getElementById('videoCameraTwe');
      _this.thisVideo.style.display = 'block';
      // 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia;
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            //不存在则报错
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      var constraints = {
        audio: false,
        video: {
          width: this.videoWidth,
          height: this.videoHeight,
          transform: 'scaleX(-1)'
        }
      };
      navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
        // 旧的浏览器可能没有srcObject
        if ('srcObject' in _this.thisVideo) {
          _this.thisVideo.srcObject = stream;
        } else {
          // 避免在新的浏览器中使用它，因为它正在被弃用。
          _this.thisVideo.src = window.URL.createObjectURL(stream);
        }
        _this.thisVideo.onloadedmetadata = function (e) {
          _this.thisVideo.play();
        };
      }).catch(function () {});
    },
    //获取考题列表
    initQuestionList: function initQuestionList() {
      var _this4 = this;
      var that = this;
      var dataBody = {
        examPlanId: this.id,
        isTest: this.from
      };
      (0, _examination.getProblems)(dataBody).then(function (response) {
        // console.log(dataBody);
        if (response.code == 200) {
          //数据获取成功
          that.allList = response.data.cards;
          that.unAnswerNum = response.data.cards.length;
          that.allList.map(function (item) {
            item.userAnswer = item.userAnswer ? item.userAnswer : [];
            item.isMark = item.isMark ? item.isMark : 0;
            //单选多选判断题数据特殊处理
            if (item.tqType == 1 || item.tqType == 2 || item.tqType == 3) {
              if (item.userAnswer.length > 0) {
                var _loop = function _loop(i) {
                  item.tqContentOb.options.map(function (children) {
                    if (item.userAnswer[i] == children.tags) {
                      if (children.sel != 1) {
                        children.sel = 1;
                      }
                    }
                    return children;
                  });
                };
                for (var i = 0; i < item.userAnswer.length; i++) {
                  _loop(i);
                }
              } else {
                item.tqContentOb.options.map(function (children) {
                  children.sel = 0;
                  return children;
                });
              }
            } else if (item.tqType == 4) {
              //填空题
              item.tqContentOb.options = [];
              if (item.userAnswer.length > 0) {
                var op = [];
                for (var _i = 0; _i < item.userAnswer.length; _i++) {
                  op.push({
                    value: item.userAnswer[_i]
                  });
                }
                item.tqContentOb.options = op;
              } else {
                var _op = [];
                for (var _i2 = 0; _i2 < item.tkAnswerNum; _i2++) {
                  _op.push({
                    value: ''
                  });
                }
                item.tqContentOb.options = _op;
              }
            } else if (item.tqType == 5) {
              if (item.userAnswer.length != 0) {
                item.value = item.userAnswer[0];
              } else {
                item.value = '';
              }
            } else if (item.tqType == 6) {
              //组合题数据特殊处理
              item.children.map(function (children) {
                if (children.tqType == 1 || children.tqType == 2 || children.tqType == 3) {
                  if (children.userAnswer.length != 0) {
                    var _loop2 = function _loop2(_i3) {
                      children.tqContentOb.options.map(function (childrenItem) {
                        if (children.userAnswer[_i3] == childrenItem.tags) {
                          if (childrenItem.sel != 1) {
                            childrenItem.sel = 1;
                          }
                        }
                        return childrenItem;
                      });
                    };
                    for (var _i3 = 0; _i3 < children.userAnswer.length; _i3++) {
                      _loop2(_i3);
                    }
                  } else {
                    children.tqContentOb.options.map(function (childrenItem) {
                      childrenItem.sel = 0;
                      return childrenItem;
                    });
                  }
                } else if (children.tqType == 4) {
                  children.tqContentOb.options = [];
                  var _op2 = [];
                  if (children.userAnswer.length != 0) {
                    for (var _i4 = 0; _i4 < children.userAnswer.length; _i4++) {
                      _op2.push({
                        value: children.userAnswer[_i4]
                      });
                    }
                    children.tqContentOb.options = _op2;
                  } else {
                    for (var _i5 = 0; _i5 < children.tkAnswerNum; _i5++) {
                      _op2.push({
                        value: ''
                      });
                    }
                    children.tqContentOb.options = _op2;
                  }
                } else if (children.tqType == 5) {
                  if (children.userAnswer.length != 0) {
                    children.value = children.userAnswer[0];
                  } else {
                    children.value = '';
                  }
                }
                return children;
              });
            }
            return item;
          });
          that.utrId = response.data.record.utrId;
          var longTime = response.data.planServiceOne.examTime; //总考试时长
          that.longTime = longTime;
          that.examHandTime = response.data.planServiceOne.examHandTime; //最少交卷时间
          var time = new Date().getTime(); //当前时间
          var startTime = new Date(response.data.record.startTime).getTime(); //考试开始时间
          that.startTime = startTime;
          _this4.examEndTime = new Date(response.data.planServiceOne.examEndTime).getTime();
          console.log(_this4.examEndTime, 'this.examEndTime');
          if (time + longTime * 60 * 1000 < _this4.examEndTime) {
            that.useTime = longTime * 60 * 1000;
            console.log(11);
          } else {
            console.log(22);
            if (time > _this4.examEndTime) {
              console.log(time > _this4.examEndTime);
              that.useTime = 0;
            } else {
              that.useTime = _this4.examEndTime - time;
              console.log(time > _this4.examEndTime, that.useTime, 44);
            }
          }
          that.faceTime = response.data.planServiceOne.examFaceCheckTime;
          if (that.faceTime != '' || that.faceTime != 0) {
            console.log('开启监考模式');
            //监考数据不为空  说明需要监考
            _this4.$nextTick(function () {
              if (that.from == 1) {
                //模拟考试  不开启监考
                //显示摄像头\\
                that.getCompetence();
              } else {
                that.IsInTakePic = true;
              }
              _this4.timeInt = setTimeout(function () {
                console.log('kkkkkkkkkkkk');
                that.invigilatorTakePhoto();
              }, 2000);
              that.supervisionintervalId = setInterval(that.supervisioninterval, that.faceTime * 1000 * 60);
            });
          }
          console.log('未监考模式');
          // if ((time - startTime) / 1000 / 60 - longTime >= 0) {
          //   that.showMsg(2, "考试时间已超时");
          //   return false;
          // } else {
          //启动,func不能使用括号
          that.interval = setInterval(that.testTiminginterval, 1000);
          // }
          //验证是否需要开启监考、
          that.showQuestion();
          that.refreshata();
        } else {
          that.showDialog(1, response.message);
        }
      }).finally(function () {
        _this4.page_loading = false;
      });
    },
    stopInter: function stopInter() {
      console.log('mmmmmmmmmmmmmm');
      clearTimeout(this.timeInt);
    },
    //刷新数据
    refreshata: function refreshata() {
      console.log(111111111);
      var num = 0;
      this.allList.map(function (item) {
        if (item.isMark == 1) {
          //已标记
          //还需要特殊处理组合题
          if (item.tqType == 6) {
            var has = false;
            item.children.map(function (itemChildren) {
              for (var i = 0; i < itemChildren.userAnswer.length; i++) {
                //主要用于填空题处理 ['111','2222']
                if (itemChildren.userAnswer[i] != '') {
                  has = true;
                }
              }
              return itemChildren;
            });
            if (has) {
              //已答题
              item.isMarkType = 3;
              num += 1;
            } else {
              //未答题
              item.isMarkType = 4;
            }
          } else {
            var _has = false;
            for (var i = 0; i < item.userAnswer.length; i++) {
              //主要用于填空题处理 ['111','2222']
              if (item.userAnswer[i] != '') {
                _has = true;
              }
            }
            if (item.userAnswer.length > 0 && _has) {
              //已答题
              item.isMarkType = 3;
              num += 1;
            } else {
              //未答题
              item.isMarkType = 4;
            }
          }
        } else {
          //未标记
          if (item.tqType == 6) {
            var has = false;
            item.children.map(function (itemChildren) {
              for (var _i6 = 0; _i6 < itemChildren.userAnswer.length; _i6++) {
                //主要用于填空题处理 ['111','2222']
                if (itemChildren.userAnswer[_i6] != '') {
                  has = true;
                }
              }
              return itemChildren;
            });
            if (has) {
              //已答题
              item.isMarkType = 1;
              num += 1;
            } else {
              //未答题
              item.isMarkType = 2;
            }
          } else {
            if (item.userAnswer) {
              var _has2 = false;
              for (var _i7 = 0; _i7 < item.userAnswer.length; _i7++) {
                //主要用于填空题处理 ['111','2222']
                if (item.userAnswer[_i7] != '') {
                  _has2 = true;
                }
              }
              if (item.userAnswer.length > 0 && _has2) {
                //已答题
                item.isMarkType = 1;
                num += 1;
              } else {
                //未答题
                item.isMarkType = 2;
              }
            }
          }
        }
        return item;
      });
      //更新已答未答数量
      this.answerNum = num;
      this.unAnswerNum = this.allList.length - num;
    },
    // 展示问题
    showQuestion: function showQuestion() {
      this.problemItem = this.allList[this.problemItemIndex];
    },
    //提示信息
    showMsg: function showMsg(type, msg) {
      if (type == 1) {
        this.$notify({
          title: '提示',
          message: msg,
          type: 'success'
        });
      } else {
        this.$notify({
          title: '提示',
          message: msg,
          type: 'error'
        });
      }
    },
    showDialog: function showDialog(type, msg) {
      this.tips = msg;
      if (type == 1) {
        //请求考试列表时  特殊处理
        this.errorDialogVisible = true;
        this.correctBtnText = '';
        this.errorBtnText = '返回';
      } else if (type == 2) {
        //人脸验证时  特殊处理
        this.errorDialogVisible = true;
        this.errorBtnText = '重新认证';
      } else if (type == 3) {
        this.errorDialogVisible = true;
        this.errorBtnText = '';
      } else if (type == 4) {
        this.errorDialogVisible = true;
        this.errorBtnText = '继续答题';
        this.correctBtnText = '确认提交';
      } else if (type == 5) {
        this.errorDialogVisible = true;
        this.correctBtnText = '';
        this.errorBtnText = '返回列表';
      }
    },
    closeCorrectDialog: function closeCorrectDialog() {
      this.errorDialogVisible = false;
      this.HandPapers();
    },
    closeErrorDialog: function closeErrorDialog() {
      if (this.errorBtnText == '返回') {
        this.errorDialogVisible = false;
        this.backHtml();
      } else if (this.errorBtnText == '重新认证') {
        this.invigilatorTakePhoto();
        this.showDialog(3, '正在重新识别中，请稍后');
      } else if (this.errorBtnText == '继续答题') {
        this.errorDialogVisible = false;
      } else if (this.errorBtnText == '返回列表') {
        this.$router.push('/allExamination/1');
        // this.$router.push('/examinationSystem/examList/1')
      }
    },
    //获取用户信息
    getUserDetails: function getUserDetails() {
      var that = this;
      (0, _examination.getUserInfo)({}).then(function (response) {
        if (response.code == 200) {
          //数据获取成功
          //回显留底图
          // that.userFace = response.data.user.faceImg
          that.userFace = response.data.user.bottomPicture;
          //回显姓名
          that.userName = response.data.user.username;
        } else {
          that.showMsg(2, response.message);
        }
      });
    },
    //提交答案
    HandInPapers: function HandInPapers(val) {
      var _this5 = this;
      console.log(val, 'val');
      var list = [];
      var that = this;
      for (var i = 0; i < this.allList.length; i++) {
        // this.allList[i].userAnswer = []
        if (this.allList[i].tqType == 1 || this.allList[i].tqType == 2 || this.allList[i].tqType == 3 || this.allList[i].tqType == 5) {
          if (this.allList[i].userAnswer.length > 0) {
            list.push({
              id: this.allList[i].id,
              isMark: this.allList[i].isMark ? this.allList[i].isMark : 0,
              userAnswer: this.allList[i].userAnswer
            });
          } else {
            list.push({
              id: this.allList[i].id,
              isMark: this.allList[i].isMark ? this.allList[i].isMark : 0,
              userAnswer: []
            });
          }
        } else if (this.allList[i].tqType == 4) {
          if (this.allList[i].userAnswer.length > 0) {
            //填空题用户已填写  但不确定具体填写了几项
            list.push({
              id: this.allList[i].id,
              isMark: this.allList[i].isMark ? this.allList[i].isMark : 0,
              userAnswer: this.allList[i].userAnswer
            });
          } else {
            //填空题 用户未填写
            var q1 = [];
            for (var j = 0; j < this.allList[i].tkAnswerNum; j++) {
              q1.push('');
            }
            list.push({
              id: this.allList[i].id,
              isMark: this.allList[i].isMark ? this.allList[i].isMark : 0,
              userAnswer: q1
            });
          }
        } else if (this.allList[i].tqType == 6) {
          for (var _j = 0; _j < this.allList[i].children.length; _j++) {
            if (this.allList[i].children[_j].tqType == 4) {
              if (this.allList[i].children[_j].userAnswer.length != 0) {
                list.push({
                  id: this.allList[i].children[_j].id,
                  isMark: this.allList[i].isMark ? this.allList[i].isMark : 0,
                  userAnswer: this.allList[i].children[_j].userAnswer
                });
              } else {
                var _q = [];
                for (var s = 0; s < this.allList[i].children[_j].tkAnswerNum; s++) {
                  _q.push('');
                }
                list.push({
                  id: this.allList[i].children[_j].id,
                  isMark: this.allList[i].isMark ? this.allList[i].isMark : 0,
                  userAnswer: _q
                });
              }
            } else {
              if (this.allList[i].children[_j].userAnswer.length > 0) {
                list.push({
                  id: this.allList[i].children[_j].id,
                  isMark: this.allList[i].isMark ? this.allList[i].isMark : 0,
                  userAnswer: this.allList[i].children[_j].userAnswer
                });
              } else {
                list.push({
                  id: this.allList[i].children[_j].id,
                  isMark: this.allList[i].isMark ? this.allList[i].isMark : 0,
                  userAnswer: []
                });
              }
            }
          }
        }
      }
      var dataBody = {
        examInfo: list,
        isNormal: 0,
        userTestRecordId: this.utrId
      };
      (0, _examination.examUserAnswer)(dataBody).then(function (response) {
        if (response.code == 200) {
          if (val == 2) {
            //强制交卷
            that.showMsg(2, '考试时间已结束，系统已自动提交试卷');
            that.HandPapers(true);
          } else {
            // let time = new Date().getTime() //当前时间
            // if (this.examHandTime && this.examHandTime != 0) {
            //   if (time - (this.startTime + this.examHandTime * 60 * 1000) < 0) {
            //     this.showMsg(2, `考试开始${this.examHandTime}分钟后才能交卷`)
            //     return false
            //   }
            // }
            var msg = _this5.unAnswerNum === 0 ? '是否要提交试卷' : "\u60A8\u8FD8\u6709".concat(_this5.unAnswerNum, "\u9898\u672A\u7B54\uFF0C\u662F\u5426\u8981\u63D0\u4EA4\u8BD5\u5377");
            //弹出提示框 交卷
            that.showDialog(4, msg);
          }
        } else {
          that.showMsg(2, response.message);
        }
      });
    },
    //交卷
    HandPapers: function HandPapers(flag) {
      var _this6 = this;
      var that = this;
      var dataBody = {
        utrId: this.utrId
      };
      if (flag) {
        this.page_loading = true;
        this.page_loading_text = '试卷提交中，请耐心等待';
        (0, _examination.examEnd)(dataBody).then(function (response) {
          console.log(response);
          if (response.code == 200) {
            if (that.from == 1) {
              that.showDialog(5, '试卷提交成功，请在我参与的考试列表中查看考试状态');
              // that.$router.push("/showAnswers/" + that.utrId + ",课程详情");
              _this6.$router.push('/allExamination/1');
              // this.$router.push('/examinationSystem/examList/1')
              // that.errorDialogVisible = true;
            } else {
              // that.$router.push('/JudgingPapers/' + that.utrId)
              _this6.$router.push({
                path: '/JudgingPapers',
                query: {
                  id: that.utrId,
                  type: 2,
                  mockId: _this6.id
                }
              });
              // that.$router.push("/showAnswers/" + that.utrId + ",课程详情");
            }
          } else {
            that.showMsg(2, response.message);
          }
        });
        return false;
      }
      this.$confirm('确定要提交试卷？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: 'warning'
      }).then(function () {
        _this6.page_loading = true;
        _this6.page_loading_text = '试卷提交中，请耐心等待';
        (0, _examination.examEnd)(dataBody).then(function (response) {
          console.log(response, 'liu');
          if (response.code == 200) {
            if (that.from == 1) {
              that.showDialog(5, '试卷提交成功，请在我参与的考试列表中查看考试状态');
              // this.$router.push('/examinationSystem/examList/1')
              _this6.$router.push('/allExamination/1');
              // that.errorDialogVisible = true;
            } else {
              // that.$router.push('/JudgingPapers/' + that.utrId)
              _this6.$router.push({
                path: '/JudgingPapers',
                query: {
                  id: that.utrId,
                  type: 2,
                  mockId: _this6.id
                }
              });
              // that.$router.push("/showAnswers/" + that.utrId + ",课程详情");
            }
          } else {
            that.showMsg(2, response.message);
          }
        }).finally(function () {
          _this6.page_loading = false;
        });
      });
    },
    // 监学拍照计时器
    supervisioninterval: function supervisioninterval() {
      console.log('supervisioninterval');
      if (this.IsItVerified) {
        console.log('人脸核验');
        console.log(this.faceTime);
        //在验证人脸中   直接显示拍照
        this.invigilatorTakePhoto();
      } else {
        if (this.faceTime != 0) {
          if (this.takePhotoNum == 0) {
            this.takePhotoNum = parseInt(this.useTime / (this.faceTime * 60 * 1000));
            if (this.takePhotoNum != 0) {
              this.invigilatorTakePhoto();
            }
          } else {
            this.takePhotoNum = parseInt(this.useTime / (this.faceTime * 60 * 1000));
            if (this.useTime - this.takePhotoNum * (this.faceTime * 60 * 1000) < 1000) {
              this.invigilatorTakePhoto();
            }
          }
        }
      }
    },
    //考试时间计时器
    testTiminginterval: function testTiminginterval() {
      console.log('testTiminginterval');
      this.useTime -= 1000;
      // longTime:考试时长
      // useTime :考试时长
      if (this.useTime < 0) {
        // console.log("强制交卷");
        //强制交卷
        this.HandInPapers(2);
        this.showMsg(2, '考试已结束，系统已强制交卷');
        this.timeText = '考试已结束';
        clearInterval(this.interval); //停止计时器
      } else {
        this.timeText = this.formatDuring(parseInt(this.useTime));
      }
    },
    formatDuring: function formatDuring(mss) {
      var day = parseInt(mss / (1000 * 60 * 60 * 24));
      var hours = parseInt(mss % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
      var minutes = parseInt(mss % (1000 * 60 * 60) / (1000 * 60));
      var seconds = parseInt(mss % (1000 * 60) / 1000);
      if (this.PatchPosition(day.toString()) != '00') {
        return this.PatchPosition(day.toString()) + '天' + this.PatchPosition(hours.toString()) + ':' + this.PatchPosition(minutes.toString()) + ':' + this.PatchPosition(seconds.toString());
      } else if (this.PatchPosition(hours.toString()) != '00') {
        return this.PatchPosition(hours.toString()) + ':' + this.PatchPosition(minutes.toString()) + ':' + this.PatchPosition(seconds.toString());
      } else {
        return this.PatchPosition(minutes.toString()) + ':' + this.PatchPosition(seconds.toString());
      }
    },
    PatchPosition: function PatchPosition(val) {
      if (val.length == 2) {
        return val.toString();
      } else if (val.length == 1) {
        return '0' + val.toString();
      } else {
        return '';
      }
    },
    //拍照
    invigilatorTakePhoto: function invigilatorTakePhoto() {
      console.log('re-verifing invigilatorTakePhoto');
      var _this = this;
      if (_this.thisContext) {
        // canvas画图
        _this.thisContext.drawImage(_this.thisVideo, 0, 0, _this.videoWidth, _this.videoHeight);
        // 获取图片base64链接
        var image = this.thisCancas.toDataURL('image/png');
        _this.imgSrc = image; //赋值并预览图片
      }
    },
    //base64 转文件
    dataURLtoFile: function dataURLtoFile(base64Str, fileName) {
      var arr = base64Str.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        //base64解析出来的图片类型
        bstr = atob(arr[1]),
        //对base64串进行操作，去掉url头，并转换为byte atob为window内置方法
        len = bstr.length,
        ab = new ArrayBuffer(len),
        //将ASCII码小于0的转换为大于0
        u8arr = new Uint8Array(ab); //
      while (len--) {
        u8arr[len] = bstr.charCodeAt(len);
      }
      // 创建新的 File 对象实例[utf-8内容，文件名称或者路径，[可选参数，type：文件中的内容mime类型]]
      return new File([u8arr], fileName, {
        type: mime
      });
    },
    //上传图片
    uploadPic: function uploadPic(formFile) {
      var _this7 = this;
      this.aliUploadBase64(formFile, 'trainExam').then(function (res) {
        if (res.res.status === 200) {
          _this7.comparisonBtn = false;
          _this7.openInvigilator("".concat(process.env.VUE_APP_UPLOAD_ALI_HOST, "/").concat(res.name));
        } else {
          _this7.showMsg(2, response.message);
        }
      });
    },
    //监考照片比对
    openInvigilator: function openInvigilator(imgUrl) {
      console.log('wo zhixing le ');
      var that = this;
      var dataBody = {
        bussinessId: this.utrId,
        //考试或学习的id
        chapterId: this.utrId,
        //章id
        headFace: imgUrl,
        sectionId: this.utrId,
        //节id
        trainId: this.utrId,
        //培训id
        type: 2
      };
      (0, _examination.course)(dataBody).then(function (response) {
        console.log(response);
        if (response.code == 200) {
          //数据获取成功
          var abc = response.data.abc; //比对结果
          if (!abc) {
            that.IsInTakePic = true;
            localStorage.setItem('IsItVerified', true);
            that.showDialog(2, '考生人脸不符，请重新核验');
          } else {
            if (parseInt(abc) >= 70) {
              //成功
              that.IsInTakePic = false;
              that.errorDialogVisible = false;
              localStorage.setItem('IsItVerified', false);
            } else {
              localStorage.setItem('IsItVerified', true);
              that.showDialog(2, '考生人脸不符，请重新核验');
              that.IsInTakePic = true;
            }
          }
        } else {
          that.IsInTakePic = true;
          localStorage.setItem('IsItVerified', true);
          that.showDialog(2, response.message);
        }
      });
    }
  }
};