"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _mloading = require("@/utils/mloading");
var _maiskaos = require("@/api/maiskaos");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      cur: 0,
      liebiaostop: [],
      liebiaosbtm: []
    };
  },
  created: function created() {
    // console.log(getAssocToken())
    this.kaoshijuebiaosfun();
  },
  methods: {
    kaoshikaosonclic: function kaoshikaosonclic(i) {
      var _this = this;
      (0, _maiskaos.kaoshikais)(i.type, i.dbId).then(function (res) {
        if (res.code == 200) {
          window.open(res.data);
        } else {
          _this.$message.error(res.message);
        }
      });
    },
    kaoshijuebiaosfun: function kaoshijuebiaosfun() {
      var _this2 = this;
      (0, _mloading.showLoading)();
      (0, _maiskaos.kaoshijuebiaos)().then(function (res) {
        if (res.code == 200) {
          for (var key in res.data.appraisal) {
            res.data.appraisal[key].forEach(function (item) {
              var a = {};
              var wwwwww = key.match(/name=(\S*)/)[1];
              wwwwww = wwwwww.match(/(\S*),/)[1];
              a.kemuname = wwwwww;
              a.mingcname = item.name;
              a.dbId = item.dbId;
              a.dengji = item.level;
              a.type = 'appraisal';
              _this2.liebiaostop.push(a);
            });
          }
          for (var key in res.data.technician) {
            res.data.technician[key].forEach(function (item) {
              var a = {};
              var wwwwww = key.match(/name=(\S*)/)[1];
              wwwwww = wwwwww.match(/(\S*),/)[1];
              a.kemuname = wwwwww;
              a.mingcname = item.name;
              a.dbId = item.dbId;
              a.dengji = item.level;
              a.type = 'technician';
              _this2.liebiaosbtm.push(a);
            });
          }
          (0, _mloading.hideLoading)();
        } else {
          (0, _mloading.hideLoading)();
        }
      });
    }
  }
};