"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _mStore = _interopRequireDefault(require("@/store/modules/mStore.js"));
var _request = _interopRequireDefault(require("@/utils/request.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      // value: null
      mUrl: _mStore.default.mUrl,
      pllist: "",
      oid: "",
      pllistNum: ""
    };
  },
  created: function created() {
    this.oid = this.$route.params && this.$route.params.id;
    this.plList();
    this.plnumlist();
  },
  methods: {
    plList: function plList() {
      var _this = this;
      (0, _request.default)({
        url: "/Info/countComment",
        method: "get",
        params: {
          infoId: this.oid,
          type: 5
        }
      }).then(function (response) {
        var data = response.data;
        _this.pllist = data;
      });
    },
    plnumlist: function plnumlist() {
      var _this2 = this;
      (0, _request.default)({
        url: "/Info/pagecomment",
        method: "post",
        params: {
          infoId: this.oid,
          page: 1,
          type: 5,
          size: 100
        }
      }).then(function (response) {
        var data = response.data;
        data.content.forEach(function (element) {
          if (element.score == "0") {
            element.score = 5;
          } else if (element.score == "1") {
            element.score = 1;
          } else if (element.score == "2") {
            element.score = 2;
          } else if (element.score == "3") {
            element.score = 3;
          } else if (element.score == "4") {
            element.score = 4;
          } else if (element.score == "5") {
            element.score = 5;
          }
        });
        _this2.pllistNum = data.content;
      });
    }
  }
};