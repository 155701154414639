var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isRepeat
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "paymentbox",
            attrs: { data: _vm.orderInfo },
          },
          [
            _vm._m(0),
            _c("div", { staticClass: "commoditybox" }, [
              _c("p", { staticClass: "commodity" }, [_vm._v("商品介绍")]),
              _c("div", { staticClass: "commodityone" }, [
                _c("img", {
                  staticClass: "commodityimg",
                  attrs: { src: _vm.orderInfo.goodsImg, alt: "" },
                }),
                _c("div", [
                  _c("p", [
                    _vm._v(" 商品名称："),
                    _c("span", [_vm._v(_vm._s(_vm.orderInfo.goodsTitle))]),
                  ]),
                  _c("p", [
                    _vm._v(" 商品编号："),
                    _c("span", [_vm._v(_vm._s(_vm.orderInfo.goodsId))]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "amountofmoneybox" }, [
              _c("div", { staticClass: "amountofmoney" }, [
                _c("p", [_vm._v("支付金额")]),
                _vm.orderInfo.freeBuy
                  ? _c("p", [_vm._v("免费")])
                  : _c("p", [
                      _vm._v("￥" + _vm._s(_vm.orderInfo.realTotalMoney)),
                    ]),
                _c("p", [
                  _vm._v(" 订单编号："),
                  _c("span", [_vm._v(_vm._s(_vm.orderInfo.orderNum))]),
                ]),
                _c("p", [
                  _vm._v(" 购买时间："),
                  _c("span", [_vm._v(_vm._s(_vm.orderInfo.createTime))]),
                ]),
              ]),
            ]),
            !_vm.orderInfo.freeBuy
              ? _c(
                  "div",
                  { staticClass: "paymentmethod" },
                  [
                    _vm.sellOnLine
                      ? [
                          _c("p", { staticClass: "paymentmethodone" }, [
                            _vm._v("支付方式"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "btnList" },
                            _vm._l(_vm.btmList, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.type,
                                  ref: "payMethod",
                                  refInFor: true,
                                  staticClass: "btnItem",
                                  style: {
                                    border:
                                      _vm.payClickType === item.type
                                        ? "1px solid #0060ef"
                                        : "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.btnItemClick(item.type)
                                    },
                                  },
                                },
                                [
                                  _c("div", [
                                    _c("i", {
                                      staticClass: "iconfont",
                                      class: item.icon,
                                    }),
                                    _c("span", [_vm._v(_vm._s(item.value))]),
                                  ]),
                                  _c("i", {
                                    staticClass: "iconfont icondui",
                                    style: {
                                      color:
                                        _vm.payClickType === item.type
                                          ? "#0060ef"
                                          : "#E4E4E4",
                                    },
                                  }),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      : [_c("div", [_vm._v("暂无")])],
                  ],
                  2
                )
              : _vm._e(),
            _vm.sellOnLine
              ? [
                  _vm.payClickType === 3 && !_vm.orderInfo.freeBuy
                    ? _c("div", { staticClass: "couponRedeem" }, [
                        _c("div", { staticClass: "couponRedeem_title" }, [
                          _vm._v("输入抵用券兑换码"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "couponRedeem_code" },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: _vm.couponRedeemCodeDisable,
                                placeholder: "请输入兑换码",
                              },
                              model: {
                                value: _vm.couponRedeemCode,
                                callback: function ($$v) {
                                  _vm.couponRedeemCode = $$v
                                },
                                expression: "couponRedeemCode",
                              },
                            }),
                            _vm.couponRedeemCodeDisable
                              ? _c(
                                  "el-link",
                                  {
                                    attrs: { underline: false },
                                    on: { click: _vm.replaceClick },
                                  },
                                  [_vm._v("更换 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._m(1),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.sellOnLine
              ? _c("div", { staticClass: "payClickDiv" }, [
                  _c("div", [
                    _vm._v(" 实付金额："),
                    !_vm.orderInfo.freeBuy
                      ? _c("span", [_vm._v("¥")])
                      : _vm._e(),
                    _vm.orderInfo.freeBuy
                      ? _c(
                          "span",
                          { staticStyle: { "margin-left": "0.2rem" } },
                          [_vm._v("免费")]
                        )
                      : _c(
                          "span",
                          { staticStyle: { "margin-left": "0.2rem" } },
                          [_vm._v(_vm._s(_vm.payMoney))]
                        ),
                  ]),
                  _vm.orderInfo.freeBuy
                    ? _c(
                        "div",
                        [
                          _c("el-button", { on: { click: _vm.payFreeBuy } }, [
                            _vm._v("立即付款"),
                          ]),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("el-button", { on: { click: _vm.payClickNow } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.payClickType === 3 && _vm.payMoney !== 0
                                    ? "校验"
                                    : "立即付款"
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                ])
              : _vm._e(),
            _c(
              "el-dialog",
              {
                attrs: {
                  visible: _vm.checkErr,
                  "close-on-click-modal": false,
                  "close-on-press-escape": false,
                  "show-close": false,
                  width: "600px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.checkErr = $event
                  },
                },
              },
              [
                _c("div", { staticClass: "checkErr" }, [
                  _c("span", { staticClass: "checkErrTitle" }, [
                    _c("i", { staticClass: "el-icon-error" }),
                    _vm._v(_vm._s(_vm.messageData.title)),
                  ]),
                  _c("span", { staticClass: "checkErrMessage" }, [
                    _vm._v(_vm._s(_vm.messageData.message)),
                  ]),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.checkErr = false
                          },
                        },
                      },
                      [_vm._v("确 定")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  visible: _vm.showYsPay,
                  "close-on-click-modal": false,
                  "close-on-press-escape": false,
                  width: "520px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.showYsPay = $event
                  },
                  closed: function ($event) {
                    _vm.showYsPay = false
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "ys-box",
                  },
                  [
                    _c("div", { staticClass: "ys-title" }, [
                      _vm._v("支付宝扫码支付"),
                    ]),
                    _vm.ysPayInfo.qr_code_url
                      ? _c("img", {
                          staticClass: "ys-img",
                          attrs: { src: _vm.ysPayInfo.qr_code_url, alt: "" },
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "des" }, [
                      _c("span", { staticClass: "iconfont icon" }, [
                        _vm._v(""),
                      ]),
                      _c("div", [
                        _c("div", [_vm._v("打开手机支付宝")]),
                        _c("div", [_vm._v("扫一扫继续支付")]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "des" },
                      [
                        _vm._v(" 若支付成功页面未进行跳转，请手动 "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: { click: _vm.getOrderInfoFun },
                          },
                          [_vm._v("刷新")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _vm.showPayDiv
              ? _c("pay-code", {
                  attrs: {
                    "pay-type": _vm.payType,
                    "pay-money": _vm.orderInfo.realTotalMoney,
                    codeUrl: _vm.payCode,
                  },
                  on: { payFinish: _vm.payFinish },
                })
              : _vm._e(),
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "paymentbox repeatBox" },
          [
            _c("h1", [_vm._v("存在相同学习内容，请勿重复购买")]),
            _c(
              "el-button",
              {
                staticStyle: { padding: "10px 40px" },
                attrs: { type: "primary" },
                on: { click: _vm.backTo },
              },
              [_vm._v(" 确定 ")]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "paymentone" }, [
      _c("div", [_c("p", [_vm._v("确认订单信息")])]),
      _c("div"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "couponRedeem_tip" }, [
      _c("i", {
        staticClass: "el-icon-warning",
        staticStyle: { color: "#fcbe48", "margin-right": "0.5rem" },
      }),
      _vm._v(" 请输入12位抵用劵兑换码，输入后校验，校验通过后可用来兑换课程 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }