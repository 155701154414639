"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.number.constructor.js");
var _examListItem = _interopRequireDefault(require("./examListItem"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { get } from 'core-js/fn/dict'
// import { get, set } from 'js-cookie'
var _default2 = exports.default = {
  name: 'ExamList',
  components: {
    ExamListItem: _examListItem.default
  },
  props: {
    total: {
      type: Number,
      default: function _default() {
        return 0;
      }
    },
    contentcontent: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    currentp: {
      type: Number,
      default: function _default() {
        return 1;
      }
    },
    size: {
      type: Number,
      default: function _default() {
        return 5;
      }
    }
  },
  // props: ['contentcontent', 'total', 'currentp', 'size'],
  computed: {
    currentpage: {
      get: function get() {
        return this.currentp;
      },
      set: function set(val) {}
    },
    showpage: function showpage() {
      return this.size <= this.total;
    }
  },
  methods: {
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // console.log(`当前页: ${val}`,val);
      this.$emit('changeSize', val);
    }
  }
};