"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/core-js/modules/es.array.iterator.js");
require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/core-js/modules/es.promise.js");
require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/core-js/modules/es.object.assign.js");
require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/core-js/modules/es.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("@/permission");
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("./iconfont/iconfont.css");
require("@/icons");
var _vueCropper = _interopRequireDefault(require("vue-cropper"));
require("bootstrap/dist/css/bootstrap.min.css");
require("bootstrap/dist/js/bootstrap.min.js");
var _aliUpload = require("@/utils/aliUpload");
var _index3 = require("@/utils/index");
var _guideFrame = _interopRequireDefault(require("@/components/guideFrame"));
// permission control
// A modern alternative to CSS resets

// lang i18n

// global css

console.log("router", _router.default);
// 导入字体图标文件

// icon

// 清除掉local存储数据
localStorage.clear();
// 全局引入裁剪工具

_vue.default.use(_vueCropper.default);

// 引入ali上传文件接口

_vue.default.prototype.aliUpload = _aliUpload.aliUpload;
_vue.default.prototype.aliUploadBase64 = _aliUpload.aliUploadBase64;
_vue.default.prototype.parseTime = _index3.parseTime;
_vue.default.prototype.aliUploadFile = _aliUpload.aliUploadFile;
_vue.default.use(_guideFrame.default);

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === 'production') {
  if (location.protocol === 'http:') {
    var hrelCopy = location.href.replace('http', 'https');
    location.href = hrelCopy;
  }
  // const {
  //   mockXHR
  // } = require('../mock')
  // mockXHR()
}
// set ElementUI lang to EN
_vue.default.use(_elementUi.default, {
  locale: _zhCN.default
});
window.eventBus = new _vue.default();

// 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)

_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});