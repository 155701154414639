"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _homeinterface = require("@/api/homeinterface");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'announcementpage',
  data: function data() {
    return {
      loading: false,
      noticenotice: [],
      listQuery: {
        page: 1,
        size: 10,
        xid: (0, _auth.getAssocId)(),
        keyword: '',
        sta: '',
        endL: ''
      },
      // 总数据条数
      total: 0
    };
  },
  mounted: function mounted() {
    if (this.$route.query.keyword) {
      this.listQuery.keyword = this.$route.query.keyword;
    }
    this.announcementlistfun();
  },
  methods: {
    details: function details(id) {
      this.$router.push({
        path: '/announcementdetails/' + id
      });
    },
    announcementlistfun: function announcementlistfun() {
      var _this = this;
      this.loading = true;
      (0, _homeinterface.announcement)(this.listQuery).then(function (response) {
        _this.loading = false;
        _this.noticenotice = response.data.content;
        _this.total = response.data.totalElements;
      });
    },
    changePage: function changePage(page) {
      this.listQuery.page = page;
      this.announcementlistfun();
    }
  }
};