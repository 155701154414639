"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.dot-all.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.sticky.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _auth = require("@/utils/auth");
var _elementUi = require("element-ui");
var _examination = require("../../api/examination.js");
var _icon_cuo = _interopRequireDefault(require("@/assets/img/icon_cuo.png"));
var _icon_dui = _interopRequireDefault(require("@/assets/img/icon_dui.png"));
var _icon_wxz = _interopRequireDefault(require("@/assets/img/icon_wxz.png"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      answerList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'],
      id: '',
      logo: '',
      loading: true,
      dialogStartExamination: true,
      errorDialogVisible: false,
      type: 1,
      allList: [],
      assocLogo: '',
      problemItem: '',
      //当前展示题的所有信息
      problemItemIndex: 0,
      //当前第几题  下标
      icon_cuo: _icon_cuo.default,
      icon_wxz: _icon_wxz.default,
      icon_dui: _icon_dui.default,
      errorBtnText: '',
      tips: '',
      ABC: ['', 'A', 'B', 'C', 'D', 'E', 'F', 'G'],
      from: '',
      itemId: ''
    };
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      // 禁用右键
      document.oncontextmenu = new Function('event.returnValue=false');
      // 禁用选择
      document.onselectstart = new Function('event.returnValue=false');
    });
  },
  beforeDestroy: function beforeDestroy() {
    // 开启选择
    document.onselectstart = new Function('event.returnValue=true');
  },
  methods: {
    closeErrorDialog: function closeErrorDialog() {
      if (this.errorBtnText == '返回') {
        this.errorDialogVisible = false;
        this.backHtml();
      }
    },
    backHtml: function backHtml() {
      // this.$router.push('/JudgingPapers/' + this.$route.params.id)
      this.$router.push({
        path: '/JudgingPapers',
        query: {
          id: this.$route.query.id,
          type: this.$route.query.type,
          mockId: this.$route.query.mockId
        }
      });
      // if (this.from == '考试结果') {
      //   window.history.back(-1)
      // } else {
      //   window.history.back(-1)
      // }
    },
    //底部功能按钮点击事件
    buttomBntClick: function buttomBntClick(val) {
      if (val == 2) {
        //上一题
        if (this.problemItemIndex == 0) {
          this.showMsg(2, '已经是第一题');
        } else {
          this.problemItemIndex -= 1;
          this.showQuestion();
        }
      } else if (val == 3) {
        //下一题
        if (this.problemItemIndex == this.allList.length - 1) {
          this.showMsg(2, '已经是最后一题');
        } else {
          this.problemItemIndex += 1;
          this.showQuestion();
        }
      }
    },
    //答题卡上题数点击事件
    cardItemClick: function cardItemClick(val) {
      this.problemItemIndex = val;
      this.showQuestion();
    },
    //获取考题列表
    initQuestionList: function initQuestionList() {
      var that = this;
      var dataBody = {
        recordId: this.id,
        isPJ: 2
      };
      (0, _examination.examCardInfoToShow)(dataBody).then(function (response) {
        console.log(dataBody);
        that.loading = false;
        if (response.code == 200) {
          //数据获取成功
          that.allList = response.data.cards;
          that.assocLogo = response.data.assoc.assocLogo;
          that.allList.map(function (item, index) {
            var reg = new RegExp('/br', 'g'); //g代表全部
            item.tqContentOb.title = (index + 1 + '、' + item.tqContentOb.title).replace(reg, '');
            if (item.tqType == 1 || item.tqType == 3 || item.tqType == 2) {
              if (Array.isArray(item.userAnswer) && item.userAnswer.length != 0) {
                //用户提交答案有值
                // let userAnswer = item.userAnswer[0]
                item.answerList.map(function (answerListItem) {
                  item.tqContentOb.options.map(function (options) {
                    if (answerListItem == options.tags) {
                      options.sel = 1;
                    } else {
                      item.userAnswer.forEach(function (x) {
                        if (!options.sel) {
                          if (x == options.tags) {
                            options.sel = 2;
                          }
                        }
                      });
                    }
                    return options;
                  });
                  return answerListItem;
                });
              } else {
                //用户提交答案有无值
                item.answerList.map(function (answerListItem) {
                  item.tqContentOb.options.map(function (options) {
                    if (answerListItem == options.tags) {
                      options.sel = 1; //正确
                    }
                    return options;
                  });
                  return answerListItem;
                });
              }
            } else if (item.tqType == 4) {
              if (Array.isArray(item.userAnswer) && item.userAnswer.length != 0) {
                item.showAnswer = [];
                item.answerList.map(function (answerListItem, s) {
                  item.showAnswer.push({
                    correct: answerListItem,
                    user: item.userAnswer[s]
                  });
                  return answerListItem;
                });
              } else {
                item.showAnswer = [];
                item.answerList.map(function (answerListItem) {
                  showAnswer.push({
                    correct: answerListItem,
                    user: ''
                  });
                  return answerListItem;
                });
              }
            } else if (item.tqType == 5) {
              if (Array.isArray(item.userAnswer) && item.userAnswer.length != 0) {
                item.value = item.userAnswer[0];
              } else {
                item.value = '';
              }
            } else if (item.tqType == 6) {
              item.children.map(function (childrenItem) {
                childrenItem.tqContentOb.title = childrenItem.tqContentOb.title.replace(reg, '');
                if (childrenItem.tqType == 1 || childrenItem.tqType == 3 || childrenItem.tqType == 2) {
                  if (Array.isArray(childrenItem.userAnswer) && childrenItem.userAnswer.length != 0) {
                    //用户提交答案有值
                    var userAnswer = childrenItem.userAnswer[0];
                    childrenItem.answerList.map(function (answerListItem) {
                      childrenItem.tqContentOb.options.map(function (options) {
                        if (answerListItem == options.tags) {
                          options.sel = 1;
                        } else {
                          if (!options.sel) {
                            if (userAnswer == options.tags) {
                              options.sel = 2;
                            } else {
                              options.sel = 3;
                            }
                          }
                        }
                        return options;
                      });
                      return answerListItem;
                    });
                  } else {
                    childrenItem.answerList.map(function (answerListItem) {
                      childrenItem.tqContentOb.options.map(function (options) {
                        if (answerListItem == options.tags) {
                          options.sel = 1; //正确
                        }
                        return options;
                      });
                      return answerListItem;
                    });
                  }
                } else if (childrenItem.tqType == 4) {
                  if (Array.isArray(childrenItem.userAnswer) && childrenItem.userAnswer.length != 0) {
                    childrenItem.showAnswer = [];
                    childrenItem.answerList.map(function (answerListItem, s) {
                      childrenItem.showAnswer.push({
                        correct: answerListItem,
                        user: childrenItem.userAnswer[s]
                      });
                      return answerListItem;
                    });
                  } else {
                    childrenItem.showAnswer = [];
                    childrenItem.answerList.map(function (answerListItem) {
                      childrenItem.showAnswer.push({
                        correct: answerListItem,
                        user: ''
                      });
                      return answerListItem;
                    });
                  }
                } else if (childrenItem.tqType == 5) {
                  if (Array.isArray(childrenItem.userAnswer) && childrenItem.userAnswer.length != 0) {
                    childrenItem.value = childrenItem.userAnswer[0];
                  } else {
                    childrenItem.value = '';
                  }
                }
                return childrenItem;
              });
            }
            return item;
          });
          that.itemId = response.data.planServiceOne.itemId;
          that.showQuestion();
          that.refreshata();
          console.log(that.allList, 'that.allList');
        } else {
          that.showDialog(1, response.message);
        }
      });
    },
    // 展示问题
    showQuestion: function showQuestion() {
      this.problemItem = this.allList[this.problemItemIndex];
    },
    //提示信息
    showMsg: function showMsg(type, msg) {
      if (type == 1) {
        this.$notify({
          title: '提示',
          message: msg,
          type: 'success'
        });
      } else {
        this.$notify({
          title: '提示',
          message: msg,
          type: 'error'
        });
      }
    },
    showDialog: function showDialog(type, msg) {
      this.tips = msg;
      if (type == 1) {
        //请求考试列表时  特殊处理
        this.errorDialogVisible = true;
        this.errorBtnText = '返回';
      }
    }
  },
  watch: {},
  created: function created() {
    this.assocLogo = (0, _auth.getAssocLogo)();
    var from = this.$route.query.id;
    var split = from.split(',');
    this.id = split[0];
    this.from = split[1];
    this.initQuestionList();
  }
};