"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.trim.js");
var _user = require("@/api/user");
var _eemc = require("@/api/eemc");
var _auth = require("@/utils/auth");
var _router = require("@/router");
var getDefaultState = function getDefaultState() {
  return {
    token: '',
    name: '',
    avatar: '',
    isRenzheng: 0,
    identity: "",
    eemcUserInfo: {
      userCode: null,
      coursesType: null
    } // eemc账号 userCode   coursesType
  };
};
var state = getDefaultState();
var mutations = {
  SET_IDENTITY: function SET_IDENTITY(state, payload) {
    state.identity = payload;
  },
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_RENZHENG: function SET_RENZHENG(state, isRenzheng) {
    state.isRenzheng = isRenzheng;
  },
  SET_EEMCUSERINFO: function SET_EEMCUSERINFO(state, eemcUserInfo) {
    state.eemcUserInfo = eemcUserInfo;
  }
};
var actions = {
  /**
   * 能效中心用户CODE登录
   * @param {*} param0
   * @param {*} userInfo
   * @returns
   */
  // eemc user login
  eemcLogin: function eemcLogin(_ref, _ref2) {
    var commit = _ref.commit;
    var usercode = _ref2.usercode;
    // console.log('................eemclogin',coursesType)
    return new Promise(function (resolve, reject) {
      (0, _eemc.getEmmcToken)(usercode).then(function (response) {
        if (response.code === 200) {
          var data = response.data;
          commit('SET_TOKEN', data.localToken);
          commit('SET_EEMCUSERINFO', {
            userCode: usercode // eemc账号
          });
          (0, _auth.setToken)(data.localToken);
          resolve(response);
        } else {
          reject(response);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user login
  login: function login(_ref3, userInfo) {
    var commit = _ref3.commit;
    var mobile = userInfo.mobile,
      passwd = userInfo.passwd;
    return new Promise(function (resolve, reject) {
      (0, _user.login)({
        mobile: mobile.trim(),
        passwd: passwd
      }).then(function (response) {
        if (response.code === 200) {
          var data = response.data;
          commit('SET_TOKEN', data.token);
          (0, _auth.setToken)(data.token);
        }
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  loginByIdentity: function loginByIdentity(_ref4, identity) {
    var commit = _ref4.commit;
    return new Promise(function (resolve, reject) {
      (0, _user.loginByIdentity)(identity).then(function (response) {
        if (response.code === 200) {
          var data = response.data;
          commit('SET_TOKEN', data.token);
          (0, _auth.setToken)(data.token);
        }
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref5) {
    var commit = _ref5.commit,
      state = _ref5.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getInfo)().then(function (response) {
        var data = response.data;
        if (!data) {
          return reject('获取用户信息失败，请稍后重试');
        }
        var _data$userInfo = data.userInfo,
          username = _data$userInfo.username,
          faceImg = _data$userInfo.faceImg,
          identity = _data$userInfo.identity;
        console.log("userInfo", data);
        commit('SET_NAME', username);
        commit('SET_AVATAR', faceImg);
        commit("SET_IDENTITY", identity);
        (0, _auth.setUserInfo)(data.userInfo);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref6) {
    var commit = _ref6.commit,
      state = _ref6.state;
    (0, _auth.removeToken)(); // must remove  token  first
    (0, _router.resetRouter)();
    (0, _auth.removeUserInfo)();
    commit('RESET_STATE');
    // return new Promise((resolve, reject) => {
    //   logout().then(() => {
    //     removeToken() // must remove  token  first
    //     resetRouter()
    //     commit('RESET_STATE')
    //     resolve()
    //   }).catch(error => {
    //     reject(error)
    //   })
    // })
  },
  // remove token
  initToken: function initToken(_ref7, token) {
    var commit = _ref7.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', token);
      (0, _auth.setToken)(token);
      resolve();
    });
  },
  // remove token
  resetToken: function resetToken(_ref8) {
    var commit = _ref8.commit;
    return new Promise(function (resolve) {
      (0, _auth.removeToken)(); // must remove  token  first
      (0, _auth.removeUserInfo)();
      commit('RESET_STATE');
      resolve();
    });
  },
  // setRenZheng
  setRenZheng: function setRenZheng(_ref9, userstate) {
    var commit = _ref9.commit;
    return new Promise(function (resolve, reject) {
      commit('SET_RENZHENG', userstate);
      resolve();
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};