"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.includes.js");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
// create an axios instance
console.log('当前版本号：', process.env.VUE_APP_VERSION);
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  withCredentials: true // send cookies when cross-domain requests
  // timeout: 10000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  // if (store.getters.token) {
  //   // let each request carry token
  //   // ['X-Token'] is a custom headers key
  //   // please modify it according to the actual situation
  //   config.headers['token'] = getToken()
  // }
  if (!config.url.includes('/tokenConfig/getAssocToken')) {
    config.headers['Authorization'] = 'bearer ' + (0, _auth.getToken)();
  }
  // config.headers['assocToken'] = getAssocToken() || ''
  // config.headers['deviceNum'] = '123456'
  config.headers['Accept'] = 'application/json';
  return config;
}, function (error) {
  // do something with request error
  // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response && response.data;
  // 判断token是否过期
  if (res.code === 402) {
    _elementUi.MessageBox.confirm('登录信息已失效或过期，请重新登录', '提示', {
      confirmButtonText: '重新登陆',
      showCancelButton: false,
      showClose: false,
      type: 'warning'
    }).then(function () {
      _store.default.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    });
    return {};
  } else if (res.code === 200 || res.code === 40401) {
    return res;
  } else if (res.code == 401) {
    _elementUi.MessageBox.confirm(res.message, {
      confirmButtonText: '确认',
      showCancelButton: false,
      type: 'warning'
    }).then(function () {
      // store.dispatch("user/resetToken").then(() => {
      location.reload();
      // });
    });
    return;
  } else if (res.code == 600) {
    return res;
  } else if (res.code == 500 || res.code == 109 || res.code == 104 || res.code == 301 || res.code == 302) {
    console.log('执行了');
    return res;
  } else if (res.code == 40201 || res.code == 40306 || res.code == 40307) {
    return res;
  } else {
    if (res.message) {
      // Message({
      //   title: '提示',
      //   message: res.message || '当前操作无法完成',
      //   type: 'warning',
      //   duration: 2000
      // })
      _elementUi.MessageBox.confirm(res.message, '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        showClose: false,
        beforeClose: false,
        type: 'warning'
      }).then(function () {});
    }
    return Promise.reject(new Error(res.message || 'Error'));
  }
}, function (error) {
  console.log('err' + error); // for debug
  // Message({
  //   message: error.message,
  //   type: 'error',
  //   duration: 5 * 1000
  // })
  (0, _elementUi.Notification)({
    title: '服务器提示',
    message: error || '服务器请求异常',
    type: 'warning',
    duration: 2000
  });
  return Promise.reject(error);
});
var _default = exports.default = service;