var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "newsListContainer" }, [
    _c(
      "div",
      { staticClass: "newsTitle" },
      [
        _c("NewsTitle", {
          attrs: { title: _vm.title, isShowBtn: _vm.isShowBtn },
          on: { more: _vm.handleMore },
        }),
      ],
      1
    ),
    _vm.list.length > 0
      ? _c(
          "div",
          { staticClass: "cardContentInner" },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "course-card",
                on: {
                  click: function ($event) {
                    return _vm.goDetail(item)
                  },
                },
              },
              [
                _c("div", { staticClass: "cover" }, [
                  _c("img", { attrs: { src: item.itemCover, alt: "" } }),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.itemName)),
                  ]),
                  _c("div", { staticClass: "sub" }, [
                    _c("span", { staticClass: "price" }, [
                      _vm._v("活动价：￥" + _vm._s(item.price)),
                    ]),
                    _c("div", { staticClass: "btn" }, [_vm._v("查看详情")]),
                  ]),
                ]),
              ]
            )
          }),
          0
        )
      : _c(
          "div",
          { staticClass: "noData", attrs: { description: "暂无数据" } },
          [
            _c("img", {
              attrs: {
                src: "https://res.hangxintong.cn/assoc147/train_web/nodata.png",
                alt: "",
              },
            }),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }