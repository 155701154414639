"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.to-string.js");
var _Theme_second_header = _interopRequireDefault(require("../components/Theme_second_header"));
var _Theme_second_footer = _interopRequireDefault(require("../components/Theme_second_footer"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ThemeSecondLayout',
  components: {
    ThemeSecondHeader: _Theme_second_header.default,
    ThemeSecondFooter: _Theme_second_footer.default
  },
  computed: {
    key: function key() {
      return this.$route.path;
      // return this.$route.query.id || this.getUUid()
    }
  },
  methods: {
    getUUid: function getUUid() {
      var num = Math.random();
      var str = num.toString(16);
      var newStr = str.split('.')[1];
      var result = newStr.slice(0, 6);
      return result;
    }
  }
};