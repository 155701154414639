var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "validateForm_content" },
    [
      _vm.validateMethod == "bdyjxjysdyz" || _vm.validateMethod == "bdyjxjyzdyz"
        ? _c(
            "div",
            { staticClass: "top_form" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    "label-position": "right",
                    "label-width": "180px",
                    model: _vm.formData,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { required: "", label: "姓名：" } },
                    [
                      _c("el-input", {
                        on: {
                          input: function ($event) {
                            _vm.formData.name = _vm.removeAllSpaces(
                              _vm.formData.name
                            )
                          },
                        },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { required: "", label: "身份证号：" } },
                    [
                      _c("el-input", {
                        on: {
                          input: function ($event) {
                            _vm.formData.cardNumber = _vm.removeAllSpaces(
                              _vm.formData.cardNumber
                            )
                          },
                        },
                        model: {
                          value: _vm.formData.cardNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "cardNumber", $$v)
                          },
                          expression: "formData.cardNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { required: "", label: "安全考核合格证编号：" } },
                    [
                      _c("el-input", {
                        on: {
                          input: function ($event) {
                            _vm.formData.certificateNo = _vm.removeAllSpaces(
                              _vm.formData.certificateNo
                            )
                          },
                        },
                        model: {
                          value: _vm.formData.certificateNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "certificateNo", $$v)
                          },
                          expression: "formData.certificateNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.validateMethod == "slryaqsckhjxjysdyz" ||
      _vm.validateMethod == "slryaqsckhjxjyzdyz"
        ? _c(
            "div",
            { staticClass: "qweqweq" },
            [
              _c(
                "el-form",
                {
                  staticStyle: { "margin-left": "6rem" },
                  attrs: {
                    "label-position": "right",
                    "label-width": "180px",
                    model: _vm.formData,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { required: "", label: "工作单位：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "620px" },
                        on: {
                          input: function ($event) {
                            _vm.formData.workUnit = _vm.removeAllSpaces(
                              _vm.formData.workUnit
                            )
                          },
                        },
                        model: {
                          value: _vm.formData.workUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "workUnit", $$v)
                          },
                          expression: "formData.workUnit",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { required: "", label: "身份证：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { maxlength: "18" },
                            on: {
                              input: function ($event) {
                                _vm.formData.cardNumber = _vm.removeAllSpaces(
                                  _vm.formData.cardNumber
                                )
                              },
                            },
                            model: {
                              value: _vm.formData.cardNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "cardNumber", $$v)
                              },
                              expression: "formData.cardNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            required: "",
                            label: "安全考核合格证编号：",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            on: {
                              input: function ($event) {
                                _vm.formData.securityReviewCertificateNo =
                                  _vm.removeAllSpaces(
                                    _vm.formData.securityReviewCertificateNo
                                  )
                              },
                            },
                            model: {
                              value: _vm.formData.securityReviewCertificateNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "securityReviewCertificateNo",
                                  $$v
                                )
                              },
                              expression:
                                "formData.securityReviewCertificateNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { required: "", label: "姓名：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { maxlength: "12" },
                            on: {
                              input: function ($event) {
                                _vm.formData.name = _vm.removeAllSpaces(
                                  _vm.formData.name
                                )
                              },
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { required: "", label: "联系电话：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { maxlength: "11" },
                            on: {
                              input: function ($event) {
                                _vm.formData.mobile =
                                  _vm.formData.mobile.replace(/\D/g, "")
                              },
                            },
                            model: {
                              value: _vm.formData.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "mobile", $$v)
                              },
                              expression: "formData.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { required: "", label: "职务：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            on: {
                              input: function ($event) {
                                _vm.formData.job = _vm.removeAllSpaces(
                                  _vm.formData.job
                                )
                              },
                            },
                            model: {
                              value: _vm.formData.job,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "job", $$v)
                              },
                              expression: "formData.job",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { required: "", label: "考生类型：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "220px" },
                              attrs: { placeholder: "请选择考生类型" },
                              model: {
                                value: _vm.formData.candidateCategory,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "candidateCategory",
                                    $$v
                                  )
                                },
                                expression: "formData.candidateCategory",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { required: "", label: "性别：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticStyle: { width: "220px" },
                              model: {
                                value: _vm.formData.sex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "sex", $$v)
                                },
                                expression: "formData.sex",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "男" } }, [
                                _vm._v("男"),
                              ]),
                              _c("el-radio", { attrs: { label: "女" } }, [
                                _vm._v("女"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { required: "", label: "出生年月：" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-M-d",
                              type: "date",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.formData.birthday,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "birthday", $$v)
                              },
                              expression: "formData.birthday",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.validateMethod == "wdypxsdyz"
        ? _c(
            "div",
            { staticClass: "fiveMemberForm" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    rules: _vm.fiveMemberRules,
                    "label-position": "right",
                    "label-width": "180px",
                    model: _vm.formData,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "学员姓名：", prop: "name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "620px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证号：", prop: "cardNumber" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "620px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formData.cardNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "cardNumber", $$v)
                          },
                          expression: "formData.cardNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "工作单位：", prop: "workUnit" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "620px" },
                        attrs: { maxlength: 20 },
                        model: {
                          value: _vm.formData.workUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "workUnit", $$v)
                          },
                          expression: "formData.workUnit",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "统一社会信用代码：", prop: "orgCode" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "620px" },
                        attrs: { maxlength: 18 },
                        model: {
                          value: _vm.formData.orgCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "orgCode", $$v)
                          },
                          expression: "formData.orgCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "学历证书：",
                        prop: "qualificationsCertUrl",
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            "list-type": "picture-card",
                            action: "",
                            multiple: "",
                            limit: 1,
                            "on-exceed": _vm.handleExceed,
                            "file-list": _vm.fileList1,
                            accept: ".jpg,.png",
                            "before-upload": _vm.beforeUpload,
                            "on-remove": function (file, fileList) {
                              return _vm.removeUploadFile(
                                file,
                                fileList,
                                "qualificationsCertUrl"
                              )
                            },
                            "http-request": function (file) {
                              return _vm.handelUpload(
                                file,
                                "qualificationsCertUrl"
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [_vm._v(" 只能上传jpg/png文件，且不超过2M ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "岗位申请表：", prop: "jobApplyFormUrl" },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            "list-type": "picture-card",
                            action: "",
                            multiple: "",
                            "auto-upload": true,
                            limit: 1,
                            "on-exceed": _vm.handleExceed,
                            "file-list": _vm.fileList3,
                            accept: ".jpg,.png",
                            "before-upload": _vm.beforeUpload,
                            "on-remove": function (file, fileList) {
                              return _vm.removeUploadFile(
                                file,
                                fileList,
                                "jobApplyFormUrl"
                              )
                            },
                            "http-request": function (file) {
                              return _vm.handelUpload(file, "jobApplyFormUrl")
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [_vm._v(" 只能上传jpg/png文件，且不超过2M ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.validateMethod === "xcgjpxsdyz"
        ? _c(
            "div",
            { staticClass: "country-form" },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "250px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名：", required: "" } },
                    [
                      _c("el-input", {
                        attrs: {
                          value: _vm.countryForm.name.value,
                          disabled: "",
                          placeholder: "请输入姓名",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证号码：", required: "" } },
                    [
                      _c("el-input", {
                        attrs: {
                          value: _vm.countryForm.cardNumber.value,
                          disabled: "",
                          placeholder: "请输入身份证号码",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所在盟市：", required: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.countryForm.city.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.countryForm.city, "value", $$v)
                            },
                            expression: "countryForm.city.value",
                          },
                        },
                        _vm._l(_vm.leagueCity, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.value, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "学历：", required: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.countryForm.education.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.countryForm.education, "value", $$v)
                            },
                            expression: "countryForm.education.value",
                          },
                        },
                        _vm._l(_vm.education, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.value, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "主要从事专业：", required: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.countryForm.professional.value,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.countryForm.professional,
                                "value",
                                $$v
                              )
                            },
                            expression: "countryForm.professional.value",
                          },
                        },
                        _vm._l(_vm.major, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.value, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所在单位(没有可以填‘无’)：",
                        required: "",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入所在单位" },
                        model: {
                          value: _vm.countryForm.workUnit.value,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.countryForm.workUnit,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "countryForm.workUnit.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话：", required: "" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入联系电话" },
                        model: {
                          value: _vm.countryForm.mobile.value,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.countryForm.mobile,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "countryForm.mobile.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮箱：", required: "" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入邮箱" },
                        model: {
                          value: _vm.countryForm.email.value,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.countryForm.email,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "countryForm.email.value",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.validateMethod === "bthbpxbmsdyz"
        ? _c(
            "div",
            { staticClass: "country-form" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.hbForm,
                    "label-width": "250px",
                    rules: _vm.hbRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "学员姓名：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "10", "show-word-limit": "" },
                        model: {
                          value: _vm.hbForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.hbForm, "name", $$v)
                          },
                          expression: "hbForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证号：", prop: "identity" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "18", "show-word-limit": "" },
                        model: {
                          value: _vm.hbForm.identity,
                          callback: function ($$v) {
                            _vm.$set(_vm.hbForm, "identity", $$v)
                          },
                          expression: "hbForm.identity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "一寸照：", prop: "inchPhoto" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: "",
                            "show-file-list": false,
                            "list-type": "picture-card",
                            accept: ".jpg,.png",
                            "http-request": function (file) {
                              return _vm.inchPhotoUpload(file, "inchPhoto")
                            },
                          },
                        },
                        [
                          _vm.hbForm.inchPhoto
                            ? _c("img", {
                                staticStyle: {
                                  width: "146px",
                                  height: "144px",
                                  display: "block",
                                },
                                attrs: { src: _vm.hbForm.inchPhoto },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(
                                " 说明：白色背景照片证件照，上传电子版不超过 80kb，要求正面露双耳、双肩。 "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "相关证明材料：", prop: "evidence" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: "",
                            "file-list": _vm.hbForm.evidence,
                            "list-type": "picture-card",
                            "before-upload": _vm.hbBeforeUpload,
                            "on-remove": function (file, fileList) {
                              return _vm.hbRemoveUploadFile(
                                file,
                                fileList,
                                "evidence"
                              )
                            },
                            "http-request": function (file) {
                              return _vm.inchPhotoUpload(file, "evidence")
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [_vm._v(" 说明：身份证正反面、毕业证、职称证等。 ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "承诺书：", prop: "commitment" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: "",
                            "list-type": "picture-card",
                            "show-file-list": false,
                            accept: ".jpg,.png",
                            "http-request": function (file) {
                              return _vm.inchPhotoUpload(file, "commitment")
                            },
                          },
                        },
                        [
                          _vm.hbForm.commitment
                            ? _c("img", {
                                staticStyle: {
                                  width: "146px",
                                  height: "144px",
                                  display: "block",
                                },
                                attrs: { src: _vm.hbForm.commitment },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(" 说明：打印模板填写签字后上传。 "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.downLoad },
                                },
                                [_vm._v("下载模板")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.validateMethod === "kcsjbmzdyz"
        ? _c(
            "div",
            { staticClass: "country-form" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.kcsjForm,
                    "label-width": "250px",
                    rules: _vm.kcsjRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位名称：", prop: "company" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "450px" },
                        attrs: { maxlength: "30", "show-word-limit": "" },
                        model: {
                          value: _vm.kcsjForm.company,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.kcsjForm,
                              "company",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "kcsjForm.company",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人：", prop: "contacts" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "450px" },
                        attrs: { maxlength: "20", "show-word-limit": "" },
                        model: {
                          value: _vm.kcsjForm.contacts,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.kcsjForm,
                              "contacts",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "kcsjForm.contacts",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话：", prop: "mobile" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "450px" },
                        attrs: { maxlength: "11", "show-word-limit": "" },
                        model: {
                          value: _vm.kcsjForm.mobile,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.kcsjForm,
                              "mobile",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "kcsjForm.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "职务：", prop: "post" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "450px" },
                        attrs: { maxlength: "30", "show-word-limit": "" },
                        model: {
                          value: _vm.kcsjForm.post,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.kcsjForm,
                              "post",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "kcsjForm.post",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "注册级别：", prop: "registrationLevel" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "450px" },
                        attrs: { maxlength: "20", "show-word-limit": "" },
                        model: {
                          value: _vm.kcsjForm.registrationLevel,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.kcsjForm,
                              "registrationLevel",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "kcsjForm.registrationLevel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "450px" },
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 8, maxRows: 8 },
                          maxlength: "200",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.kcsjForm.remark,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.kcsjForm,
                              "remark",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "kcsjForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.validateMethod === "jzyryzcjxjysdyz" ||
      _vm.validateMethod === "jzyryzcjxjyzdyz"
        ? _c(
            "div",
            { staticClass: "country-form" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.zhiChengForm,
                    "label-width": "250px",
                    rules: _vm.zhiChengRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "工作单位：", prop: "company" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "128", "show-word-limit": "" },
                        model: {
                          value: _vm.zhiChengForm.company,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.zhiChengForm,
                              "company",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "zhiChengForm.company",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "10", "show-word-limit": "" },
                        model: {
                          value: _vm.zhiChengForm.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.zhiChengForm,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "zhiChengForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别：", prop: "sex" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.zhiChengForm.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.zhiChengForm, "sex", $$v)
                            },
                            expression: "zhiChengForm.sex",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "男" } }, [
                            _vm._v("男"),
                          ]),
                          _c("el-radio", { attrs: { label: "女" } }, [
                            _vm._v("女"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证号：", prop: "identity" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "18", "show-word-limit": "" },
                        model: {
                          value: _vm.zhiChengForm.identity,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.zhiChengForm,
                              "identity",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "zhiChengForm.identity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所在单位统一社会信用代码：",
                        prop: "orgCode",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "18", "show-word-limit": "" },
                        model: {
                          value: _vm.zhiChengForm.orgCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.zhiChengForm,
                              "orgCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "zhiChengForm.orgCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位性质：", prop: "unitNature" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "10", "show-word-limit": "" },
                        model: {
                          value: _vm.zhiChengForm.unitNature,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.zhiChengForm,
                              "unitNature",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "zhiChengForm.unitNature",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.validateMethod == "xcjsgjpxyzzd"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "country-form", staticStyle: { padding: "20px" } },
              [
                _vm._v(" 所属盟市： "),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "380px" },
                    attrs: { placeholder: "请选择盟市" },
                    model: {
                      value: _vm.xcjsgjpxyz.city.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.xcjsgjpxyz.city, "value", $$v)
                      },
                      expression: "xcjsgjpxyz.city.value",
                    },
                  },
                  _vm._l(_vm.leagueCitytt, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.value, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.typeListMixin.includes(_vm.validateMethod)
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "align-items": "center",
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "hyxxyjjzszhuxiangzdyzForm",
                  attrs: {
                    model: _vm.hyxxyjjzszhuxiangzdyzFormData,
                    rules: _vm.hyxxyjjzszhuxiangzdyzRules,
                    size: "medium",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "证书编号", prop: "cert_num" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: { placeholder: "请输入证书编号", clearable: "" },
                        model: {
                          value: _vm.hyxxyjjzszhuxiangzdyzFormData.cert_num,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.hyxxyjjzszhuxiangzdyzFormData,
                              "cert_num",
                              $$v
                            )
                          },
                          expression: "hyxxyjjzszhuxiangzdyzFormData.cert_num",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "培训工种",
                        prop: "train_target_detail_id",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "400px" },
                          attrs: {
                            placeholder: "请选择培训工种",
                            clearable: "",
                          },
                          model: {
                            value:
                              _vm.hyxxyjjzszhuxiangzdyzFormData
                                .train_target_detail_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.hyxxyjjzszhuxiangzdyzFormData,
                                "train_target_detail_id",
                                $$v
                              )
                            },
                            expression:
                              "hyxxyjjzszhuxiangzdyzFormData.train_target_detail_id",
                          },
                        },
                        _vm._l(
                          _vm.train_target_detail_idOptions,
                          function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.label,
                                value: item.value,
                                disabled: item.disabled,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "btm_btn" }, [
        _c("div"),
        _c(
          "div",
          [
            _c("el-button", { on: { click: _vm.goBack } }, [_vm._v("取消")]),
            _c("el-button", { on: { click: _vm.submit } }, [_vm._v("提交")]),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "loading_div",
          attrs: {
            visible: _vm.showLoadingDiv,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            top: "25vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showLoadingDiv = $event
            },
          },
        },
        [
          _c("div", [
            _c("i", { staticClass: "el-icon-loading" }),
            _c("span", [_vm._v("您提交的报名身份效验中...")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }