"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mStore = _interopRequireDefault(require("@/store/modules/mStore.js"));
var _request = _interopRequireDefault(require("@/utils/request.js"));
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      mUrl: _mStore.default.mUrl,
      list: "",
      oid: ""
    };
  },
  created: function created() {
    this.oid = this.$route.params && this.$route.params.id;
    this.getlist();
  },
  methods: {
    getlist: function getlist() {
      var _this = this;
      (0, _request.default)({
        url: "/train/config/" + this.oid,
        method: "get"
      }).then(function (response) {
        if (response.code == 200) {
          _this.list = response.data.itemConfig.handExplain;
        }
      });
    }
  }
};