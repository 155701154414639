"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.splice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _defineProperty2 = _interopRequireDefault(require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _request = _interopRequireDefault(require("@/utils/request.js"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var mPlayAuther = "";
var _default = exports.default = {
  data: function data() {
    var _ref;
    return _ref = {
      activeNames: [],
      activeNames1: [],
      list: "",
      list1: "",
      oid: "",
      oprtContent: "",
      listname: 0,
      listname1: 0,
      listname2: 0,
      listnameRrainIsSup: 0,
      listnameRrainIsSup1: 0,
      dialogVideoVisible: false,
      listText: "",
      dialogFormVisible: false,
      cid: "",
      textMap: {
        update: "编辑实操",
        create: "添加实操"
      },
      dialogStatus: "",
      handUploadEnd: "",
      temp: {
        textarea: "",
        oimg: [],
        address: "",
        assocId: 133,
        handClassHour: "",
        id: "",
        itemId: ""
      },
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      updateVideoWrap: true,
      tokenList: "",
      order: null,
      coursePlayList: [],
      visible: false,
      //弹窗
      loading: false,
      //上传按钮加载
      os: false,
      //控制摄像头开关
      thisVideo: null,
      thisContext: null,
      thisCancas: null,
      videoWidth: 500,
      videoHeight: 400,
      postOptions: [],
      CertCtl: ""
    }, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "loading", true), "ids", []), "single", true), "multiple", true), "total", 0), "akworkerList", []), "workerTypeOptions", []), "title", ""), "open", false), "queryParams", {
      pageNum: 1,
      pageSize: 10,
      imgSrc: undefined
    }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "form", {}), "rules", {}), "dialogAddVisible", true);
  },
  created: function created() {
    this.tokenList = (0, _auth.getToken)();
    this.oid = this.$route.params && this.$route.params.id;
    this.cID = (0, _auth.getAssocId)();
    this.getlist();
    this.getList1();
    // request({
    //   url: "/getSumClassHourse/" + this.oid,
    //   method: "get"
    // }).then(response => {

    //   this.listname = response.data.requirClassHourse
    //   this.listname1 = response.data.searchClassHourse
    //   this.listname2 = response.data.handClassHour
    // });
  },
  methods: {
    getlist: function getlist() {
      var _this = this;
      (0, _request.default)({
        url: "/train/config/" + this.oid,
        method: "get"
      }).then(function (response) {
        if (response.code == 200) {
          // 是否开启监学 0 未开启，1开启
          // 必修
          _this.listnameRrainIsSup = response.data.itemConfig.isRequiredMonitor;
          // 选修
          _this.listnameRrainIsSup1 = response.data.itemConfig.isSearchMonitor;
          _this.listname = response.data.itemConfig.requiredTarget;
          _this.listname1 = response.data.itemConfig.searchTarget;
          _this.listname2 = response.data.itemConfig.handClassHour;
          _this.response.data.requirdClass.forEach(function (ele, index) {
            _this.activeNames.push(index);
          });
          response.data.searchSplit.forEach(function (ele, index) {
            _this.activeNames1.push(index);
          });
          _this.list = response.data.requirdClass;
          _this.list1 = response.data.searchSplit;
          _this.listText = response.data.itemConfig.handExplain;
        }
      });
    },
    getList1: function getList1() {
      var _this2 = this;
      (0, _request.default)({
        url: "/train/config/operatlists/" + this.oid,
        method: "get"
      }).then(function (response) {
        _this2.oprtContent = response.data;
      });
    },
    mChangePlay: function mChangePlay(item) {
      this.coursePlayList.chapters.forEach(function (e) {
        // e.section.forEach(el => {
        //   el.isPlay = false;
        // });
      });
      this.playVideo(item);
    },
    playVideo: function playVideo(item, list) {
      var _this3 = this;
      if (item.isTryLearn == 1) {
        this.dialogVideoVisible = true;
        if (list) {
          this.coursePlayList = list;
          this.mteachers = list.teachers;
        }
        this.coursePlayList.chapters.forEach(function (e) {
          // e.section.forEach(el => {
          //   el.isPlay = false;
          // });
        });
        item.isPlay = true;
        this.updateVideoWrap = false;
        this.$nextTick(function () {
          _this3.updateVideoWrap = true;
        });
        (0, _request.default)({
          url: "/videoUpload/play",
          method: "post",
          params: {
            vid: item.content
          }
        }).then(function (res) {
          mPlayAuther = res.data.PlayAuth;
          _this3.player = new Aliplayer({
            id: "player-con",
            width: "100%",
            height: "32.3rem",
            autoplay: true,
            vid: item.content,
            playauth: mPlayAuther,
            isLive: false,
            rePlay: false,
            playsinLine: true,
            preload: false,
            // 私密播放
            encryptType: 1,
            controlBarVisibility: "hover",
            //definition: "OD,HD,SD,LD",
            skinLayout: [{
              name: "bigPlayButton",
              align: "blabs",
              x: 30,
              y: 80
            }, {
              name: "H5Loading",
              align: "cc"
            }, {
              name: "controlBar",
              align: "blabs",
              x: 0,
              y: 0,
              children: [{
                name: "progress",
                align: "tlabs",
                x: 0,
                y: 0
              }, {
                name: "playButton",
                align: "tl",
                x: 10,
                y: 10
              }, {
                name: "timeDisplay",
                align: "tl",
                x: 5,
                y: 5
              }, {
                name: "fullScreenButton",
                align: "tr",
                x: 10,
                y: 10
              }, {
                name: "volume",
                align: "tr",
                x: 10,
                y: 10
              }, {
                name: "setting",
                align: "tr",
                x: 10,
                y: 10
              }]
            }]
          }, function (player) {
            player.play();
          });
        });
        // setTimeout(() => {
        //   this.onTake();
        // }, 3000);
      } else {
        if (this.tokenList) {
          this.$router.push({
            path: "/payment/" + this.oid
          });
        } else {
          this.dialogAddVisible = true;
          eventBus.$emit("showList", this.dialogAddVisible);
          // this.$notify({
          //   title: "提示",
          //   message: "请你先登录",
          //   type: "info"
          // });
        }
      }
    },
    photoClick: function photoClick(item) {
      if (this.tokenList) {
        this.resetTemp();
        this.dialogFormVisible = true;
        this.dialogStatus = "create";
        this.temp.address = item.address;
        this.temp.handClassHour = item.handClassHour;
        this.temp.id = item.id;
        this.temp.itemId = item.itemId;
        this.handUploadEnd = item.handUploadEnd;
      } else {
        // this.$notify({
        //   title: "提示",
        //   message: "请你先登录",
        //   type: "info"
        // });
        this.dialogAddVisible = true;
        eventBus.$emit("showList", this.dialogAddVisible);
      }
    },
    bcClick: function bcClick(item) {
      this.dialogFormVisible = true;
      this.dialogStatus = "update";
      this.temp.address = item.address;
      this.temp.handClassHour = item.handClassHour;
      this.temp.id = item.id;
      this.temp.itemId = item.itemId;
      this.handUploadEnd = item.handUploadEnd;
      this.temp.textarea = item.title;
      this.temp.oimg = item.operationImg.split(",");
      this.temp.oimg.forEach(function (item) {});
    },
    createData: function createData() {
      var _this4 = this;
      (0, _request.default)({
        url: "/train/config/saveOperation",
        method: "post",
        data: {
          address: this.temp.address,
          assocId: this.cID,
          handClassHour: this.temp.handClassHour,
          id: this.temp.id,
          itemId: this.oid,
          // itemId：1,
          operationImg: this.temp.oimg.toString(),
          status: 2,
          title: this.temp.textarea
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this4.getlist1();
          _this4.dialogFormVisible = false;
          _this4.$message({
            title: "成功",
            message: "保存成功",
            type: "success",
            duration: 2000
          });
        }
      });
    },
    updateDatalist: function updateDatalist() {
      var _this5 = this;
      (0, _request.default)({
        url: "/train/config/saveOperation",
        method: "post",
        data: {
          address: this.temp.address,
          assocId: this.cID,
          handClassHour: this.temp.handClassHour,
          id: this.temp.id,
          //   itemId: this.oid,
          itemId: this.oid,
          operationImg: this.temp.oimg.toString(),
          status: 1,
          title: this.temp.textarea
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this5.getlist1();
          _this5.dialogFormVisible = false;
          _this5.$message({
            title: "成功",
            message: "保存草稿成功",
            type: "success",
            duration: 2000
          });
        }
      });
    },
    updateData: function updateData() {
      var _this6 = this;
      (0, _request.default)({
        url: "/train/config/saveOperation",
        method: "post",
        data: {
          address: this.temp.address,
          assocId: this.temp.assocId,
          handClassHour: this.temp.handClassHour,
          id: this.temp.id,
          itemId: this.oid,
          operationImg: this.temp.oimg.toString(),
          status: 2,
          title: this.temp.textarea
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this6.getlist1();
          _this6.dialogFormVisible = false;
          _this6.$message({
            title: "成功",
            message: "保存成功",
            type: "success",
            duration: 2000
          });
        }
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      for (var i = 0; i < this.temp.oimg.length; i++) {
        if (file.response.data.filename == this.temp.oimg[i]) {
          this.temp.oimg.splice(i, 1);
        }
      }
    },
    handSuccess: function handSuccess(file) {
      var _this7 = this;
      this.aliUpload(file, "trainPracticalOperation").then(function (res) {
        if (res.res.status === 200) {
          _this7.temp.oimg.push("https://oss.hangxintong.cn/".concat(res.name));
        }
      });
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    resetTemp: function resetTemp() {
      this.temp = {
        textarea: "",
        oimg: [],
        address: "",
        assocId: "",
        handClassHour: "",
        id: "",
        itemId: ""
      };
      this.fileList = [];
    },
    computedIndex: function computedIndex(index) {
      if (index < 10) {
        return "0" + index + ". ";
      } else {
        return index + ". ";
      }
    } //   signUpClick1(){
    //  this.dialogAddVisible = true;
    //      eventBus.$emit("showList", this.dialogAddVisible);
    //   }
  }
};