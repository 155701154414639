"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.splice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _request = _interopRequireDefault(require("@/utils/request.js"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: "",
      oid: "",
      hightName: "296px",
      showfiex: true,
      oprtContent: "",
      oprtConImg: [],
      dialogFormVisible: false,
      textMap: {
        update: "编辑实操",
        create: "添加实操"
      },
      dialogStatus: "",
      handUploadEnd: "",
      fileList: [],
      cID: "",
      oImgLength: 0,
      temp: {
        textarea: "",
        oimg: [],
        address: "",
        assocId: 133,
        handClassHour: "",
        id: "",
        itemId: ""
      },
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      tokenList: "",
      order: null
    };
  },
  created: function created() {
    var _this = this;
    this.oid = this.$route.params && this.$route.params.id;
    this.tokenList = (0, _auth.getToken)();
    this.cID = (0, _auth.getAssocId)();
    (0, _request.default)({
      url: "/train/config/" + this.oid,
      method: "get"
    }).then(function (response) {
      _this.order = response.data.order;
      if (_this.tokenList && _this.order) {
        _this.getlist1();
      } else {
        _this.getlist();
      }
    });
  },
  mounted: function mounted() {
    this.hightName = document.getElementById("contBox").clientHeight;
  },
  methods: {
    getlist: function getlist() {
      var _this2 = this;
      (0, _request.default)({
        url: "/train/config/" + this.oid,
        method: "get"
      }).then(function (response) {
        if (response.code == 200) {
          _this2.list = response.data.itemConfig.handExplain;
        }
      });
    },
    getlist1: function getlist1() {
      var _this3 = this;
      (0, _request.default)({
        url: "/train/config/operatlists/" + this.oid,
        method: "get"
      }).then(function (response) {
        _this3.oprtContent = response.data;
      });
    },
    showClick: function showClick() {
      this.hightName = "auto";
      this.showfiex = false;
    },
    showupClick: function showupClick() {
      this.hightName = "296px";
      this.showfiex = true;
    },
    photoClick: function photoClick(item) {
      this.resetTemp();
      this.dialogFormVisible = true;
      this.dialogStatus = "create";
      this.temp.address = item.address;
      this.temp.handClassHour = item.handClassHour;
      this.temp.id = item.id;
      this.temp.itemId = item.itemId;
      this.handUploadEnd = item.handUploadEnd;
    },
    bcClick: function bcClick(item) {
      this.dialogFormVisible = true;
      this.dialogStatus = "update";
      this.temp.address = item.address;
      this.temp.handClassHour = item.handClassHour;
      this.temp.id = item.id;
      this.temp.itemId = item.itemId;
      this.handUploadEnd = item.handUploadEnd;
      this.temp.textarea = item.title;
      this.temp.oimg = item.operationImg.split(",");
      this.temp.oimg.forEach(function (item) {});
    },
    createData: function createData() {
      var _this4 = this;
      (0, _request.default)({
        url: "/train/config/saveOperation",
        method: "post",
        data: {
          address: this.temp.address,
          assocId: this.cID,
          handClassHour: this.temp.handClassHour,
          id: this.temp.id,
          itemId: this.oid,
          // itemId：1,
          operationImg: this.temp.oimg.toString(),
          status: 2,
          title: this.temp.textarea
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this4.getlist1();
          _this4.dialogFormVisible = false;
          _this4.$message({
            title: "成功",
            message: "保存成功",
            type: "success",
            duration: 2000
          });
        }
      });
    },
    updateDatalist: function updateDatalist() {
      var _this5 = this;
      (0, _request.default)({
        url: "/train/config/saveOperation",
        method: "post",
        data: {
          address: this.temp.address,
          assocId: this.cID,
          handClassHour: this.temp.handClassHour,
          id: this.temp.id,
          //   itemId: this.oid,
          itemId: this.oid,
          operationImg: this.temp.oimg.toString(),
          status: 1,
          title: this.temp.textarea
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this5.getlist1();
          _this5.dialogFormVisible = false;
          _this5.$message({
            title: "成功",
            message: "保存草稿成功",
            type: "success",
            duration: 2000
          });
        }
      });
    },
    updateData: function updateData() {
      var _this6 = this;
      (0, _request.default)({
        url: "/train/config/saveOperation",
        method: "post",
        data: {
          address: this.temp.address,
          assocId: this.temp.assocId,
          handClassHour: this.temp.handClassHour,
          id: this.temp.id,
          itemId: this.oid,
          operationImg: this.temp.oimg.toString(),
          status: 2,
          title: this.temp.textarea
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this6.getlist1();
          _this6.dialogFormVisible = false;
          _this6.$message({
            title: "成功",
            message: "保存成功",
            type: "success",
            duration: 2000
          });
        }
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      for (var i = 0; i < this.temp.oimg.length; i++) {
        if (file.response.data.filename == this.temp.oimg[i]) {
          this.temp.oimg.splice(i, 1);
        }
      }
    },
    handSuccess: function handSuccess(file) {
      var _this7 = this;
      this.aliUpload(file, "trainPracticalOperation").then(function (res) {
        if (res.res.status === 200) {
          _this7.temp.oimg.push("https://oss.hangxintong.cn/".concat(res.name));
        }
      });
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    resetTemp: function resetTemp() {
      this.temp = {
        textarea: "",
        oimg: [],
        address: "",
        assocId: "",
        handClassHour: "",
        id: "",
        itemId: ""
      };
      this.fileList = [];
    }
  }
};