var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "theme-second-layout" },
    [
      _c("Header"),
      _c("section", [
        _c(
          "div",
          { staticClass: "router-view-box" },
          [
            _c(
              "transition",
              { attrs: { name: "fade-transform", mode: "out-in" } },
              [_c("router-view", { key: _vm.key })],
              1
            ),
          ],
          1
        ),
      ]),
      _c("footer", [_c("Footer")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }