"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.autoLogin = autoLogin;
var _request = _interopRequireDefault(require("@/utils/request"));
// 质量学员登录
function autoLogin(data) {
  return (0, _request.default)({
    url: '/zyg/login/autoLogin',
    method: 'post',
    data: data
  });
}