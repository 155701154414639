"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.json.stringify.js");
var _refundProgress = _interopRequireDefault(require("./refundProgress.vue"));
var _personal = require("@/api/personal");
var _request = _interopRequireDefault(require("@/utils/request.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  inject: ["handleSelect"],
  components: {
    refundProgress: _refundProgress.default
  },
  data: function data() {
    return {
      tkPayWay: null,
      // 退款item payWay
      showProgress: false,
      // 退款进度弹框
      refundProgressItem: null,
      // 退款进度弹框Item
      progressActive: 3,
      total: 100,
      list: null,
      listLoading: true,
      disabList: false,
      textarea: "",
      disablist1: false,
      oid: "",
      remark: "",
      listQuery: {
        // cid: getAssocId("mCid"),
        page: 1,
        size: 100,
        status: 5
      },
      refundFlag: 1,
      // 退款flag 1=》退款原因  2=》无法退款
      companyList: {} // 协会信息
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    //获取订单信息
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _personal.refundOrderList)(this.listQuery).then(function (response) {
        _this.list = response.data.content.map(function (item) {
          var payDay = new Date(item.payTime).getTime();
          var countDown = new Date(item.payTime).getTime() + 604800000;
          var NowData = new Date().getTime();
          if (countDown <= NowData) {
            item.maynot = false;
          } else {
            item.maynot = true;
          }
          item.purchaseDays = parseInt((NowData - payDay) / 1000 / 60 / 60 / 24);
          return item;
        });
        _this.total = response.data.totalElements;
        _this.listLoading = false;
      });
    },
    details: function details(item, jumpType) {
      this.handleSelect(11, item, jumpType, '4-4');
    },
    // 兑换码支付退款
    volumeClick: function volumeClick() {
      var _this2 = this;
      couponRefund(this.oid, this.textarea).then(function (res) {
        if (res.code == 200) {
          _this2.showProgress = false;
          _this2.disabList = false;
          _this2.getList();
          _this2.$message({
            message: "已提交申请，请耐心等待平台审核",
            type: "success"
          });
        } else {
          _this2.disabList = false;
          _this2.$message.error(res.message);
        }
      });
    },
    qxClick: function qxClick(item) {
      var _this3 = this;
      this.tkPayWay = item.payWay;
      (0, _request.default)({
        url: "/isOrderFlag",
        method: "get",
        params: {
          orderId: item.id
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this3.oid = response.data.id;
          _this3.textarea = "";
          _this3.disabList = true;
        } else {
          _this3.$alert(response.message, "提示", {
            confirmButtonText: "确定",
            type: "info",
            callback: function callback(action) {}
          });
        }
      });
    },
    reQxClick: function reQxClick() {
      this.qxClick(this.refundProgressItem);
    },
    infoClick: function infoClick(item) {
      this.$router.push({
        path: "/teachinfo/" + item.goodsId
      });
    },
    examClick: function examClick(item) {
      console.log(JSON.stringify(item.goodsId, null, 2));
      this.$router.push("/waitingForTheExam/".concat(item.goodsId));
    },
    // 退款进度
    refundProgress: function refundProgress(item) {
      this.refundProgressItem = item;
      console.log(this.refundProgressItem);
      this.showProgress = true;
    },
    closeDid: function closeDid() {
      this.getList();
      this.showProgress = false;
    },
    tkMoneyClick: function tkMoneyClick() {
      var _this4 = this;
      if (!this.textarea) {
        this.$message.warning("请填写退款原因！");
        return false;
      }
      switch (this.tkPayWay) {
        case 6:
          this.volumeClick();
          break;
        default:
          (0, _request.default)({
            url: "/order/applyForRefund",
            method: "post",
            params: {
              oid: this.oid,
              remark: this.textarea
            }
          }).then(function (response) {
            if (response.code == 200) {
              _this4.showProgress = false;
              _this4.disabList = false;
              _this4.getList();
              _this4.$message({
                message: "申请退款成功",
                type: "success"
              });
            } else {
              _this4.refundFlag = 2;
            }
          });
          break;
      }
    }
  }
};