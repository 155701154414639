"use strict";

var _interopRequireDefault = require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/liujian/\u516C\u53F8\u9879\u76EE/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var fillOutTheForm = {
  path: '/fillOutTheForm',
  component: _layout.default,
  redirect: '/fillOutTheForm',
  children: [{
    path: '/fillOutTheForm',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/fillOutTheForm/index'));
      });
    },
    children: [{
      path: '/fillOutTheForm/validateForm/:trainId',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/fillOutTheForm/formInfo/validateForm'));
        });
      }
    }, {
      path: '/fillOutTheForm/customerForm/:formId',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/fillOutTheForm/formInfo/customerForm'));
        });
      }
    }]
  }]
};
var _default = exports.default = fillOutTheForm;